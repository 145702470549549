import { UIText2 } from '@cian/ui-kit/typography';
import { IconMapCar16, IconMapWalk16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';
import { ETransportType } from '../../../../types/newbuildingPromoBuilderOffers';

interface IFromUndergroundProps {
  transportType: ETransportType;
  timeTo: string;
}

export function FromUndergroundTime({ transportType, timeTo }: IFromUndergroundProps) {
  const Icon = transportType === ETransportType.Walk ? IconMapWalk16 : IconMapCar16;

  return (
    <div className={styles['distance']} data-testid="from_underground_time">
      <div className={styles['icon']}>
        <Icon data-testid={`from_underground_time_icon_${transportType}`} color={'icon-secondary-default'} />
      </div>
      <UIText2 color={'gray60_100'}>{timeTo}</UIText2>
    </div>
  );
}
