export enum EMediaType {
  Layout = 'layout',
  CianLayout = 'cianLayout',
  Photo = 'photo',
  Video = 'video',
}

interface IMedia<T extends EMediaType> {
  src: string;
  type: T;
}

export interface IVideoMedia extends IMedia<EMediaType.Video> {
  previewUrl: string;
  videoType: TVideoType;
  id: string;
}

export type TPhotoMedia = IMedia<EMediaType.CianLayout | EMediaType.Layout | EMediaType.Photo>;

export type TMedia = TPhotoMedia | IVideoMedia;

export type TVideoType = 'kinescope' | 'rutube' | 'vk' | 'youtube';

export interface IVideoShortParams {
  seek: number;
  duration: number;
}
