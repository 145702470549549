import { type IConfig } from '@cian/config/shared';
import React, { useCallback, useMemo } from 'react';

import { EPosition } from '../../../../../shared/filters/components/filters/types';
import { IAbUseExperiment } from '../../../../../shared/types/abUseExperiments';
import { TApplyFiltersSource } from '../../../../../shared/types/applyFiltersSource';
import { TPromoSearchTypes } from '../../../../../shared/types/promoSearchTypes';
import { IMakeRequest } from '../../../../../shared/utils/request';
import { EFeature } from '../../types/features';
import { TFilter, TOpenedFiltersState } from '../../types/filters';
import { IJsonQuery } from '../../types/jsonQuery';
import { TCurrentLocation } from '../../types/location';
import { TModifier } from '../../types/modifier';
import { ISpecialPromo } from '../../types/specialPromo';
import { IContext, context as Context } from '../../utils/context';
import { modifyJsonQuery } from '../../utils/modifyJsonQuery';

export interface IOnApplyClickParameters {
  source: TApplyFiltersSource;
}

export interface IOnChangeParameters {
  appliedModifiers: [TModifier, ...TModifier[]];
  nextJsonQuery: IJsonQuery;
}

interface IProviderProps {
  appContext: {
    config: IConfig;
    makeRequest: IMakeRequest;
    experiments: IAbUseExperiment[];
  };
  currentLocation: TCurrentLocation;
  features: EFeature[];
  filtersSectionPosition: EPosition;
  hasDistricts: boolean;
  hasHighways: boolean;
  hasUnderground: boolean;
  hasRegionalDistricts: boolean;
  isTooltipAvailable: boolean;
  jsonQuery: IJsonQuery;
  jsonQueryCount: number | undefined;
  jsonQueryCountRefreshing: boolean;
  lastAppliedModifiers: [TModifier, ...TModifier[]] | null;
  openedFilters: TOpenedFiltersState;
  specialPromo?: ISpecialPromo;
  isVillageMortgageFilterEnabled: boolean;
  isNewbuildingBlackFridayActive: boolean;
  promoSearchTypes: TPromoSearchTypes;
  onApply(): void;
  onApplyClick?(parameters: IOnApplyClickParameters): void;
  onChange(parameters: IOnChangeParameters): void;
  onClear?(): void;
  onCloseFilter(filter: TFilter): void;
  onDistrictPopupOpen?(): void;
  onGeoPopupOpen?(): void;
  onHighwaysPopupOpen?(): void;
  onOpenFilter(filter: TFilter): void;
  onUndergroundPopupOpen?(): void;
  onRegionalDistrictsOpen?(): void;
}

export const Provider: React.FC<React.PropsWithChildren<IProviderProps>> = ({
  children,
  appContext,
  currentLocation,
  features,
  filtersSectionPosition,
  hasDistricts,
  hasHighways,
  hasUnderground,
  hasRegionalDistricts,
  isTooltipAvailable,
  jsonQuery,
  jsonQueryCount,
  jsonQueryCountRefreshing,
  lastAppliedModifiers,
  openedFilters,
  specialPromo,
  isVillageMortgageFilterEnabled,
  isNewbuildingBlackFridayActive,
  promoSearchTypes,
  onApply,
  onApplyClick,
  onChange,
  onClear,
  onCloseFilter,
  onDistrictPopupOpen,
  onGeoPopupOpen,
  onHighwaysPopupOpen,
  onOpenFilter,
  onUndergroundPopupOpen,
  onRegionalDistrictsOpen,
}) => {
  const handleChange = useCallback(
    (...modifiers: [TModifier, ...TModifier[]]) => {
      onChange({
        appliedModifiers: modifiers,
        nextJsonQuery: modifyJsonQuery(jsonQuery, modifiers),
      });
    },
    [jsonQuery, onChange],
  );

  const context: IContext = useMemo(
    () => ({
      appContext,
      currentLocation,
      features,
      filtersSectionPosition,
      hasDistricts,
      hasHighways,
      hasUnderground,
      hasRegionalDistricts,
      isTooltipAvailable,
      jsonQuery,
      jsonQueryCount,
      jsonQueryCountRefreshing,
      lastAppliedModifiers,
      openedFilters,
      specialPromo,
      isVillageMortgageFilterEnabled,
      isNewbuildingBlackFridayActive,
      promoSearchTypes,
      onApply,
      onApplyClick,
      onChange: handleChange,
      onClear,
      onCloseFilter,
      onDistrictPopupOpen,
      onGeoPopupOpen,
      onHighwaysPopupOpen,
      onOpenFilter,
      onUndergroundPopupOpen,
      onRegionalDistrictsOpen,
    }),
    [
      appContext,
      currentLocation,
      features,
      filtersSectionPosition,
      hasDistricts,
      hasHighways,
      hasUnderground,
      hasRegionalDistricts,
      isTooltipAvailable,
      jsonQuery,
      jsonQueryCount,
      jsonQueryCountRefreshing,
      lastAppliedModifiers,
      openedFilters,
      specialPromo,
      isVillageMortgageFilterEnabled,
      isNewbuildingBlackFridayActive,
      promoSearchTypes,
      onApply,
      onApplyClick,
      handleChange,
      onClear,
      onCloseFilter,
      onDistrictPopupOpen,
      onGeoPopupOpen,
      onHighwaysPopupOpen,
      onOpenFilter,
      onUndergroundPopupOpen,
      onRegionalDistrictsOpen,
    ],
  );

  return <Context.Provider value={context}>{children}</Context.Provider>;
};
