import { TColors } from '@cian/ui-kit-design-tokens/colors';

import { TLabelColor, ELabelColor } from '../../types/label';

export const labelBgColorMap: Record<ELabelColor, TColors | string> = {
  [ELabelColor.Red]: 'surface-negative-default',
  [ELabelColor.Green]: 'surface-positive-default',
  [ELabelColor.DarkGreen]: 'accent-positive-primary',
  [ELabelColor.Blue]: 'accent-main-secondary',
  [ELabelColor.Gray]: 'overlay-control-dark',
  [ELabelColor.Yellow]: 'surface-warning-default',
  [ELabelColor.OrangePercent]: 'brand-pink',
  [ELabelColor.SimpleBlue]: 'accent-main-secondary',
  [ELabelColor.SurfaceBlueIconed]: 'surface-main-default',
  [ELabelColor.SurfaceBlue]: 'surface-main-default',
  [ELabelColor.Purple]: 'decorative-accent-lavender',
  [ELabelColor.DarkGray]: 'surface-neutral-default',
  [ELabelColor.BlueVerifiedByCian]: 'accent-main-secondary',
  [ELabelColor.BlueInstantBooking]: 'accent-main-secondary',
  [ELabelColor.BlackBlueGradient]: 'linear-gradient(90deg, black 0%, #0766fc 100%), rgba(4, 104, 255, 0.1)',
  [ELabelColor.Orange]: 'surface-warning-default',
};

export const labelTextColorMap: Record<ELabelColor, TColors> = {
  [ELabelColor.Red]: 'accent-negative-primary',
  [ELabelColor.Green]: 'accent-positive-primary',
  [ELabelColor.DarkGreen]: 'decorative-theme-white',
  [ELabelColor.Blue]: 'accent-main-primary',
  [ELabelColor.Gray]: 'decorative-theme-dark',
  [ELabelColor.DarkGray]: 'decorative-theme-dark',
  [ELabelColor.Yellow]: 'accent-warning-primary',
  [ELabelColor.OrangePercent]: 'decorative-theme-dark',
  [ELabelColor.SimpleBlue]: 'accent-main-primary',
  [ELabelColor.SurfaceBlueIconed]: 'accent-main-primary',
  [ELabelColor.SurfaceBlue]: 'accent-main-primary',
  [ELabelColor.BlackBlueGradient]: 'decorative-theme-white',
  [ELabelColor.Purple]: 'decorative-accent-purple',
  [ELabelColor.BlueVerifiedByCian]: 'accent-main-primary',
  [ELabelColor.BlueInstantBooking]: 'accent-main-primary',
  [ELabelColor.Orange]: 'accent-warning-primary',
};

export function mapLabelBgColor(color: TLabelColor): TColors {
  return labelBgColorMap[color] as TColors;
}

export function mapLabelColor(color: TLabelColor): TColors {
  return labelTextColorMap[color];
}
