import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { trackWriteClick } from './tracking';
import { useOfferContext } from '../../../../context';

export const ChatButtonContainer: React.FC = () => {
  const { offer, api, isChatAvailable, position, isNewbuildingFromBuilder } = useOfferContext();
  const chatButtonText = isNewbuildingFromBuilder ? 'Написать застройщику' : 'Написать';

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      trackWriteClick(offer, position);
      api.openChat(offer, position);
    },
    [offer, api, position],
  );

  if (!isChatAvailable) {
    return null;
  }

  return (
    <Button data-mark="ChatButton" fullWidth size="XS" onClick={handleClick} theme="fill_secondary">
      {chatButtonText}
    </Button>
  );
};
