import { useUpdateEffect } from '@cian/react-utils';
import KinescopePlayer from '@kinescope/react-kinescope-player';
import { clsx } from 'clsx';
import * as React from 'react';

import styles from './KinescopeVideoShort.css';
import { IVideoShortParams } from '../../../../types';

export interface IKinescopeVideoShort {
  id: string;
  params: IVideoShortParams;
  playVideo: boolean;

  onInitError(error: Error): void;
  onReady(): void;
  onEnded(): void;
  onPlay(): void;
}

export const KinescopeVideoShort: React.FC<IKinescopeVideoShort> = ({
  id,
  params,
  playVideo,
  onInitError,
  onReady,
  onEnded,
  onPlay,
}) => {
  const [showPlayer, setShowPlayer] = React.useState<boolean>(false);

  const playerRef = React.useRef<KinescopePlayer>(null);

  const stopVideo = React.useCallback(() => {
    playerRef.current?.stop();
    setShowPlayer(false);
    onEnded();
  }, [onEnded]);

  const handleVideoPlay = React.useCallback(() => {
    setShowPlayer(true);
    onPlay();
  }, [onPlay]);

  const handleVideoReady = React.useCallback(async () => {
    await playerRef.current?.setVideoQuality(720);

    onReady();
  }, [onReady]);

  useUpdateEffect(() => {
    if (playVideo) {
      playerRef.current?.play();
    } else {
      stopVideo();
    }
  }, [playVideo, stopVideo]);

  return (
    <KinescopePlayer
      ref={playerRef}
      videoId={id}
      className={clsx(styles['kinescope-player'], showPlayer && styles['kinescope-player--visible'])}
      onEnded={stopVideo}
      onReady={handleVideoReady}
      onPlay={handleVideoPlay}
      onInitError={onInitError}
      controls={false}
      query={params}
      muted
      localStorage={false}
      autoPause={false}
      preload
    />
  );
};
