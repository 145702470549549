import { IAppState } from '../../common/state';
import { AUD_EMPTY_SEARCH_BANNER_EXPERIMENT } from '../../constants/experiments';
import { isAbUseExperimentGroup } from '../../utils/experiments/isAbUseExperimentGroup';
import { selectExperiments } from '../selectExperiments';

export const selectIsEmptySearchBannerExperimentEnabled = (state: IAppState) => {
  const experiments = selectExperiments(state);

  return isAbUseExperimentGroup(experiments, AUD_EMPTY_SEARCH_BANNER_EXPERIMENT, 'B');
};
