import { IconProductDateTime16, IconProduct3DTour16, IconMediaVideo16 } from '@cian/ui-kit-design-tokens/icons';
import React from 'react';

import {
  EPhotoFeatureIconsType,
  IPhotoFeatureIcons,
} from '../../../../../../../shared/repositories/search-offers.legacy/v2/types/photoFeatureIcons';
import { useApplicationContext } from '../../../../../../../shared/utils/applicationContext';
import { FeatureLabel } from '../../../../components/FeatureLabel';
import { FeatureLabels } from '../../../../components/FeatureLabels';
import { useOfferContext } from '../../../../context';

const assetMap: { [index: string]: React.ReactNode } = {
  has3dTour: <IconProduct3DTour16 color={'icon-inverted-default'} data-name="3D-tour-icon" />,
  hasFlatTourBooking: <IconProductDateTime16 color={'icon-inverted-default'} />,
  hasVideo: <IconMediaVideo16 color={'icon-inverted-default'} />,
};

export function FeatureLabelsContainer() {
  const { config } = useApplicationContext();
  const { offer } = useOfferContext();
  const { photoFeatureIcons } = offer;

  if (!photoFeatureIcons) {
    return null;
  }

  let filteredIcons = [...photoFeatureIcons];

  const isExternalTourEnabled = !!config.get<boolean>('external_tour_enabled.Enabled');

  if (offer.isExternalTourAvailable && !isExternalTourEnabled) {
    filteredIcons = [...photoFeatureIcons.filter(icon => icon.type !== EPhotoFeatureIconsType.Has3dTour)];
  }

  return (
    <FeatureLabels>
      {filteredIcons.map((icon: IPhotoFeatureIcons, index) => {
        return <FeatureLabel key={index} icon={assetMap[icon.type]} title={icon.text} />;
      })}
    </FeatureLabels>
  );
}
