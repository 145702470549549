/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetUserDataByRequestModel,
  IGetUserDataByRequestRequest,
  IMappers,
  IGetUserDataByRequestResponse,
  IGetUserDataByRequestResponseError,
  TGetUserDataByRequestResponse,
} from './types';

const defaultConfig: TGetUserDataByRequestModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-user-data-by-request/',
  hostType: 'api',
} as TGetUserDataByRequestModel;

function createGetUserDataByRequestModel(parameters: IGetUserDataByRequestRequest): TGetUserDataByRequestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetUserDataByRequestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetUserDataByRequestRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetUserDataByRequest<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetUserDataByRequestOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetUserDataByRequestResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetUserDataByRequestModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetUserDataByRequestResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetUserDataByRequestResponseError);
    }
  }

  return { response, statusCode } as TGetUserDataByRequestResponse;
}

export { defaultConfig, createGetUserDataByRequestModel, fetchGetUserDataByRequest };
