import * as React from 'react';
import { useSelector } from 'react-redux';

import {
  trackSubsidisedMortgageZeroResultsBannerClick,
  trackSubsidisedMortgageZeroResultsBannerShow,
} from './tracking';
import { selectFullUrl } from '../../../selectors/results';
import { useApplicationContext } from '../../../utils/applicationContext';
import { SubsidisedMortgageZeroResultsBanner } from '../../components/SubsidisedMortgageZeroResultsBanner';

type TZeroResultsBannerInfo = {
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
};

export const SubsidisedMortgageZeroResultsBannerContainer: React.FC = () => {
  const { config } = useApplicationContext();

  const fullUrl = useSelector(selectFullUrl);

  const bannerInfo = config.get<TZeroResultsBannerInfo>('subsidizedMortgage.desktopSerpZeroResultsBannerInfo');

  const handleShow = React.useCallback(() => {
    trackSubsidisedMortgageZeroResultsBannerShow();
  }, []);

  const handleClick = React.useCallback(() => {
    trackSubsidisedMortgageZeroResultsBannerClick();
  }, []);

  const url = React.useMemo(() => {
    /* istanbul ignore next */
    if (!fullUrl) {
      return '';
    }

    const url = new URL(fullUrl);

    url.searchParams.delete('is_subsidised_mortgage');

    return url.toString();
  }, [fullUrl]);

  /* istanbul ignore next */
  if (!bannerInfo) {
    return null;
  }

  const { title, subtitle, image, buttonText } = bannerInfo;

  return (
    <SubsidisedMortgageZeroResultsBanner
      buttonText={buttonText}
      image={image}
      onClick={handleClick}
      onShow={handleShow}
      subtitle={subtitle}
      title={title}
      url={url}
    />
  );
};
