/* eslint-disable max-lines */
import { equals } from 'ramda';

import { agentCallsForbiddenTagDefinition, IAgentCallsForbiddenTag } from './definitions/agent_calls_forbidden';
import { apartmentTagDefinition, IApartmentTag } from './definitions/apartment';
import { balconyTagDefinition, IBalconyTag } from './definitions/balcony';
import { blackFridayTagDefinition, IBlackFridayTag } from './definitions/blackFriday';
import { builderTagDefinition, IBuilderTag } from './definitions/builder';
import { ceilingHeightTagDefinition, ICeilingHeightTag } from './definitions/ceiling_height';
import { comissionTypeTagDefinition, IComissionTypeTag } from './definitions/comission_type';
import { decorationTagDefinition, IDecorationTag } from './definitions/decoration';
import {
  demolishedInMoscowProgrammTagDefinition,
  IDemolishedInMoscowProgrammTag,
} from './definitions/demolished_in_moscow_programm';
import { developerTagDefinition, IDeveloperTag } from './definitions/developer';
import { domrfTagDefinition, IDomrfTag } from './definitions/domrf';
import { electronicTradingTypeTagDefinition, IElectronicTradingTag } from './definitions/electronic_trading_type';
import { elevatorsHasServiceTagDefinition, IElevatorsHasServiceTag } from './definitions/elevators_has_service';
import { elevatorsMinCountTagDefinition, IElevatorsMinCountTag } from './definitions/elevators_min_count';
import { facilityTagDefinition, IFacilityTag } from './definitions/facilities';
import { flatShareTagDefinition, IFlatShareTag } from './definitions/flat_share';
import { floorTagDefinition, IFloorTag } from './definitions/floor';
import { furnitureTagDefinition, IFurnitureTag } from './definitions/furniture';
import { garageKindsTagDefinition, IGarageKindsTag } from './definitions/garage_kind';
import { garageMaterialsTagDefinition, IGarageMaterialsTag } from './definitions/garage_material';
import { garageStatusesTagDefinition, IGarageStatusesTag } from './definitions/garage_status';
import { garageTypesTagDefinition, IGarageTypesTag } from './definitions/garage_type';
import {
  geoCircleTagDefinition,
  geoDeveloperTagDefinition,
  geoDistrictTagDefinition,
  geoHighwayTagDefinition,
  geoHouseTagDefinition,
  geoLocationTagDefinition,
  geoNewbuildingTagDefinition,
  geoPolygonTagDefinition,
  geoBboxTagDefinition,
  geoStreetTagDefinition,
  geoUndergroundTagDefinition,
  geoRailwayTagDefinition,
  TGeoTag,
  geoRegionalDistrictTagDefinition,
  IGeoRegionalDistrictTag,
} from './definitions/geo';
import { hasElectricityTagDefinition, IHasElectricityTag } from './definitions/has_electricity';
import {
  hasExtinguishingSystemTagDefinition,
  IHasExtinguishingSystemTag,
} from './definitions/has_extinguishing_system';
import { hasFlatTourBookingTagDefinition, IHasFlatTourBookingTag } from './definitions/has_flat_tour_booking';
import { hasHeatingTagDefinition, IHasHeatingTag } from './definitions/has_heating';
import { IHasLightTag } from './definitions/has_light';
import { hasProfitbaseBookingTagDefinition, IHasProfitbaseBookingTag } from './definitions/has_profitbase_booking';
import { hasWaterTagDefinition, IHasWaterTag } from './definitions/has_water';
import { heatingTypesTagDefinition, IHeatingTypesTag } from './definitions/heating';
import { hiddenBaseTagDefinition, IHiddenBaseTag } from './definitions/hidden_base';
import { houseMaterialTagDefinition, IHouseMaterialTag } from './definitions/house_material';
import { houseYearTagDefinition, IHouseYearTag } from './definitions/house_year';
import { IIdenticalOffersIdTag, identicalOffersIdTagDefinition } from './definitions/identical_offers_id';
import { IByHomeOwnerTag, isByHomeOwnerTagDefinition } from './definitions/is_by_homeowner';
import { IIsFirstFloorTag, isFirstFloorTagDefinition } from './definitions/is_first_floor';
import { IKitchenAreaTag, kitchenAreaTagDefinition } from './definitions/kitchen_area';
import { IKpIdTag, kpIdTagDefinition } from './definitions/kp_id';
import { ILandStatusTypesTag, landStatusTypesTagDefinition } from './definitions/land_status';
import { ILayoutTypeTag, layoutTypeTagDefinition } from './definitions/layout';
import { ILivingAreaTag, livingAreaTagDefinition } from './definitions/living_area';
import { ILoggiaTag, loggiaTagDefinition } from './definitions/loggia';
import { IMaxPrepayTag, maxPrepayTagDefinition } from './definitions/max_prepay';
import { IMortgagePossibleTag, mortgagePossibleTagDefinition } from './definitions/mortgage_possible';
import { IMultiIdTag, multiIdTagDefinition } from './definitions/multi_id';
import { IIncludeNewMoscowTag, includeNewMoscowTagDefinition } from './definitions/new_moscow';
import { INewbuildingCompletedTag, newbuildingCompletedTagDefinition } from './definitions/newbuilding_completed';
import {
  buildingDoneYearsTagDefinition,
  INewbuildingCompletionAfterTag,
} from './definitions/newbuilding_completion_after';
import {
  INewbuildingCompletionYearTag,
  newbuildingCompletionYearTagDefinition,
} from './definitions/newbuilding_completion_year';
import { INotLastFloorTag, notLastFloorTagDefinition } from './definitions/not_last_floor';
import { IOfferIdTag, offerIdTagDefinition } from './definitions/offer_id';
import { IOfferSellerTypesTag, offerSellerTypesTagDefinition } from './definitions/offer_seller';
import { IParkingTypesTag, parkingTypesTagDefinition } from './definitions/parking';
import { IPenthouseTag, penthouseTagDefinition } from './definitions/penthouse';
import { IPhoneTag, phoneTagDefinition } from './definitions/phone';
import { IPossessorTag, possessorTagDefinition } from './definitions/possessor';
import { IPromoSearchTypesTag, promoSearchTypesTagDefinition } from './definitions/promoSearchTypes';
import { IPublishPeriodTag, publishPeriodTagDefinition } from './definitions/publish_period';
import { IRentTimeTag, rentTimeTagDefinition } from './definitions/rent_time';
import { IRepairTag, repairTagDefinition } from './definitions/repair';
import { IRoomAreaTag, roomAreaTagDefinition } from './definitions/room_area';
import { IRoomsInDealTag, roomsInDealTagDefinition } from './definitions/rooms_in_deal';
import { IRoomsTotalTag, roomsTotalTagDefinition } from './definitions/rooms_total';
import { ISaleTypesTag, saleTypesTagDefinition } from './definitions/sale_type';
import { ISiteAreaTag, siteAreaTagDefinition } from './definitions/site_area';
import { ISuburbanHouseTypeTag, suburbanHouseTypeTagDefinition } from './definitions/suburban_house_type';
import { ISuburbanUtilityTag, suburbanUtilityTagDefinition } from './definitions/suburban_utilities';
import { ISuburbanWCTypeTag, suburbanWCTypeTagDefinition } from './definitions/suburban_wc';
import { ITenantsTag, tenantsTagDefinition } from './definitions/tenants';
import { ITotalAreaTag, totalAreaTagDefinition } from './definitions/total_area';
import { ITotalBedroomsTag, totalBedroomsTagDefinition } from './definitions/total_bedrooms';
import { ITotalFloorsTag, totalFloorsTagDefinition } from './definitions/total_floors';
import { ITour3dTag, tour3dDefinition } from './definitions/tour_3d';
import { IUndergroundTimeTag, undergroundTimeDefinition } from './definitions/underground_time';
import { IUserIdTag, userIdTagDefinition } from './definitions/user_id';
import { hasVideoTagDefinition, IHasVideoTag } from './definitions/video';
import { IWCCountTag, wcCountTagDefinition } from './definitions/wc_count';
import { IWCTypeTag, wcTypeTagDefinition } from './definitions/wc_type';
import { IWindowsTypeTag, windowsTagDefinition } from './definitions/windows';
import { IWithLayoutTag, withLayoutTagDefinition } from './definitions/with_layout';
import { IWithPhotoTag, withPhotoTagDefinition } from './definitions/with_photo';
import { IWithoutDepositTag, withoutDepositTagDefinition } from './definitions/without_deposit';
import { IWordsExcludeTag, wordsExcludeTagDefinition } from './definitions/words_exclude';
import { IWordsIncludeTag, wordsIncludeTagDefinition } from './definitions/words_include';
import { isSuburban, offerTypeFromJsonQuery } from '../../../../packages/JsonQuery';
import { IAppState, ITagsData } from '../../../common/state';
import { IJsonQuery } from '../../../json_query';
import { TLocation } from '../../../types/location';

export interface ITagDefinition {
  reduce(tags: TTag[], jsonQuery: IJsonQuery, tagsData: ITagsData, currentLocation: TLocation): TTag[];
}

export type TMultiSelectTag = IGarageKindsTag | IGarageMaterialsTag | IGarageStatusesTag | IGarageTypesTag;

export type TTag =
  | ITour3dTag
  | TMultiSelectTag
  | IAgentCallsForbiddenTag
  | IApartmentTag
  | IBalconyTag
  | IBlackFridayTag
  | IByHomeOwnerTag
  | ICeilingHeightTag
  | IComissionTypeTag
  | IDecorationTag
  | IDemolishedInMoscowProgrammTag
  | IDeveloperTag
  | IDomrfTag
  | IElevatorsHasServiceTag
  | IElevatorsMinCountTag
  | IFacilityTag
  | IFloorTag
  | IFurnitureTag
  | IHasElectricityTag
  | IHasExtinguishingSystemTag
  | IHasFlatTourBookingTag
  | IHasHeatingTag
  | IHasLightTag
  | IHasProfitbaseBookingTag
  | IHasVideoTag
  | IHasWaterTag
  | IHeatingTypesTag
  | IHiddenBaseTag
  | IHouseMaterialTag
  | IHouseYearTag
  | IIncludeNewMoscowTag
  | IIsFirstFloorTag
  | IKitchenAreaTag
  | ILandStatusTypesTag
  | ILayoutTypeTag
  | ILivingAreaTag
  | ILoggiaTag
  | IMaxPrepayTag
  | IMortgagePossibleTag
  | IMultiIdTag
  | INewbuildingCompletedTag
  | INewbuildingCompletionAfterTag
  | INewbuildingCompletionYearTag
  | INotLastFloorTag
  | IOfferIdTag
  | IParkingTypesTag
  | IPenthouseTag
  | IPhoneTag
  | IPublishPeriodTag
  | IRentTimeTag
  | IRepairTag
  | IRoomAreaTag
  | IRoomsInDealTag
  | IRoomsTotalTag
  | ISaleTypesTag
  | ISiteAreaTag
  | ISuburbanUtilityTag
  | ISuburbanWCTypeTag
  | ITenantsTag
  | ITotalAreaTag
  | ITotalFloorsTag
  | IUserIdTag
  | IWCCountTag
  | IWCTypeTag
  | IWindowsTypeTag
  | IWithPhotoTag
  | IWithLayoutTag
  | IWithoutDepositTag
  | IWordsExcludeTag
  | IWordsIncludeTag
  | TGeoTag
  | IKpIdTag
  | IIdenticalOffersIdTag
  | ITotalBedroomsTag
  | ISuburbanHouseTypeTag
  | IPossessorTag
  | IGeoRegionalDistrictTag
  | IFlatShareTag
  | IElectronicTradingTag
  | IOfferSellerTypesTag
  | IUndergroundTimeTag
  | IBuilderTag
  | IPromoSearchTypesTag;

export interface ITagRemovedAction {
  type: 'filters/tags/TAG_REMOVED';
  tag: TTag;
  index: number;
}

export interface IAllTagsRemovedAction {
  type: 'filters/tags/ALL_TAGS_REMOVED';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const tagDefinitions: ITagDefinition[] = [
  ...facilityTagDefinition,
  ...suburbanUtilityTagDefinition,
  tour3dDefinition,
  agentCallsForbiddenTagDefinition,
  apartmentTagDefinition,
  flatShareTagDefinition,
  balconyTagDefinition,
  blackFridayTagDefinition,
  buildingDoneYearsTagDefinition,
  ceilingHeightTagDefinition,
  comissionTypeTagDefinition,
  decorationTagDefinition,
  demolishedInMoscowProgrammTagDefinition,
  developerTagDefinition,
  domrfTagDefinition,
  elevatorsHasServiceTagDefinition,
  elevatorsMinCountTagDefinition,
  floorTagDefinition,
  furnitureTagDefinition,
  garageKindsTagDefinition,
  garageMaterialsTagDefinition,
  garageStatusesTagDefinition,
  garageTypesTagDefinition,
  geoCircleTagDefinition,
  geoDeveloperTagDefinition,
  geoDistrictTagDefinition,
  geoHighwayTagDefinition,
  geoHouseTagDefinition,
  geoLocationTagDefinition,
  geoNewbuildingTagDefinition,
  geoPolygonTagDefinition,
  geoBboxTagDefinition,
  geoStreetTagDefinition,
  geoUndergroundTagDefinition,
  geoRailwayTagDefinition,
  hasElectricityTagDefinition,
  hasExtinguishingSystemTagDefinition,
  hasFlatTourBookingTagDefinition,
  hasHeatingTagDefinition,
  hasProfitbaseBookingTagDefinition,
  hasVideoTagDefinition,
  hasWaterTagDefinition,
  heatingTypesTagDefinition,
  hiddenBaseTagDefinition,
  houseMaterialTagDefinition,
  houseYearTagDefinition,
  includeNewMoscowTagDefinition,
  isByHomeOwnerTagDefinition,
  isFirstFloorTagDefinition,
  kitchenAreaTagDefinition,
  kpIdTagDefinition,
  landStatusTypesTagDefinition,
  layoutTypeTagDefinition,
  livingAreaTagDefinition,
  loggiaTagDefinition,
  maxPrepayTagDefinition,
  mortgagePossibleTagDefinition,
  multiIdTagDefinition,
  newbuildingCompletedTagDefinition,
  newbuildingCompletionYearTagDefinition,
  notLastFloorTagDefinition,
  offerIdTagDefinition,
  parkingTypesTagDefinition,
  penthouseTagDefinition,
  phoneTagDefinition,
  publishPeriodTagDefinition,
  rentTimeTagDefinition,
  repairTagDefinition,
  roomAreaTagDefinition,
  roomsInDealTagDefinition,
  roomsTotalTagDefinition,
  saleTypesTagDefinition,
  suburbanWCTypeTagDefinition,
  suburbanHouseTypeTagDefinition,
  tenantsTagDefinition,
  totalFloorsTagDefinition,
  userIdTagDefinition,
  wcCountTagDefinition,
  wcTypeTagDefinition,
  windowsTagDefinition,
  withPhotoTagDefinition,
  withLayoutTagDefinition,
  withoutDepositTagDefinition,
  wordsExcludeTagDefinition,
  wordsIncludeTagDefinition,
  identicalOffersIdTagDefinition,
  totalBedroomsTagDefinition,
  possessorTagDefinition,
  geoRegionalDistrictTagDefinition,
  electronicTradingTypeTagDefinition,
  offerSellerTypesTagDefinition,
  undergroundTimeDefinition,
  builderTagDefinition,
  promoSearchTypesTagDefinition,
];

export const restoreTagsFromJsonQuery = (jsonQuery: IJsonQuery, tagsData: ITagsData, location: TLocation): TTag[] =>
  tagDefinitions.reduce((acc, tagDefinition) => {
    return tagDefinition.reduce(acc, jsonQuery, tagsData, location);
  }, [] as TTag[]);

export function tagsReducer(state: IAppState): IAppState {
  const offerType = offerTypeFromJsonQuery(state.filters.jsonQuery);

  const definitions = [...tagDefinitions];

  if (!isSuburban(offerType)) {
    definitions.push(siteAreaTagDefinition, totalAreaTagDefinition);
  }

  const tags: TTag[] = definitions.reduce((acc, tagDefinition) => {
    return tagDefinition.reduce(acc, state.filters.jsonQuery, state.filters.tagsData, state.filters.currentLocation);
  }, state.filters.tags);

  if (!equals(state.filters.tags, tags)) {
    return {
      ...state,
      filters: {
        ...state.filters,
        tags,
      },
    };
  }

  return state;
}
