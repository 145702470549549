import { EBannerType } from '../../constants/bannerType';
import { TNewbuildingRentTrapExperimentGroup } from '../experiments/getNewbuildingRentTrapExperimentGroup';

interface IGetBannerTypeParams {
  bannerType:
    | EBannerType.RentNBListing
    | EBannerType.RentKpnCallback
    | EBannerType.SuburbanNBTrap
    | EBannerType.FlatSaleNBTrap;
  experimentGroup: TNewbuildingRentTrapExperimentGroup;
}

export const getBannerType = ({ bannerType, experimentGroup }: IGetBannerTypeParams) => {
  const oldDesign = experimentGroup === 'B';
  const isListingTrap = experimentGroup === 'B';

  switch (bannerType) {
    case EBannerType.RentNBListing:
      return oldDesign ? 'old_link' : 'new_link';
    case EBannerType.RentKpnCallback:
      return oldDesign ? 'old_callback' : 'new_callback';
    case EBannerType.FlatSaleNBTrap:
      return isListingTrap ? 'flat_sale_link' : 'flat_sale_callback';
    case EBannerType.SuburbanNBTrap:
      return isListingTrap ? 'suburban_sale_link' : 'suburban_sale_callback';
  }
};
