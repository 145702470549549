import { IconFunctionalWarningSign16 } from '@cian/ui-kit-design-tokens/icons';
import React, { useMemo } from 'react';

import { Note } from '../../../components/Note';
import { useOfferContext } from '../../../context';

export function PessimizationContainer() {
  const {
    offer: { isRecidivist, isExcludedFromAction },
    isBot,
  } = useOfferContext();

  const pessimization = useMemo(() => {
    if (isRecidivist) {
      return `Этот агент замечен в размещении недостоверных предложений. Возможно, это объявление вымышленное.
        Служба качества Циан не рекомендует звонить по этому объявлению. Если вы позвонили,
        но объекта нет, оставьте жалобу`;
    }
    if (isExcludedFromAction) {
      return `В объявлениях этого агента встречаются ошибки.
        Объект может отличаться от предложенного в объявлении.
        Уточняйте параметры объекта до просмотра. Оставьте жалобу, если обнаружите ошибку.`;
    }

    return null;
  }, [isRecidivist, isExcludedFromAction]);

  if (isBot || !pessimization) {
    return null;
  }

  return (
    <Note
      beforeIcon={
        <IconFunctionalWarningSign16 color={isRecidivist ? 'icon-negative-default' : 'icon-secondary-default'} />
      }
      theme={isRecidivist ? 'danger' : undefined}
    >
      {pessimization}{' '}
      <a href="/help/quality/37645/" target="_blank">
        Подробнее
      </a>
    </Note>
  );
}
