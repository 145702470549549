import { getValueToTagLabelMap } from '../../../../utils/promoSearchTypes';
import { ensureSingleTagExists } from '../helpers';
import { ITagDefinition, TTag } from '../index';

const IPromoSearchTypesTagType = 'promo_search_types';

export interface IPromoSearchTypesTag {
  type: 'promo_search_types';
  value: string;
  label?: string;
}

export function isPromoSearchTypesTag(tag: TTag): tag is IPromoSearchTypesTag {
  return tag.type === IPromoSearchTypesTagType;
}

export const promoSearchTypesTagDefinition: ITagDefinition = {
  reduce: (tags, jsonQuery, tagsData) => {
    const { promoSearchTypes } = tagsData;

    const values = jsonQuery.promo_search_types && jsonQuery.promo_search_types.value;

    let nextTags = tags.filter(
      tag => tag.type !== IPromoSearchTypesTagType || (values && values.some(value => value === tag.value)),
    );

    if (values !== undefined && promoSearchTypes) {
      nextTags = values.reduce(
        (result, value: string) =>
          ensureSingleTagExists(
            result,
            (tag: TTag): tag is IPromoSearchTypesTag => isPromoSearchTypesTag(tag) && tag.value === value,
            () => ({
              type: IPromoSearchTypesTagType,
              value,
              label: getValueToTagLabelMap(promoSearchTypes).get(value),
            }),
          ),
        nextTags,
      );
    }

    return nextTags;
  },
};
