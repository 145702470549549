import { UIHeading5, UIText2 } from '@cian/ui-kit/typography';
import cn from 'classnames';
import * as React from 'react';

import { StarIcon } from './StarIcon';
import * as styles from './styles.css';
import { getReviewsNumber } from '../../utils/getReviewsNumber';

interface IRatingProps {
  className?: string;
  rating?: string;
  reviewsCount?: string;
}

export const Rating = (props: IRatingProps) => {
  const { className, rating, reviewsCount } = props;

  const reviewsCountNumber = getReviewsNumber(reviewsCount);

  const hasRating = rating && rating.length > 0;
  const hasReviewsCount = reviewsCountNumber > 0;

  if (!hasRating && !reviewsCount) {
    return null;
  }

  if (hasRating && !hasReviewsCount) {
    return (
      <div data-testid="RatingComponent" className={cn(styles['root'], className)}>
        <div className={styles['rating']}>
          <StarIcon />{' '}
          <UIHeading5 data-testid="RatingComponent_rating" color="text-warning-default">
            {rating}
          </UIHeading5>
        </div>
      </div>
    );
  }

  if (!hasRating && hasReviewsCount) {
    return (
      <div data-testid="RatingComponent" className={cn(styles['root'], className)}>
        <StarIcon color="#b8c1d6" />
        <span className={styles['reviews-count']}>
          <UIText2 data-testid="RatingComponent_reviews">{reviewsCount}</UIText2>
        </span>
      </div>
    );
  }

  return (
    <div data-testid="RatingComponent" className={cn(styles['root'], className)}>
      <div className={styles['rating']}>
        <StarIcon />{' '}
        <UIHeading5 data-testid="RatingComponent_rating" color="text-warning-default">
          {rating}
        </UIHeading5>
      </div>
      <span className={cn(styles['reviews-count'], styles['dot'])}>
        <UIText2 data-testid="RatingComponent_reviews">{reviewsCount}</UIText2>
      </span>
    </div>
  );
};
