import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { trackOpen } from './tracking';
import { usePushManager } from './usePushManager';
import {
  SaveSearchContainer,
  TOnSubmitSaveSearchParams,
  useSaveSearchEvents,
} from '../../../../packages/SaveSearchModal';
import { IAppState } from '../../../common/state';
import { TUser } from '../../../types/user';

type TSaveSearchModalContainerProps = {
  isOpen: boolean;
  onSubscribed: (parameters: TOnSubmitSaveSearchParams) => void;
  onPushSubscribed: (subscribeToPushAvailable: boolean) => void;
  onOpen: () => void;
  onClose: () => void;
};

export const SaveSearchModalContainer: FC<TSaveSearchModalContainerProps> = ({
  isOpen,
  onSubscribed,
  onPushSubscribed,
  onOpen,
  onClose,
}) => {
  const defaultTitle = useSelector<IAppState, string | null>(state => state.results.seo.h1);
  const user = useSelector<IAppState, TUser>(state => state.user);
  const defaultEmail = useMemo(() => (user.isAuthenticated ? user.email : null), [user]);
  const { subscribeToPushAvailable } = usePushManager(isOpen, trackOpen);

  useEffect(() => {
    onPushSubscribed(subscribeToPushAvailable);
  }, [subscribeToPushAvailable]);

  const handleSubscribed = useCallback(
    ({ frequency, title, email, subscribeToNews, subscribeToPush }: TOnSubmitSaveSearchParams) => {
      if (onSubscribed) {
        onSubscribed({
          frequency,
          title,
          email,
          subscribeToNews,
          defaultTitle,
          defaultEmail,
          subscribeToPushAvailable,
          subscribeToPush,
        });
      }

      if (window.__reloadHeader__) {
        window.__reloadHeader__();
      }
    },
    [defaultEmail, defaultTitle, subscribeToPushAvailable, onSubscribed],
  );

  useSaveSearchEvents(handleSubscribed, onOpen, onClose);

  return <SaveSearchContainer />;
};
