import { IAppState } from '../../common/state';
import {
  getNewbuildingFlatSaleTrapExperimentGroup,
  TNewbuildingFlatSaleTrapExperimentGroup,
} from '../../utils/experiments/getNewbuildingFlatSaleTrapExperimentGroup';

export function selectNewbuildingFlatSaleTrap({
  abUseExperiments,
}: IAppState): TNewbuildingFlatSaleTrapExperimentGroup {
  return getNewbuildingFlatSaleTrapExperimentGroup(abUseExperiments);
}
