import { Button } from '@cian/ui-kit/button';
import { Checkbox } from '@cian/ui-kit/checkbox';
import { FormField } from '@cian/ui-kit/form';
import { Input } from '@cian/ui-kit/input';
import { Header2, InlineText2, InlineText3 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './PreInfiniteSaveSearch.css';

export enum EStatus {
  Initial = 'initial',
  Loading = 'loading',
  Succeed = 'succeed',
  Failed = 'failed',
}

export interface IPreInfiniteSaveSearchProps {
  status: EStatus;
  emailConfirmationRequired: boolean | null;
  errors: string[] | null;
  isUserAuthenticated: boolean;
  email: string;
  subscribeToNews: boolean;
  onEmailChange(email: string): void;
  onSubscribeToNewsChange(subscribeToNews: boolean): void;
  onSubscribe(): void;
}

export const PreInfiniteSaveSearch: React.FC<IPreInfiniteSaveSearchProps> = ({
  status,
  emailConfirmationRequired,
  errors,
  isUserAuthenticated,
  email,
  subscribeToNews,
  onEmailChange,
  onSubscribeToNewsChange,
  onSubscribe,
}) => {
  const isLoading = status === EStatus.Loading;

  const handleEmailChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      onEmailChange(value);
    },
    [onEmailChange],
  );

  const handleSubscribeToNewsChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
      onSubscribeToNewsChange(value);
    },
    [onSubscribeToNewsChange],
  );

  const handleSubscribe = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      onSubscribe();
    },
    [onSubscribe],
  );

  return (
    <>
      <Header2 as="span" color="text-primary-default" fontWeight="bold">
        Сейчас это все объявления по вашему поиску.
        <br />
        Подпишитесь на обновления, и мы сообщим вам, если что-то появится
      </Header2>
      {status === EStatus.Succeed && (
        <div className={styles['message--succeed']}>
          <InlineText2>
            {emailConfirmationRequired ? (
              <span>
                На <b>{email}</b> отправлено письмо для активации подписки
              </span>
            ) : (
              'Мы оповестим вас, когда появятся новые объявления по вашему поиску'
            )}
          </InlineText2>
        </div>
      )}
      {status !== EStatus.Succeed && (
        <form className={styles['form']}>
          <FormField errorMessage={status === EStatus.Failed && errors ? errors.join('; ') : null}>
            <div className={styles['field--main']}>
              <Input
                placeholder="Ваш email"
                maxLength={254}
                disabled={isLoading}
                value={email}
                onChange={handleEmailChange}
              />
              <Button theme="fill_primary" size="XS" loading={isLoading} onClick={handleSubscribe}>
                Подписаться
              </Button>
            </div>
          </FormField>
          <FormField>
            <Checkbox
              label="Получать новости Cian.ru"
              disabled={isLoading}
              checked={subscribeToNews}
              onChange={handleSubscribeToNewsChange}
            />
          </FormField>
          {!isUserAuthenticated && (
            <div className={styles['license']}>
              <InlineText3 color="gray60_100">
                При подписке вы принимаете условия{' '}
                <a target="_blank" href="//www.cian.ru/help/about/rules/" rel="noreferrer">
                  Пользовательского соглашения
                </a>{' '}
                и{' '}
                <a target="_blank" href="//www.cian.ru/help/about/36526/" rel="noreferrer">
                  Политики конфиденциальности
                </a>
              </InlineText3>
            </div>
          )}
        </form>
      )}
    </>
  );
};
