import { type IHttpApi } from '@cian/http-api/shared';
import { type ILogger } from '@cian/logger/shared';

import { handlePayClick } from './handlePayClick';
import { showFailNotification } from './showFailNotification';
import { showNotification } from './showNotification';
import { trackAuctionNotEnoughMoneyPopupShow, trackAuctionSendBetClick } from './tracking';
import { getApplyNewBetDate } from '../../../api/getApplyNewBetDate';
import { setOfferAuctionBet } from '../../../api/setOfferAuctionBet';
import { IOffer } from '../../../types/offer';

interface IUpdateBetResponse {
  nextBet?: number;
  currentBet?: number;
  applyDate?: string;
}

interface IUpdateBetParams {
  bet: number;
  offer: IOffer;
  isReducing: boolean;
}
interface IUpdateBetContext {
  httpApi: IHttpApi;
  logger: ILogger;
}

export async function updateBet(
  { bet, offer, isReducing }: IUpdateBetParams,
  { httpApi, logger }: IUpdateBetContext,
): Promise<IUpdateBetResponse> {
  document.dispatchEvent(new CustomEvent('auctionNotificationHide'));

  const result: IUpdateBetResponse = {};

  try {
    const { status } = await setOfferAuctionBet(httpApi, offer.id, bet);

    if (status === 'completed') {
      if (isReducing) {
        const { applyNewBetDate, nextBet } = await getApplyNewBetDate(httpApi, offer.id);

        result.nextBet = nextBet || undefined;
        result.applyDate = applyNewBetDate || undefined;
      } else {
        result.nextBet = bet;
        result.currentBet = bet;
      }

      trackAuctionSendBetClick(offer, bet);

      showNotification({
        status,
      });
    }

    if (status === 'failed') {
      showFailNotification(async () => {
        await updateBet({ bet, offer, isReducing }, { httpApi, logger });
      });
    }

    if (status === 'notEnoughMoney') {
      showNotification({
        status,
        onPayClick: handlePayClick,
      });

      trackAuctionNotEnoughMoneyPopupShow();
    }
  } catch (e) {
    logger.error(e);
    showFailNotification(async () => {
      await updateBet({ bet, offer, isReducing }, { httpApi, logger });
    });
  }

  return result;
}
