import { ca } from '@cian/analytics';

import { IOffer } from '../../../../../shared/offer';
import { gatherAnalyticsInfo } from '../../../../../shared/offer/analytics_info';

export const trackJoinTradeClick = (offer: IOffer) => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'Phones_Rosimuschestvo_popup_click',
    category: 'Listing',
    products: [gatherAnalyticsInfo(offer)],
  });
};

export const trackShowTradingWidgetModalClick = (offer: IOffer) => {
  ca('eventEbc', {
    name: 'oldevent',
    action: 'Open_listing_rosimuschestvo',
    category: 'Phones',
    label: offer.gaLabel,
    products: [gatherAnalyticsInfo(offer)],
  });
};
