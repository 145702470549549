import { useMemo } from 'react';

import { useApplicationContext } from '../../../../../utils/applicationContext';
import { IVideoShortParams } from '../../../types';

export const DEFAULT_PARAMS = { seek: 0, duration: 8 };

/**
 * Хук для получения параметров видео-шортсов
 */
export const useVideoShortParams = () => {
  const { config } = useApplicationContext();

  return useMemo(() => config.get<IVideoShortParams>('audience.videoShorts.params') || DEFAULT_PARAMS, [config]);
};
