import * as React from 'react';

import { trackIsSoldFurnishedLabelShow, trackShowRosreestrCheckedLabel } from './tracking';
import { LabelsList } from '../../../components/LabelsList';
import { useOfferContext } from '../../../context';

export function LabelsListContainer() {
  const { labels, jsonQuery, offer, position, isRosreestrCheckedLabelAvailable, isSoldFurnishedLabelAvailable } =
    useOfferContext();
  const isHotel = offer?.dailyrent?.hotelData?.isHotel;

  const labelsToShow = [...labels];

  const onShow = React.useCallback(() => {
    if (isRosreestrCheckedLabelAvailable) {
      trackShowRosreestrCheckedLabel(jsonQuery, { offerId: offer.id, position });
    }

    if (isSoldFurnishedLabelAvailable) {
      trackIsSoldFurnishedLabelShow(jsonQuery, { offerId: offer.id, position });
    }
  }, [isRosreestrCheckedLabelAvailable, isSoldFurnishedLabelAvailable, jsonQuery, offer.id, position]);

  if (!labelsToShow.length || isHotel) {
    return null;
  }

  return <LabelsList labels={labelsToShow} onShow={onShow} />;
}
