import * as React from 'react';
import { useSelector } from 'react-redux';

import { SummaryHeaderContainer } from './containers/SummaryHeaderContainer';
import { SummaryMapViewButtonContainer } from './containers/SummaryMapViewButtonContainer';
import { SummarySortDropdownContainer } from './containers/SummarySortDropdownContainer';
import { NewbuildingHousesFilterContainer } from '../../../containers/NewbuildingHousesFilter';
import { getIsHousesFilterEnabled } from '../../../selectors/newbuildingHeader';
import { isResidentialComplexLayoutActive } from '../../../selectors/newbuildingLayouts';
import { selectAggregatedOffers, selectJsonQuery } from '../../../selectors/results';
import { useApplicationContext } from '../../../utils/applicationContext';
import { checkIsHotelsSearch } from '../../../utils/checkIsHotelsSearch';
import { SummarySection } from '../../components/Summary';
import { ModerateLinkButtonContainer } from '../ModerateButtonContainer';

export function SummaryContainer() {
  const { config } = useApplicationContext();
  const jsonQuery = useSelector(selectJsonQuery);
  const isNewbuildingHousesFilterEnabled = useSelector(getIsHousesFilterEnabled);
  const layoutsAbEnabled = useSelector(isResidentialComplexLayoutActive);
  const layoutsFlagEnabled = config.get('Newbuilding.LayoutsListingDesktop.Enabled');
  const aggregatedOffers = useSelector(selectAggregatedOffers);
  const isHotelsSearch = checkIsHotelsSearch(jsonQuery);

  if (aggregatedOffers === 0) {
    return null;
  }

  if (isHotelsSearch) {
    return <SummarySection leftColumn={<SummaryMapViewButtonContainer />} />;
  }

  if (isNewbuildingHousesFilterEnabled && !(layoutsFlagEnabled && layoutsAbEnabled)) {
    return (
      <SummarySection
        leftColumn={
          <>
            <NewbuildingHousesFilterContainer />
            <SummaryHeaderContainer />
          </>
        }
        rightColumn={
          <>
            <SummarySortDropdownContainer />
            <SummaryMapViewButtonContainer />
            <ModerateLinkButtonContainer />
          </>
        }
      />
    );
  }

  return (
    <SummarySection
      leftColumn={
        <>
          <SummaryHeaderContainer />
          <SummarySortDropdownContainer />
          <SummaryMapViewButtonContainer />
        </>
      }
      rightColumn={<ModerateLinkButtonContainer />}
    />
  );
}
