import { ca } from '@cian/analytics';

export function trackSubsidisedMortgageZeroResultsBannerClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountSecondary',
    action: 'Click',
    label: 'EmptyBanner',
  });
}

export function trackSubsidisedMortgageZeroResultsBannerShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountSecondary',
    action: 'Show',
    label: 'EmptyBanner',
  });
}
