import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectRegionId } from '../../../../../../shared/selectors/filters/selectRegionId';
import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { JsonQuery } from '../../../../../JsonQuery';
import { SubsidisedMortgage, TOption } from '../../../components/advancedFilters/SubsidisedMortgage';
import {
  SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY,
  SUBSIDIZED_MORTGAGE_LABEL_TEXT_KEY,
} from '../../../constants/config';
import { useContext } from '../../../utils/useContext';

export const SubsidisedMortgageContainer: React.FC = () => {
  const { jsonQuery, onChange } = useContext();
  const isSubsidisedMortgage = new JsonQuery(jsonQuery).getIsSubsidisedMortgage();
  const { config } = useApplicationContext();
  const regionId = useSelector(selectRegionId);

  const label = config.get<string>(SUBSIDIZED_MORTGAGE_LABEL_TEXT_KEY) || '';
  const subsidizedMortgageExcludedRegions = config.get<number[]>(SUBSIDIZED_MORTGAGE_EXCLUDED_REGIONS_KEY) ?? [];

  const options: TOption[] = [
    { label: 'Неважно', value: null },
    { label, value: true },
  ];

  const handleChange = React.useCallback(
    (isSubsidisedMortgage: boolean | null) => {
      onChange({ action: 'setIsSubsidisedMortgage', arguments: [isSubsidisedMortgage] });
    },
    [onChange],
  );

  if (!label || subsidizedMortgageExcludedRegions.includes(regionId)) {
    return null;
  }

  return <SubsidisedMortgage onChange={handleChange} value={isSubsidisedMortgage} options={options} />;
};
