import { Icon3DTour16 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

export const Features = () => {
  return (
    <div className={style['container']}>
      <div className={style['feature']}>
        <Icon3DTour16 color="icon-inverted-default" />
      </div>
    </div>
  );
};
