import {
  IconMapHighway16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroMsk16,
  IconMapMetroNN16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroSpb16,
  IconMapTrain16,
  IIconProps,
  ISVGIconProps,
} from '@cian/ui-kit-design-tokens/icons';
import React, { FC } from 'react';

import { ColoredIconWrapper } from '../../../../../../../../../common/components/ColoredIconWrapper';
import { TransportTypes } from '../../../../../../../../../types/TransportTypes';

type UndergroundIconProps = {
  iconType: TransportTypes;
  iconColor: string | null;
};

const getIconByType = (iconType: TransportTypes): FC<IIconProps | ISVGIconProps> => {
  switch (iconType) {
    case 'spb':
      return IconMapMetroSpb16;
    case 'ekb':
      return IconMapMetroEkb16;
    case 'kzn':
      return IconMapMetroKzn16;
    case 'nsk':
      return IconMapMetroNovosib16;
    case 'smr':
      return IconMapMetroSam16;
    case 'nn':
      return IconMapMetroNN16;
    case 'mow':
      return IconMapMetroMsk16;
    case 'highway':
      return IconMapHighway16;
    case 'railway':
      return IconMapTrain16;
  }
};

export const TransportIcon: FC<UndergroundIconProps> = props => {
  const { iconType, iconColor } = props;
  const TransportIcon = getIconByType(iconType);

  return (
    <ColoredIconWrapper color={iconColor}>
      <TransportIcon data-testid={`icon-${iconType}`} color="current_color" />
    </ColoredIconWrapper>
  );
};
