import { NB_LAYOUT_LISTING } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

export function isNewbuildingLayoutListingExperimentActive(experiments: IAbUseExperiment[]) {
  const experiment = experiments.find(e => e.experimentName === NB_LAYOUT_LISTING);

  if (!experiment || !experiment.groupName) {
    return false;
  }

  return ['B'].includes(experiment.groupName);
}
