import { NewbuildingKpnBannerContext, KpnBanner } from '@cian/frontend-newbuilding-kpn-banners';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { EBannerType } from '../../constants/bannerType';
import { selectNewbuildingSuburbanSaleTrap } from '../../selectors/experiments/selectNewbuildingSuburbanSaleTrap';
import { getCurrentRegionId } from '../../selectors/getCurrentRegionId';
import { getNewbuildingFlatSaleTrapUrl } from '../../selectors/getNewbuildingFlatSaleTrapUrl';
import { getOffers } from '../../selectors/offers';
import { getUserId, getUserPhone } from '../../selectors/user';
import { useApplicationContext } from '../../utils/applicationContext';
import { gaLabelFormatter } from '../../utils/newbuildingRentBanner/gaLabelFormatter';
import { getBannerType } from '../../utils/newbuildingRentBanner/getBannerType';
import { getPrivacyPolicyUrl } from '../../utils/newbuildingRentBanner/getPrivacyPolicyUrl';

interface INewbuildingSuburbanSaleBannerContainerProps {
  bannerType: EBannerType.SuburbanNBTrap;
}

export const NewbuildingSuburbanSaleBannerContainer = ({
  bannerType,
}: INewbuildingSuburbanSaleBannerContainerProps) => {
  const { httpApi, logger, config } = useApplicationContext();

  const regionId = useSelector(getCurrentRegionId);
  const experimentGroup = useSelector(selectNewbuildingSuburbanSaleTrap);
  const bannerLink = useSelector(getNewbuildingFlatSaleTrapUrl);
  const phone = useSelector(getUserPhone);
  const userId = useSelector(getUserId);
  const offers = useSelector(getOffers);

  /* istanbul ignore next */
  const gaLabel = React.useMemo(() => {
    const gaLabel = offers[0]?.gaLabel;

    if (!gaLabel) {
      return '/rent/flat/';
    }

    return gaLabelFormatter(gaLabel);
  }, [offers]);

  const context = React.useMemo(
    () => ({
      httpApi,
      logger,
    }),
    [httpApi, logger],
  );

  const personalAgreementLink = React.useMemo(() => {
    return getPrivacyPolicyUrl(config);
  }, [config]);

  const defaultPhoneNumber = React.useMemo(() => phone.substring(1), [phone]);

  const banner = React.useMemo(() => {
    return getBannerType({ bannerType, experimentGroup });
  }, [bannerType, experimentGroup]);

  return (
    <div data-testid="NewbuildingSuburbanSaleBannerContainer">
      <NewbuildingKpnBannerContext.Provider value={context}>
        <KpnBanner
          placeType="listing"
          personalAgreementLink={personalAgreementLink}
          regionId={regionId}
          newobjectsListingUrl={bannerLink}
          bannerType={banner}
          defaultPhoneNumber={defaultPhoneNumber}
          gaLabel={gaLabel}
          userId={userId}
        />
      </NewbuildingKpnBannerContext.Provider>
    </div>
  );
};
