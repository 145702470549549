import { Button, TooltipDesktop } from '@cian/ui-kit';
import { IconFunctionalHide16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ControlWrapper } from '../../../../components/ControlWrapper';
import { useOfferContext } from '../../../../context';

export function HideOfferControlContainer() {
  const {
    api: { onHideOffer, onHideOfferAfterAuth },
    isHideOfferInProgress,
    user: { isAuthenticated },
  } = useOfferContext();

  const handleClick = React.useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (!isHideOfferInProgress) {
        if (isAuthenticated) {
          onHideOffer();
        } else {
          onHideOfferAfterAuth();
        }
      }
    },
    [isHideOfferInProgress, isAuthenticated],
  );

  return (
    <ControlWrapper>
      <TooltipDesktop theme="black" placement="left" title="Скрыть">
        <Button
          data-mark="HideOfferControl"
          theme="stroke_secondary"
          onClick={handleClick}
          size="XS"
          loading={isHideOfferInProgress}
          beforeIcon={<IconFunctionalHide16 />}
        />
      </TooltipDesktop>
    </ControlWrapper>
  );
}
