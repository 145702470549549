import { TJsonQueryOptionalKeysToReset } from '../../../../packages/JsonQuery';

export const TERMS_TO_RESET: TJsonQueryOptionalKeysToReset[] = [
  'geo',
  'room_area',
  'kitchen',
  'living_area',
  'balconies',
  'loggia',
  'bedroom_total',
  'ceiling_height',
  'commission_type',
  'cl',
  'has_decoration',
  'apartment',
  'only_flat',
  'penthouse',
  'lifts',
  'lift_service',
  'flat_share',
  'floorn',
  'floor',
  'is_first_floor',
  'not_last_floor',
  'rooms_count',
  'is_in_hidden_base',
  'wp',
  'wc',
  'wc_type',
  'wc_site',
  'windows_type',
  'description_exclude',
  'description_include',
  'repair',
  'rooms_for_sale',
  'garage',
  'garage_material',
  'garage_status',
  'garage_type',
  'garage_garage_type',
  'heating_source_type',
  'house_material',
  'house_year',
  'land_status',
  'room_type',
  'id',
  'id_user',
  'parking_type',
  'contact',
  'publish_period',
  'prepay',
  'zalog',
  'is_dacha',
  'composition',
  'with_layout',
  'only_foot',
  'tour_3d',
  'suburban_offer_filter',
  'kp_id',
  'has_profitbase_booking',
  'bbox',
  'from_developer',
  'yeargte',
  'year',
  'domrf',
  'demolished_in_moscow_programm',
  'electricity',
  'has_electricity',
  'has_extinguishing_system',
  'has_furniture',
  'has_heating',
  'has_light',
  'has_water',
  'has_video',
  'has_drainage',
  'is_by_homeowner',
  'deal_rent',
  'include_new_moscow',
  'hand_over',
  'bath',
  'conditioner',
  'dish_washer',
  'has_fridge',
  'has_fridge',
  'internet',
  'kids',
  'has_kitchen_furniture',
  'pets',
  'phone',
  'pool',
  'bathhouse',
  'security',
  'shower',
  'tv',
  'has_washer',
  'ipoteka',
  'is_black_friday',
  'gas',
  'sost_type',
  'offer_seller_type',
  'foot_min',
  'promo_search_types',
];

export const EXTENDED_TERMS_TO_RESET: TJsonQueryOptionalKeysToReset[] = [...TERMS_TO_RESET, 'site', 'total_area'];
