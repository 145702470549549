import { UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import styles from './EmptyListingBanner.css';
import imageSrc from './assets/image.svg';
import { NoIndex } from './components/NoIndex';

export const EmptyListingBanner = () => {
  const [showEmptyListingBanner, setShowEmptyListingBanner] = React.useState(false);

  React.useEffect(() => {
    /**
     * Отложено показываем баннер
     * Сделано в целях исключения показа текста для поисковых роботов google
     * По идее, они уже умеют дожидаться загрузки контента, но SEO попросили сделать на всякий случай
     */
    setTimeout(() => setShowEmptyListingBanner(true));
  }, []);

  return (
    <NoIndex className={styles['empty-listing-banner']} dataName="EmptyListingBanner">
      {showEmptyListingBanner ? (
        <>
          <img src={imageSrc} className={styles['empty-listing-banner-image']} />
          <div className={styles['empty-listing-banner--text']}>
            <UIHeading2 as="h3">У нас ещё нет таких объявлений</UIHeading2>
            <UIText1 as="div">Попробуйте изменить что-нибудь в фильтрах</UIText1>
          </div>
        </>
      ) : null}
    </NoIndex>
  );
};
