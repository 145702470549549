export enum EPlayerStatus {
  /** Начальное состояние, когда плеер не проиниализирован */
  PlayerNotMounted,
  /**  Плеер начал инициализироваться */
  PlayerInitStart,
  /** Плеер не смог проиниализироваться */
  PlayerInitError,
  /** Плеер готов к воспроизведению */
  PlayerReady,
  /** Плеер готов и идет запуск видео */
  VideoReady,
  /** Видео воспроизводится */
  VideoPlaying,
}
