import { type IHttpApi } from '@cian/http-api';

import {
  createGetApplyNewBetDateByAnnouncementModel,
  IGetApplyNewBetDateByAnnouncementResponse,
  IGetApplyNewBetDateByAnnouncementResponseError,
} from '../../../../shared/repositories/auction/v1/get-apply-new-bet-date-by-announcement';

export const getApplyNewBetDate = (httpApi: IHttpApi, announcementId: number) => {
  return httpApi
    .fetch(createGetApplyNewBetDateByAnnouncementModel({ announcementId }))
    .then(({ response, statusCode }) => {
      if (statusCode === 400) {
        throw response as IGetApplyNewBetDateByAnnouncementResponseError;
      }

      return response as IGetApplyNewBetDateByAnnouncementResponse;
    });
};
