import { EMediaType, TMedia, TPhotoMedia } from '../../../../../shared/components/Carousel';
import { TComputedPropMapper } from '../../types/computed-props-mapper';

export const thumbnails: TComputedPropMapper<Array<TMedia | null> | null> = ({
  offer: { photos, showGalleryThumbnails },
}) => {
  if (!showGalleryThumbnails || photos.length < 2) {
    return null;
  }

  const media = photos
    .map<TPhotoMedia>(photo => ({
      type: photo.isLayout ? EMediaType.Layout : EMediaType.Photo,
      src: photo.thumbnailUrl,
    }))
    .slice(1);

  return new Array(3).fill(null).map((_, index) => media[index] || null);
};
