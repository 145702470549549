import * as React from 'react';

import styles from './SeoText.css';

interface ISeoTextProps {
  text: string;
}

export const SeoText: React.FC<ISeoTextProps> = ({ text }) => (
  <div
    data-name="SeoText"
    className={styles['seo-text']}
    style={{ order: 1 }}
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML={{ __html: text }}
  />
);
