import { ca } from '@cian/analytics';

interface INewbuildingLayoutsSwitchClickInfo {
  isSwitchOn: boolean;
}

export const trackNewbuildingLayoutsSwitchClick = ({ isSwitchOn }: INewbuildingLayoutsSwitchClickInfo) => {
  const action = isSwitchOn ? 'turn_on_sopr' : 'turn_off_sopr';
  ca('eventSite', {
    name: 'oldevent',
    category: 'Layout',
    action,
    label: 'jk_layouts',
  });
};
