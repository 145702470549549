import { Button } from '@cian/ui-kit';
import * as React from 'react';

import { useBuilderChatEnabled, useBuilderChatOpenHandler } from '../../hooks/builderChats';

export const NewbuildingLayoutChatButtonContainer: React.FC = () => {
  const isChatEnabled = useBuilderChatEnabled();
  const openChat = useBuilderChatOpenHandler();

  const handleClick = React.useCallback(() => {
    openChat({
      pageExtraType: 'Open_layout_modal_block',
    });
  }, [openChat]);

  if (!isChatEnabled) {
    return null;
  }

  return (
    <Button onClick={handleClick} size="M" theme="fill_primary">
      Написать застройщику
    </Button>
  );
};
