import { IAppState, TActions } from '../../common/state';
import { FILTERS_SEARCH_SEARCH_REQUEST_FINISHED } from '../../filters/state/search';
import { IResidentialComplexLayoutsCount } from '../../types/residentialComplexLayouts';

export const defaultState: IResidentialComplexLayoutsCount = {
  // Количество планировок установленое при последнем поиске объявлений
  offersCurrentCount: 0,
  // Количество планировок установленое при последнем обновлении фильтров
  offersLastMetaCount: 0,
};

export function newbuildingLayoutsCountReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case 'filters/layoutMeta/META_REFRESH_COMPLETE': {
      return {
        ...state,
        newbuildingLayoutsCount: {
          offersCurrentCount: state.newbuildingLayoutsCount.offersCurrentCount,
          offersLastMetaCount: action.layoutsCountMeta,
        },
      };
    }
    case FILTERS_SEARCH_SEARCH_REQUEST_FINISHED: {
      return {
        ...state,
        newbuildingLayoutsCount: {
          offersCurrentCount: state.newbuildingLayoutsCount.offersLastMetaCount,
          offersLastMetaCount: state.newbuildingLayoutsCount.offersLastMetaCount,
        },
      };
    }
  }

  return state;
}
