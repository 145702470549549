import { Header5, LinkButton, Paragraph2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './ProfessionalSearchBanner.css';
import icon from './icon.png';
import { trackBannerShow } from '../../../packages/Filters/shared/containers/ProfessionalSearchBannerContainer/tracking';

export interface IProfessionalSearchBannerProps {
  link: string;
  onNotnowClick(): void;
  onTrynowClick(): void;
}

export const ProfessionalSearchBanner = ({ link, onTrynowClick, onNotnowClick }: IProfessionalSearchBannerProps) => {
  React.useEffect(() => {
    trackBannerShow();
  }, []);

  return (
    <div className={styles['banner']}>
      <img className={styles['icon']} src={icon} />
      <Paragraph2>Ищите объявления на нескольких площадках одновременно — в профпоиске</Paragraph2>
      <div className={styles['buttons']}>
        <LinkButton href={link} theme={'fill_secondary'} size="XS" onClick={onTrynowClick}>
          Попробовать
        </LinkButton>
        <div className={styles['notnow']} onClick={onNotnowClick}>
          <Header5 color="text-main-default">Не сейчас</Header5>
        </div>
      </div>
    </div>
  );
};
