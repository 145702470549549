import { deleteCookie } from '@cian/utils/lib/browser/cookie';
import { addDays, startOfToday, parseISO, formatISO } from 'date-fns';
import * as React from 'react';

import { trackCloseDatesModal, trackOpenDatesModal } from './tracking';
import { getButtonLabel } from './utils';
import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';
import { checkIsHotelsSearch } from '../../../../../../shared/utils/checkIsHotelsSearch';
import { getDateRangeValue } from '../../../../../JsonQuery';
import { Dates } from '../../../components/filters/Dates';
import { COOKIE_NAME } from '../../../constants/filters/dates';
import { IFilterComponentProps } from '../../../types/filters';
import { useSearchResultsTooltipOpen } from '../../../utils/searchResultsTooltipOpen';
import { useContext } from '../../../utils/useContext';

export const DatesContainer: React.FC<IFilterComponentProps> = ({ filterKey, open, onOpen, onClose }) => {
  const { jsonQuery, jsonQueryCount, jsonQueryCountRefreshing, onChange, onApply, onApplyClick } = useContext();
  const { config } = useApplicationContext();

  const isHotelSearch = checkIsHotelsSearch(jsonQuery);
  const range = getDateRangeValue('dates')(jsonQuery);
  const openTooltip = useSearchResultsTooltipOpen({
    filterKey,
    actions: ['setDates'],
  });

  const today = startOfToday();
  const minDate = today;
  const maxDate = addDays(today, 365);
  const fromValue = range?.gte ? parseISO(range.gte) : null;
  const toValue = range?.lt ? parseISO(range.lt) : null;

  const buttonLabel = getButtonLabel(fromValue, toValue);

  const handleApply = React.useCallback(() => {
    if (onApplyClick) {
      onApplyClick({ source: 'tooltip' });
    }

    onApply();

    if (onClose) {
      onClose();
    }
  }, [onApply, onApplyClick, onClose]);

  const handleDatesChange = React.useCallback(
    (from: Date | undefined, to: Date | undefined) => {
      let fromValue = from;
      let toValue = to;

      if (fromValue && !toValue) {
        toValue = addDays(fromValue, 1);
      }
      if (toValue && !fromValue) {
        fromValue = addDays(toValue, -1);
      }
      if (fromValue && toValue && fromValue >= toValue) {
        toValue = addDays(fromValue, 1);
      }

      if (fromValue && toValue) {
        const gte = formatISO(fromValue, { representation: 'date' });
        const lte = formatISO(toValue, { representation: 'date' });

        onChange({ action: 'setDates', arguments: [gte, lte] });
      }
    },
    [onChange],
  );

  const handleOpenModal = React.useCallback(() => {
    trackOpenDatesModal();
    onOpen?.();
  }, [onOpen]);

  const handleCloseModal = React.useCallback(() => {
    trackCloseDatesModal();
    onClose?.();
  }, [onClose]);

  React.useEffect(() => {
    const isDatesModalAutoOpenRSEnabled = config.get<boolean>('Dailyrent.DatesAutoOpen.Enabled');
    const hasSkipCookie = document.cookie.includes(COOKIE_NAME);

    const { gte, lt } = getDateRangeValue('dates')(jsonQuery) || {};
    const hasDates = gte && lt;

    if (isDatesModalAutoOpenRSEnabled && !hasDates && !hasSkipCookie) {
      trackOpenDatesModal();
      onOpen?.();
    }

    if (hasSkipCookie) {
      deleteCookie(COOKIE_NAME);
    }
  }, []);

  return (
    <Dates
      isOpen={open}
      buttonLabel={buttonLabel}
      minDate={minDate}
      maxDate={maxDate}
      fromValue={fromValue}
      toValue={toValue}
      hideTooltipCount={isHotelSearch}
      offersCount={jsonQueryCount}
      offersCountLoading={jsonQueryCountRefreshing}
      openTooltip={openTooltip}
      onButtonClick={handleOpenModal}
      onDatesChange={handleDatesChange}
      onApply={handleApply}
      onClose={handleCloseModal}
    />
  );
};
