/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TApiCallbackAddModel,
  IApiCallbackAddRequest,
  IMappers,
  IApiCallbackAddResponse,
  IApiCallbackAddResponseError,
  TApiCallbackAddResponse,
} from './types';

const defaultConfig: TApiCallbackAddModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/api/callback/add/',
  hostType: 'ud-api',
} as TApiCallbackAddModel;

function createApiCallbackAddModel(parameters: IApiCallbackAddRequest): TApiCallbackAddModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IApiCallbackAddOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IApiCallbackAddRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchApiCallbackAdd<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IApiCallbackAddOptions<TResponse200, TResponse400>): Promise<TResponse200 | TResponse400 | TApiCallbackAddResponse> {
  const { response, statusCode } = await httpApi.fetch(createApiCallbackAddModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IApiCallbackAddResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IApiCallbackAddResponseError);
    }
  }

  return { response, statusCode } as TApiCallbackAddResponse;
}

export { defaultConfig, createApiCallbackAddModel, fetchApiCallbackAdd };
