import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingFlightBanner } from './NewbuildingFlightBanner';
import { trackBannerShow, trackBannerClick } from './tracking';
import { getJsonQuery } from '../../../selectors/getJsonQuery';
import { selectNewbuildingFlightEntryUrl } from '../../../selectors/newbuildingFlight';

export function NewbuildingFlightBannerContainer() {
  const jsonQuery = useSelector(getJsonQuery);
  const url = useSelector(selectNewbuildingFlightEntryUrl) as string;

  const handleBannerShown = React.useCallback(() => trackBannerShow(jsonQuery), [jsonQuery]);
  const handleBannerClick = React.useCallback(() => trackBannerClick(jsonQuery), [jsonQuery]);

  return (
    <ActionAfterViewObserver callback={handleBannerShown} triggerOnce>
      <NewbuildingFlightBanner url={url} onBannerClick={handleBannerClick} />
    </ActionAfterViewObserver>
  );
}
