import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackSendMessageClick } from './tracking';
import { openNewbuildingChat, openNewbuildingChatAfterAuth } from '../../../actions/chat';
import { getNewbuildingInformation, getBuilder } from '../../../selectors/newbuildingHeader';
import { getUser } from '../../../selectors/user';
import { callAuthenticationPopup } from '../../../utils/authentication';

type TOpenChatParams = {
  pageExtraType: string;
};

export function useBuilderChatOpenHandler() {
  const dispatch = useDispatch();

  const user = useSelector(getUser);
  const newbuilding = useSelector(getNewbuildingInformation);
  const builder = useSelector(getBuilder);

  return React.useCallback(
    ({ pageExtraType }: TOpenChatParams) => {
      const isOwnNewbuilding = user.isAuthenticated && builder?.realtyUserId === user.userId;

      if (!newbuilding || isOwnNewbuilding) {
        return;
      }

      trackSendMessageClick(newbuilding.id ?? 0, pageExtraType);

      if (!user.isAuthenticated) {
        dispatch(openNewbuildingChatAfterAuth(newbuilding));
        callAuthenticationPopup();

        return;
      }

      dispatch(openNewbuildingChat(newbuilding));
    },
    [dispatch, user, newbuilding, builder],
  );
}
