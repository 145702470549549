import { call, put, select, take } from 'redux-saga/effects';

import {
  FILTERS_SEARCH_LAYOUT_SEARCH_REQUEST_START_REQUESTED,
  FILTERS_SEARCH_SEARCH_REQUEST_FINISHED,
  isRedirectNeeded,
  setLoading,
} from './search';
import { JsonQuery } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';
import { fetchQuickLinksSafe } from '../../services/fetchQuickLinks';
import { fetchSearchNewbuildingLayouts } from '../../services/fetchSearchNewbuildingLayouts/fetchSearchNewbuildingLayouts';
import { IApplicationContext } from '../../types/applicationContext';
import { IQuickLinks } from '../../types/quickLinks';
import { INewbuildingLayoutsForDesktop } from '../../types/residentialComplexLayouts/layouts/residentialComplexLayouts';
import { displayConsultantPhone } from '../../utils/displayNewbuildingConsultantPhone';
import { getNewbuildingBannerRandomBoolean } from '../../utils/getNewbuildingBannerRandomBoolean';
import { saveHistorySearch } from '../../utils/history_search';
import { isSubdomainRedirectNeeded } from '../../utils/isSubdomainRedirectNeeded';

let prevRegionsValue: number[] = [];

export function* searchLayoutSaga() {
  while (true) {
    let state: IAppState = yield select();

    yield take(FILTERS_SEARCH_LAYOUT_SEARCH_REQUEST_START_REQUESTED);

    yield put(setLoading());

    state = yield select();

    if (state.filters.jsonQueryCountRefresing) {
      // Block search while meta is loading
      yield take(['filters/meta/META_REFRESH_COMPLETE', 'filters/meta/META_REFRESH_FAILED']);
    }

    state = yield select();

    const {
      httpApi,
      filters: { jsonQuery, jsonQueryFullUrl },
      config,
      logger,
      currentSubdomain,
    } = state;

    if (
      jsonQueryFullUrl &&
      (isRedirectNeeded(config, jsonQuery, jsonQueryFullUrl) || isSubdomainRedirectNeeded(jsonQueryFullUrl))
    ) {
      window.location.assign(jsonQueryFullUrl);

      return;
    }

    const isRegionChanged = (value: number[]) => {
      let isChanged = false;

      if (prevRegionsValue.length === value.length) {
        const prev = prevRegionsValue.sort();
        const next = value.sort();

        prev.forEach((n, i) => {
          if (n !== next[i]) {
            isChanged = true;
          }
        });
      } else {
        isChanged = true;
      }

      prevRegionsValue = value;

      return isChanged;
    };

    if (window.__reloadHeader__ && jsonQuery.region && isRegionChanged(jsonQuery.region.value)) {
      window.__reloadHeader__();
    }

    const data: INewbuildingLayoutsForDesktop = yield call(
      fetchSearchNewbuildingLayouts,
      { httpApi, logger, config },
      {
        jsonQuery: new JsonQuery(jsonQuery).toJSON(),
        subdomain: currentSubdomain,
        path: jsonQueryFullUrl || '',
      },
    );

    /**
     * Сохранение текущего поиска для истории поисков
     */
    saveHistorySearch(data.seoData.h1, data.fullUrl, data.jsonQuery);

    const quickLinks: IQuickLinks | null = yield call(fetchQuickLinksSafe, { httpApi, logger } as IApplicationContext, {
      subdomain: currentSubdomain,
      jsonQuery: data.jsonQuery,
    });

    state = yield select();

    document.title = data.seoData.title || document.title;
    window.history.pushState({}, document.title, state.filters.jsonQueryUrl);

    yield put({
      type: FILTERS_SEARCH_SEARCH_REQUEST_FINISHED,
      data: {
        offers: [],
        offersSerialized: [],
        avgPriceInformer: data.avgPriceInformer,
        queryString: data.queryString,
        jsonQuery: data.jsonQuery,
        fullUrl: data.fullUrl,
        seoData: data.seoData,
        seoLinks: data.seoLinks,
        offerCount: data.offerCount,
        suggestOffersSerializedList: [],
        suggestionsQuery: '',
        qsToUris: data.qsToUris,
        kp: null,
        extensionTypes: [],
        puids: data.puids,
        ymEvents: data.ymEvents,
        breadcrumbs: data.breadcrumbs,
        mlRankingGuid: state.mlRankingGuid,
        mlRankingModelVersion: state.mlRankingModelVersion,
        newbuildingIdsForBuildersPromoSlider: [],
        newbuildingPromoBuilderOffers: null,
        isNewbuildingsOnly: (data.jsonQuery.building_status || { value: undefined }).value === 2,
        collections: state.collections,
        newbuildingSimilarOffersBlock: null,
        isNewbuildingBannerRandomRotationEnabled: getNewbuildingBannerRandomBoolean(state.config),
        newbuilding: data.newbuildingInformation || null,
      },
      quickLinks,
      newbuildingLayouts: data.layouts || null,
      newbuildingInfo: null,
      newbuildingInformation: data.newbuildingInformation || null,
      villageInfo: null,
      similarNewobjects: null,
      similarNewobjectsFromDeveloper: null,
      recommendedVillages: null,
      suburbanBuildersProjects: [],
    });

    state = yield select();

    const consultantPhone = config.get<string>('newbuildingConsultant.phone.moscowAndRegion');
    const consultantPhoneEnabledRegions = config.get<number[]>('newbuildingConsultant.phone.enabledRegions') || [];
    displayConsultantPhone(state, consultantPhoneEnabledRegions, consultantPhone);
  }
}
