import { ModalWindow, UIText2, Button, LinkButton, UIHeading1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { IDownloadExcelModalAllProps } from './types';

export const DownloadExcelModal = React.forwardRef<HTMLDivElement, IDownloadExcelModalAllProps>((props, ref) => {
  const {
    isOpened,
    onClose,
    error,
    onLinkBtnClick,
    onDownloadClick,
    isLoading,
    footer,
    title,
    doneList,
    linkButtonText,
    linkButtonHref,
  } = props;

  return (
    <ModalWindow
      open={isOpened}
      onClose={onClose}
      title=""
      size="XS"
      content={
        <div ref={ref} className={styles['content']} data-name="download_excel_modal">
          <div className={styles['title']}>
            <UIHeading1>{title}</UIHeading1>
          </div>
          {doneList}
          <div className={styles['actions']}>
            <LinkButton onClick={onLinkBtnClick} href={linkButtonHref} target="_blank" rel="noreferrer">
              {linkButtonText}
            </LinkButton>
            <div className={styles['download_btn_wrapper']}>
              {error && (
                <div className={styles['error']}>
                  <UIText2 color="text-inverted-default">{error}</UIText2>
                </div>
              )}
              <Button
                theme="fill_white_primary"
                type="button"
                onClick={onDownloadClick}
                loading={isLoading}
                disabled={Boolean(error)}
                data-name="download_excel_trigger"
              >
                Сохранить файл в Excel
              </Button>
            </div>
          </div>
        </div>
      }
      footer={footer}
    />
  );
});

DownloadExcelModal.displayName = 'DownloadExcelModal';
