export function isNeedToShow(key: string): boolean {
  const lastShown = Number(localStorage.getItem(key));
  const now = new Date().getTime();

  return !lastShown || lastShown < now;
}

export function updateShownTimestamp(key: string, interval: number) {
  const now = new Date().getTime();
  const expired = now + interval;

  localStorage.setItem(key, String(expired));
}
