import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackModalOpen } from './tracking';
import { getOfferChatSrc } from './utils';
import { closeChat } from '../../../actions/chat';
import { ChatPopup } from '../../../components/ChatPopup';
import { selectDate } from '../../../selectors/filters/selectDate';
import { selectChat } from '../../../selectors/selectChat';

export const OfferChatPopupContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpened, offer, position } = useSelector(selectChat);
  const dates = useSelector(selectDate);

  React.useEffect(() => {
    if (isOpened && offer && position) {
      trackModalOpen(offer, position);
    }
  }, [isOpened, offer, position]);

  const onClose = React.useCallback(() => dispatch(closeChat()), [dispatch]);

  if (!isOpened) {
    return null;
  }

  const src = getOfferChatSrc(offer, dates);

  return <ChatPopup src={src} onClose={onClose} />;
};
