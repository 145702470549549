import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingHousesFilterContainer } from '../../../containers/NewbuildingHousesFilter';
import { NewbuildingLayoutsFilterContainer } from '../../../containers/NewbuildingLayoutsFilterContainer';
import { getIsHousesFilterEnabled } from '../../../selectors/newbuildingHeader';
import { isResidentialComplexLayoutActive } from '../../../selectors/newbuildingLayouts';
import { selectAggregatedOffers } from '../../../selectors/results';
import { useApplicationContext } from '../../../utils/applicationContext';
import { NewbuildingFilters } from '../../components/NewbuildingFilters';

export function NewbuildingFiltersContainer() {
  const { config } = useApplicationContext();
  const isNewbuildingHousesFilterEnabled = useSelector(getIsHousesFilterEnabled);
  const layoutsAbEnabled = useSelector(isResidentialComplexLayoutActive);
  const layoutsFlagEnabled = config.get('Newbuilding.LayoutsListingDesktop.Enabled');
  const aggregatedOffers = useSelector(selectAggregatedOffers);

  if (aggregatedOffers === 0) {
    return null;
  }

  if (!isNewbuildingHousesFilterEnabled || !layoutsFlagEnabled || !layoutsAbEnabled) {
    return null;
  }

  return (
    <NewbuildingFilters
      housesFilter={<NewbuildingHousesFilterContainer withLabel={true} />}
      layoutsToggle={<NewbuildingLayoutsFilterContainer />}
    />
  );
}
