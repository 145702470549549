/* eslint-disable max-lines */

import * as React from 'react';

import { TOffersProps } from './index';
import { MortgageCardCreatorBanner } from './internal/components/MortgageCardCreatorBanner';
import { ParallaxBanner } from './internal/components/ParallaxBanner';
import { SuburbanListingBanner } from './internal/components/SuburbanListingBanner';
import { TGBBanner } from './internal/components/TGBBanner';
import { prepareOfferCardComponentsProps } from './offer_card';
import { trackFavoriteAdded, trackFavoriteRemoved, trackPageJumped, trackPhoneOpened } from './tracking';
import { OfferContainer } from '../../../../fragments/OfferCardWidget/shared/containers/OfferContainer';
import { isNewbuildingBookedFromDeveloper } from '../../../../fragments/OfferCardWidget/shared/utils/newbuildingBooking';
import { getDateRangeValue, getRangeValue, TJsonQuery } from '../../../../packages/JsonQuery';
import { TOfferCategory } from '../../../../packages/api-models/search-offers/v2/types';
import { SuburbanBuildersProjectsContainer } from '../../../containers/SuburbanBuildersProjectsContainer';
import { IJsonQuery } from '../../../json_query';
import { IBreadcrumb, IOffer } from '../../../offer/types';
import { trackHiddenObjects } from '../../../tracking/hide_offer';
import { ISuburbanProjectSuggest } from '../../../types/suburbanBuildersProjects';
import { isDealRentDisablePhonesExperimentActive } from '../../../utils/experiments/dealRentDisablePhones';
import { getIsNewbuildingFromJsonQuery } from '../../../utils/getIsNewbuildingFromJsonQuery';
import { isRosreestrCheckedLabelAvailable } from '../../../utils/isRosreestrCheckedLabelAvailable';
import { isSaleFromSuburbanBuilder } from '../../../utils/isSaleFromSuburbanBuilder';
import { getOfferKpAdvantages } from '../../../utils/trackings';
import { BannerContainer } from '../../containers/Banner';
import { EHideOfferState } from '../../state/hide_offer';
import { CountrysideTrapBanner } from '../CountrysideTrapBanner';

type TSuggestionFragment = { isSimilar: false } | { isSimilar: true; startIndex: number };

export interface IIntegrateAdOptions {
  currentPageNumber: number;
  perPage: number;
  jsonQuery: IJsonQuery;
  offers: IOffer[];
  suggestions?: IOffer[] | null;
  offerCardCreator(
    offer: IOffer,
    position: number,
    index: number,
    currentPageNumber: number,
    keyPrefix: string,
  ): React.ReactNode;
  isPrintEnabled: boolean;
  suggestionFragment: TSuggestionFragment;
  hiddenOffers?: number[];
  hiddenSuggestions?: number[];
  animatingOffer?: number | null;
  locationId?: number;
  positionOffset?: number;
  extendedOffersOffset?: number;
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
  isAgent?: boolean;
  villagesLink?: string;
  showCountrysideTrap?: boolean;
  keyPrefix: string;
  trackShowNBMotivationPopup?: boolean;
  motivationPopupPosition?: number;
  isCountrysideBuildersBannerAvailable?: boolean;
  servicesBannerPositions: number[];
  showSuburbanListingBanner?: boolean;
  suburbanBuildersProjects?: ISuburbanProjectSuggest[];
}

/**
 * Требования к позициям банsнеров
 * @see https://conf.cian.tech/pages/viewpage.action?pageId=1319907301
 */
const PARALLAX_BANNER_1_POSITION_OFFSET = 10;
const PARALLAX_BANNER_2_POSITION_OFFSET = 20;

const TGP_BANNER_1_POSITION_OFFSET = 5;
const TGP_BANNER_2_POSITION_OFFSET = 15;
const TGP_BANNER_3_POSITION_OFFSET = 20;
const TGP_BANNER_4_POSITION_OFFSET = 25;

const SUBURBAN_LISTING_BANNER_POSITION_OFFSET = 7;
const SUBURBAN_BUILDERS_PROJECTS_POSITION_OFFSET = 12;
/**
 * position !== positionOffset
 */
const COUNTRYSIDE_TRAP_BANNER_POSITION = 1;

export const isDailyrent = (offerCategory: TOfferCategory | null) => {
  return offerCategory && ['dailyHouseRent', 'dailyBedRent', 'dailyFlatRent', 'dailyRoomRent'].includes(offerCategory);
};

interface IUpdateDailyrentUrlParameters {
  currentUrl: string;
  dates?: { checkin: string; checkout: string };
  guestsCount?: number;
}

export const updateUrlWithDailyrentParameters = ({
  currentUrl,
  dates,
  guestsCount,
}: IUpdateDailyrentUrlParameters): string => {
  const url = new URL(currentUrl);

  if (dates) {
    url.searchParams.set('checkin', dates.checkin);
    url.searchParams.set('checkout', dates.checkout);
  } else {
    url.searchParams.delete('checkin');
    url.searchParams.delete('checkout');
  }

  if (guestsCount && guestsCount > 1) {
    url.searchParams.set('min_beds', String(guestsCount));
  } else {
    url.searchParams.delete('min_beds');
  }

  return url.toString();
};

const getUpdatedUrlWithDates = (jsonQuery: TJsonQuery, offer: IOffer) => {
  const isDailyrentOffer = isDailyrent(offer.category);

  const jsonQueryGuests = getRangeValue('beds_count')(jsonQuery);
  const jsonQueryDates = getDateRangeValue('dates')(jsonQuery);

  const guestsCount = jsonQueryGuests?.gte;
  const checkin = jsonQueryDates?.gte;
  const checkout = jsonQueryDates?.lt;

  const hasDates = checkin && checkout;
  const hasGuests = guestsCount && guestsCount > 1;

  const shouldUpdateUrl = (hasDates || hasGuests) && isDailyrentOffer;

  if (!shouldUpdateUrl) {
    return undefined;
  }

  const dates = hasDates ? { checkin, checkout } : undefined;

  return updateUrlWithDailyrentParameters({
    currentUrl: offer.fullUrl,
    dates,
    guestsCount,
  });
};

export const integrateAd = (options: IIntegrateAdOptions) => {
  const {
    currentPageNumber,
    perPage,
    offers,
    suggestions,
    offerCardCreator,
    isPrintEnabled,
    locationId,
    suggestionFragment,
    jsonQuery,
    hiddenOffers = [],
    hiddenSuggestions = [],
    isAgent,
    villagesLink,
    showCountrysideTrap,
    extendedOffersOffset,
    keyPrefix,
    isCountrysideBuildersBannerAvailable,
    servicesBannerPositions: SERVICES_BANNER_POSITIONS,
    showSuburbanListingBanner,
    suburbanBuildersProjects,
  } = options;

  let positionOffset = options.positionOffset || 0;

  const isNewbuilding = getIsNewbuildingFromJsonQuery(jsonQuery);

  return offers.reduce((acc: React.ReactNode[], offer: IOffer, index) => {
    const getBannerKey = (bannerName: string) => `${keyPrefix}_${bannerName}_${offer.id}`;

    const urlWithDates = getUpdatedUrlWithDates(jsonQuery, offer);
    if (urlWithDates) {
      offer.fullUrl = urlWithDates;
    }

    const suggestionIds = (suggestions || []).map(item => item.id);

    if (!hiddenOffers.includes(offer.id) && !hiddenSuggestions.includes(offer.id)) {
      const isOfferExtended = suggestionIds.includes(offer.id);
      const position = (currentPageNumber - 1) * perPage + index;
      // порядок для события считаем начиная с единицы
      let trackingPosition = position + 1;

      // у добивок собственное начало отсчета
      trackingPosition = isOfferExtended ? trackingPosition - (extendedOffersOffset || 0) : trackingPosition;

      const nativeTgbPosition = trackingPosition + 1;

      positionOffset += 1;

      acc.push(
        offerCardCreator(
          offer,
          trackingPosition,
          suggestionFragment.isSimilar ? index + suggestionFragment.startIndex : index,
          currentPageNumber,
          keyPrefix,
        ),
      );

      if (!isPrintEnabled) {
        // TGP BANNER
        if (
          [TGP_BANNER_1_POSITION_OFFSET, TGP_BANNER_2_POSITION_OFFSET, TGP_BANNER_4_POSITION_OFFSET].includes(
            positionOffset,
          ) ||
          (TGP_BANNER_3_POSITION_OFFSET === positionOffset && isNewbuilding)
        ) {
          acc.push(
            <TGBBanner key={getBannerKey('TgpBanner')} position={nativeTgbPosition} isNewbuilding={isNewbuilding} />,
          );
        }

        if (positionOffset === SUBURBAN_LISTING_BANNER_POSITION_OFFSET && showSuburbanListingBanner) {
          acc.push(<SuburbanListingBanner key={getBannerKey('SuburbanListingBanner')} position={nativeTgbPosition} />);
        }

        // COUNTRYSIDE TRAP BANNER
        if (showCountrysideTrap && villagesLink && position === COUNTRYSIDE_TRAP_BANNER_POSITION) {
          acc.push(<CountrysideTrapBanner key={`countryside_banner_${position}`} villagesLink={villagesLink} />);
        }

        // SERVICES BANNER
        if (offers.length !== positionOffset && SERVICES_BANNER_POSITIONS.includes(position)) {
          acc.push(
            <BannerContainer
              key={`banner_${position}`}
              isAgent={isAgent}
              options={options}
              position={position}
              locationId={locationId}
              isCountrysideBuildersBannerAvailable={isCountrysideBuildersBannerAvailable}
            />,
          );
        }

        // PARALLAX BANNER
        if (
          positionOffset === PARALLAX_BANNER_1_POSITION_OFFSET ||
          (positionOffset === PARALLAX_BANNER_2_POSITION_OFFSET && !isNewbuilding)
        ) {
          acc.push(
            <ParallaxBanner
              key={getBannerKey('ParallaxBanner')}
              isNewbuilding={isNewbuilding}
              position={nativeTgbPosition}
            />,
          );
        }

        if (positionOffset === SUBURBAN_BUILDERS_PROJECTS_POSITION_OFFSET && suburbanBuildersProjects?.length) {
          acc.push(<SuburbanBuildersProjectsContainer />);
        }
      }
    }

    return acc;
  }, []);
};

export const getOnFavoriteChange =
  (
    offer: IOffer,
    jsonQuery: IJsonQuery,
    position: number,
    onFavoriteChangedHandler: (offer: IOffer, isFavorite: boolean) => void,
  ) =>
  (isFavorite: boolean) => {
    const trackingLabel = offer.gaLabel;

    if (isFavorite) {
      const kpAdvantages = isSaleFromSuburbanBuilder(offer) ? getOfferKpAdvantages(offer) : undefined;

      trackFavoriteAdded(jsonQuery, { offerId: offer.id, position, kpAdvantages }, trackingLabel || undefined);
    } else {
      trackFavoriteRemoved(jsonQuery, { offerId: offer.id, position }, trackingLabel || undefined);
    }

    onFavoriteChangedHandler(offer, isFavorite);
  };

export interface IOfferCardCreatorProps extends TOffersProps {
  offersQty: number;
  queryString: string;
  breadcrumbs: IBreadcrumb[];
}

interface IGetOfferCardCreator {
  props: IOfferCardCreatorProps;
  isSimilar?: boolean;
  isSimilarFromML?: boolean;
  pageNum?: number;
  isExtended?: boolean;
}

export const getOfferCardCreator =
  ({ props, isSimilar, isSimilarFromML, pageNum }: IGetOfferCardCreator) =>
  // eslint-disable-next-line react/display-name
  (offer: IOffer, position: number, index: number, currentPageNumber: number, keyPrefix: string): React.ReactNode => {
    const {
      breadcrumbs,
      currentPath,
      onFavoriteChanged,
      isPrintEnabled,
      jsonQuery,
      onUserInfoPopupClose,
      onUserInfoPopupOpen,
      hideOfferAction,
      hideOfferAfterAuthAction,
      qsToUris,
      suggestionDistancesSeoText,
      user,
      offersQty,
      mlRankingGuid,
      mlRankingModelVersion,
      queryString,
      hideOffer: { hideOfferState, hidingOffer },
      maxAuctionBet,
      maxAuctionService,
      trackingData,
      auctionBanks,
      villagePromotionLabel,
      addToComparison,
      deleteFromComparison,
      openChat,
      openNotAvailablePopup,
      addToComparisonAfterAuth,
    } = props;

    const { id, bargainTerms } = offer;
    const withAuction = Boolean(
      !!auctionBanks && !!offer.user && !!offer.user.cianUserId && auctionBanks.includes(+offer.user.cianUserId),
    );

    const onPopupClose = () => onUserInfoPopupClose(offer.id);
    const onPopupOpen = () => onUserInfoPopupOpen(offer.id);
    const onHideOffer =
      hideOfferState === EHideOfferState.FETCHING || hideOfferState === EHideOfferState.ANIMATING
        ? () => {}
        : () => {
            trackHiddenObjects({ offer, jsonQuery });
            hideOfferAction({ offer, isSimilar: !!isSimilar });
          };
    const onHideOfferAfterAuth = () => {
      hideOfferAfterAuthAction({ offer, isSimilar: !!isSimilar });
    };

    const mortgageBanner =
      bargainTerms && bargainTerms.mortgageAllowed && !isPrintEnabled ? (
        <MortgageCardCreatorBanner key={`${keyPrefix}_MortgageCardCreatorBanner_${offer.id}`} />
      ) : null;

    const onPageJumped = (label: string) =>
      trackPageJumped(offer, {
        user,
        jsonQuery,
        position,
        isSimilarFromML,
        label,
        pageNum,
        breadcrumbs,
        queryString,
        offersQty,
        mlRankingGuid,
        mlRankingModelVersion,
      });

    const offerCardProps = prepareOfferCardComponentsProps(props, {
      mortgageBanner,
      onPageJumped,
      onPopupClose,
      onPopupOpen,
      onHideOffer,
      onHideOfferAfterAuth,
      onFavoriteChange: getOnFavoriteChange(offer, jsonQuery, position, onFavoriteChanged),
      trackPhoneOpened: (isFromBrandBlock?: boolean) =>
        trackPhoneOpened(offer, position, jsonQuery, user, offersQty, queryString, isSimilar, isFromBrandBlock),
      offer,
      jsonQuery,
      position,
      addToComparison,
      addToComparisonAfterAuth,
      deleteFromComparison,
    });

    return (
      <OfferContainer
        key={`${keyPrefix}-${id}`}
        index={index}
        currentPath={currentPath}
        suggestionDistancesSeoText={suggestionDistancesSeoText}
        position={position}
        trackingData={trackingData}
        queryString={queryString}
        offer={offer}
        jsonQuery={jsonQuery}
        qsToUris={qsToUris}
        user={user}
        mortgageBanner={mortgageBanner}
        excludedUtilitiesTermsRegions={offerCardProps.excludedUtilitiesTermsRegions}
        maxAuctionBet={maxAuctionBet}
        maxAuctionService={maxAuctionService}
        isHideOfferInProgress={
          (Boolean(hidingOffer && hidingOffer.offer.id === offer.id) && hideOfferState === EHideOfferState.FETCHING) ||
          (Boolean(hidingOffer && hidingOffer.offer.id === offer.id) && hideOfferState === EHideOfferState.ANIMATING)
        }
        api={{
          onAgentRatingChanged: offerCardProps.userInfo.onAgentRatingChanged,
          onPageJumped,
          onHideOffer: offerCardProps.buttons.onHideOffer,
          onHideOfferAfterAuth: offerCardProps.buttons.onHideOfferAfterAuth,
          isPopupsOpened: props.isPopupsOpened,
          onFavoritesChange: offerCardProps.offerCard.onFavoriteChange,
          sendComplaintFeedback: offerCardProps.complaintsHelpers.sendComplaintFeedback,
          onComplaintSent: offerCardProps.reportPopup.onComplaintSent,
          addToComparison: offerCardProps.offerComparison.addToComparison,
          addToComparisonAfterAuth: offerCardProps.offerComparison.addToComparisonAfterAuth,
          deleteFromComparison: offerCardProps.offerComparison.deleteFromComparison,
          openChat,
          openNotAvailablePopup,
        }}
        isBot={offerCardProps.pessimisation.isBot}
        tracking={offerCardProps.tracking}
        featureToggle={offerCardProps.featureToggle}
        withAuction={withAuction}
        isDealRentDisablePhonesExperimentActive={Boolean(
          offer.offerInDeal && isDealRentDisablePhonesExperimentActive(props.abUseExperiments),
        )}
        isRosreestrCheckedLabelAvailable={isRosreestrCheckedLabelAvailable(offer)}
        isNewbuildingBookedFromDeveloper={isNewbuildingBookedFromDeveloper(offer)}
        villagePromotionLabel={villagePromotionLabel}
        isSoldFurnishedLabelAvailable={offer.isSoldFurnished}
        comparison={props.comparison}
      />
    );
  };
