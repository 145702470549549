import { NB_SUBURBAN_TRAP } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

export type TNewbuildingSuburbanTrapExperimentGroup =
  /** Не показываем баннер **/
  | 'A'
  /** Показываем баннер с ссылкой на выдачу **/
  | 'B'
  /** Показываем баннер с кпн **/
  | 'C';

export function getNewbuildingSuburbanTrapExperimentGroup(
  experiments: IAbUseExperiment[],
): TNewbuildingSuburbanTrapExperimentGroup {
  const experiment = experiments.find(e => e.experimentName === NB_SUBURBAN_TRAP);

  if (!experiment || !experiment.groupName) {
    return 'A';
  }

  return experiment.groupName as TNewbuildingSuburbanTrapExperimentGroup;
}
