import * as React from 'react';
import { useSelector } from 'react-redux';

import { useCatalogs } from './hooks';
import { selectIsEmptySearchBannerExperimentEnabled } from '../../../selectors/experiments';
import { getOffers } from '../../../selectors/offers';
import { TSuburbanCatalog } from '../../../types/suburbanCatalog';
import { SuburbanCatalogs } from '../../components/SuburbanCatalogs';

interface ISuburbanCatalogsContainerProps {
  catalogsTypes: TSuburbanCatalog[];
  fullWidth?: boolean;
}

export const SuburbanCatalogsContainer: React.VFC<ISuburbanCatalogsContainerProps> = ({ catalogsTypes, fullWidth }) => {
  const catalogs = useCatalogs(catalogsTypes);
  const isEmptySearchRedesignExperimentEnabled = useSelector(selectIsEmptySearchBannerExperimentEnabled);
  const offers = useSelector(getOffers);

  if (!catalogs.length || (isEmptySearchRedesignExperimentEnabled && !offers.length)) {
    return null;
  }

  return <SuburbanCatalogs catalogs={catalogs} fullWidth={fullWidth} />;
};
