import { type ILogger } from '@cian/logger';
import { equals } from 'ramda';
import * as React from 'react';

import { getOfferCardCreator, integrateAd, IOfferCardCreatorProps } from './helpers';
import { IFeatureToggle } from './types';
import {
  IButtonsAPI,
  ICommentsAPI,
} from '../../../../packages/api-models/search-card-fragment/v1/get-offer-card.props';
import { TAgentRating } from '../../../api/agent';
import { EmptyKpSearchContainer } from '../../../common/components/PreInfiniteBanner/EmptyKpSearch/container';
import { ITrackingData } from '../../../common/state';
import { EmptyListingBannerContainer } from '../../../containers/EmptyListingBannerContainer';
import { IJsonQuery } from '../../../json_query';
import { IBreadcrumb, IOffer, ISerpDataQueristringToUri, TOfferCategory } from '../../../offer/types';
import { IGetComparisonDesktopResponse } from '../../../repositories/offers-comparison/v2/get-comparison-desktop';
import { EMaxAuctionService } from '../../../repositories/search-offers.legacy/v2/search-offers-desktop.data';
import { IAbUseExperiment } from '../../../types/abUseExperiments';
import { ISuburbanProjectSuggest } from '../../../types/suburbanBuildersProjects';
import { TUser } from '../../../types/user';
import { SubsidisedMortgageZeroResultsBannerContainer } from '../../containers/SubsidisedMortgageZeroResultsBannerContainer';
import { IHideOffer, IHidingOfferInfo } from '../../state/hide_offer';
import { EFeedbackComplaint, IComplaintFeedbackBody } from '../../state/offer_card/feedback_complaint';
import { AuctionStatusNotification } from '../AuctionStatusNotification';

const style = require('./index.css');

type TCommentsStateProps =
  | 'commentingBlockedOffers'
  | 'commentingBlockedAgents'
  | 'commentingErroneousOffers'
  | 'commentingErroneousAgents';

export interface IOffersStateProps extends Pick<ICommentsAPI, TCommentsStateProps> {
  queryString: string;
  offersQty: number;
  currentPageNumber: number;
  currentPath: string | undefined;
  isBot: boolean;
  isPopupsOpened(): boolean;
  isPrintEnabled: boolean;
  jsonQuery: IJsonQuery;
  logger: ILogger;
  offers: IOffer[];
  suggestions: IOffer[] | null;
  offersPerPage: number;
  qsToUris: ISerpDataQueristringToUri | null;
  ratingBlockedAgents: number[];
  shownId: number | undefined;
  suggestionDistancesSeoText: string | undefined;
  user: TUser;
  favoritesLimitForUnauthUser: number;
  hideOffer: IHideOffer;
  isNewAdFox?: boolean;
  breadcrumbs: IBreadcrumb[];
  mlRankingGuid: string | null;
  mlRankingModelVersion: string | null;
  showEmptyKpSearch?: boolean;

  complaintsFormStatus: EFeedbackComplaint;

  /** Максимальная ставка аукциона на выдаче */
  maxAuctionBet: number;
  /** Максимальная услуга выдачи */
  maxAuctionService?: EMaxAuctionService;
  villagesLink?: string;
  showCountrysideTrap?: boolean;
  aggregatedOffersCount: number;
  extendedOffersCount: number;
  excludedUtilitiesTermsRegions: number[];
  featureToggle: IFeatureToggle;
  trackingData: ITrackingData;
  auctionBanks?: number[];
  abUseExperiments?: IAbUseExperiment[];
  isDealRentDisablePhonesExperimentActive?: boolean;
  villagePromotionLabel?: boolean | null;
  isCountrysideBuildersBannerAvailable?: boolean;
  isSubsidisedMortgageZeroResultsBannerAvailable?: boolean;
  /** Позиции баннеров сервисов Циан в выдаче */
  servicesBannerPositions: number[];
  showSuburbanListingBanner?: boolean;
  suburbanBuildersProjects: ISuburbanProjectSuggest[];
  comparison: IGetComparisonDesktopResponse | null;
}

export interface IOffersDispatchProps extends IButtonsAPI {
  closePopup(): void;
  onAgentRatingChanged(offer: IOffer, rating: TAgentRating): void;
  onComplaintSent(offerId: number, name: string, message?: string): void;
  onFavoriteChanged(offer: IOffer, isFavorite: boolean): void;
  onOfferCommentsChanged(offer: IOffer, commentOffer: string | undefined, commentAgent: string | undefined): void;
  onUserInfoPopupOpen(offerId: number): void;
  onUserInfoPopupClose(offerId: number): void;
  hideOfferAction(parameters: IHidingOfferInfo): void;
  hideOfferAfterAuthAction(parameters: IHidingOfferInfo): void;
  sendComplaintFeedback(body: IComplaintFeedbackBody): void;
  statusChanger(status: EFeedbackComplaint): void;
  addToComparison({ offerId, category }: { offerId: number; category: TOfferCategory | null }): void;
  addToComparisonAfterAuth({ offerId, category }: { offerId: number; category: TOfferCategory | null }): void;
  deleteFromComparison({ offerId }: { offerId: number }): void;
}

export interface IOwnProps {
  openChat(offer: IOffer, position: number): void;
  openNotAvailablePopup(offer: IOffer, position: number): void;
}

export type TOffersProps = IOffersStateProps & IOffersDispatchProps & IOwnProps;

function getOffersWithoutFavoritesFlag(offers: IOffer[]): IOffer[] {
  return offers.map(offer => ({ ...offer, isFavorite: null }));
}

export class Offers extends React.Component<TOffersProps, {}> {
  // Не обновляем выдачу, если не менялись фильтры или офферы
  // Дикое, забагованное говно © Shamil
  public shouldComponentUpdate(nextProps: TOffersProps) {
    const currentOffersWithoutFavoritesFlag = getOffersWithoutFavoritesFlag(this.props.offers);
    const nextOffersWithourFavoritesFlag = getOffersWithoutFavoritesFlag(nextProps.offers);

    return (
      !equals(this.props.jsonQuery, nextProps.jsonQuery) ||
      !equals(currentOffersWithoutFavoritesFlag, nextOffersWithourFavoritesFlag) ||
      !equals(this.props.commentingBlockedAgents, nextProps.commentingBlockedAgents) ||
      !equals(this.props.commentingBlockedOffers, nextProps.commentingBlockedOffers) ||
      !equals(this.props.commentingErroneousAgents, nextProps.commentingErroneousAgents) ||
      !equals(this.props.commentingErroneousOffers, nextProps.commentingErroneousOffers) ||
      !equals(this.props.hideOffer, nextProps.hideOffer) ||
      this.props.shownId !== nextProps.shownId ||
      this.props.complaintsFormStatus !== nextProps.complaintsFormStatus ||
      this.props.isPopupsOpened() !== nextProps.isPopupsOpened() ||
      this.props.openChat !== nextProps.openChat ||
      this.props.openNotAvailablePopup !== nextProps.openNotAvailablePopup
    );
  }

  public render() {
    const {
      offers,
      suggestions,
      offersPerPage,
      currentPageNumber,
      isPrintEnabled,
      jsonQuery,
      offersQty,
      queryString,
      user,
      hideOffer: { hiddenOffers, hiddenSuggestions },
      mlRankingGuid,
      mlRankingModelVersion,
      showEmptyKpSearch,
      villagesLink,
      showCountrysideTrap,
      aggregatedOffersCount,
      extendedOffersCount,
      isCountrysideBuildersBannerAvailable,
      isSubsidisedMortgageZeroResultsBannerAvailable,
      servicesBannerPositions,
      showSuburbanListingBanner,
      suburbanBuildersProjects,
    } = this.props;

    const offerCardCreatorProps: IOfferCardCreatorProps = {
      ...this.props,
      offersQty,
      queryString,
    };

    const cardsWithAd = integrateAd({
      suggestions,
      currentPageNumber,
      perPage: offersPerPage,
      offers,
      jsonQuery,
      offerCardCreator: getOfferCardCreator({ props: offerCardCreatorProps }),
      isPrintEnabled,
      locationId: jsonQuery.region ? jsonQuery.region.value[0] : undefined,
      suggestionFragment: { isSimilar: false },
      hiddenOffers,
      hiddenSuggestions,
      mlRankingGuid,
      mlRankingModelVersion,
      isAgent: user.isAuthenticated && user.isAgent,
      villagesLink,
      showCountrysideTrap,
      extendedOffersOffset: aggregatedOffersCount - extendedOffersCount,
      keyPrefix: 'regular',
      isCountrysideBuildersBannerAvailable,
      servicesBannerPositions,
      showSuburbanListingBanner,
      suburbanBuildersProjects,
    });

    const emptyListingBanner = isSubsidisedMortgageZeroResultsBannerAvailable ? (
      <SubsidisedMortgageZeroResultsBannerContainer />
    ) : showEmptyKpSearch ? (
      <EmptyKpSearchContainer />
    ) : (
      <EmptyListingBannerContainer />
    );

    return (
      <div className={style['wrapper']}>
        {!offers.length && emptyListingBanner}
        <AuctionStatusNotification />
        {cardsWithAd}
      </div>
    );
  }
}
