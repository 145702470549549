import { useUpdateEffect } from '@cian/react-utils';
import { useSlideContext } from '@cian/slider';
import { useState } from 'react';

export const DELAY = 600;

/**
 * Хук для отложенного изменения статуса слайда.
 * Нужно, чтобы при перелистывания слайдов не сразу показывать видео.
 */
export const useDeferredSlideStatus = () => {
  const { isActive } = useSlideContext();

  const [deferredIsActive, setDeferredIsActive] = useState(isActive);

  useUpdateEffect(() => {
    setTimeout(() => {
      setDeferredIsActive(isActive);
    }, DELAY);
  }, [isActive]);

  return deferredIsActive;
};
