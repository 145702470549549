import * as React from 'react';

import { SkeletonHeader } from './SkeletonHeader';
import { SkeletonRow } from './SkeletonRow';
import { SkeletonTableHead } from './SkeletonTableHead';
import * as style from './style.css';

export const Skeleton = () => {
  return (
    <div className={style['container']}>
      <SkeletonHeader />
      <div className={style['table']}>
        <SkeletonTableHead />
        <SkeletonRow />
        <SkeletonRow />
      </div>
    </div>
  );
};
