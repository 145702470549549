import {
  IconMapCar16,
  IconMapHighway16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroMsk16,
  IconMapMetroNN16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroSpb16,
  IconMapWalk16,
  IconMapTrain16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import { ColoredIconWrapper } from '../../common/components/ColoredIconWrapper';
import { ETransportType } from '../../repositories/search-offers.legacy/v2/types/NewbuildingSimilarBlockOfferForDesktopSchema';
import { INewbuildingSimilarOffer, INewbuildingSimilarOfferTransformed } from '../../types/newbuildingSimilarOffer';

const ICON_GRAY_HEX_COLOR = '#737a8e';

export const getUndergroundIcon = (undergroundType?: string | null): React.ReactNode => {
  switch (undergroundType) {
    case 'spb':
      return <IconMapMetroSpb16 />;
    case 'smr':
      return <IconMapMetroSam16 />;
    case 'ekb':
      return <IconMapMetroEkb16 />;
    case 'kzn':
      return <IconMapMetroKzn16 />;
    case 'nn':
      return <IconMapMetroNN16 />;
    case 'nsk':
      return <IconMapMetroNovosib16 />;
    default:
      return <IconMapMetroMsk16 />;
  }
};

export const mapNewbuildingSimilarOffers = (
  offers: INewbuildingSimilarOffer[],
): INewbuildingSimilarOfferTransformed[] => {
  return offers.reduce((acc, current) => {
    const {
      underground,
      highway,
      railway,
      floor,
      offerUrl,
      imageUrl,
      price,
      address,
      newbuildingName,
      totalArea,
      rootCount,
      analytics,
    } = current;
    const description = [`${rootCount} ･ `, `${totalArea} ･ `, floor];
    let locationText = null;
    let locationIconColor = null;
    let locationIcon = null;
    let timeText = null;
    let timeIcon = null;

    if (underground) {
      const isWalkingTime = underground.transportType === ETransportType.Walk;
      locationText = underground.name;
      locationIconColor = `#${underground.lineColor}`;
      locationIcon = (
        <ColoredIconWrapper color={locationIconColor}>
          {getUndergroundIcon(underground.undergroundType)}
        </ColoredIconWrapper>
      );
      timeIcon = isWalkingTime ? (
        <IconMapWalk16 color={'icon-secondary-default'} />
      ) : (
        <IconMapCar16 color={'icon-secondary-default'} />
      );
      timeText = `${underground.time} мин.`;
    } else if (highway) {
      locationText = highway.name;
      locationIconColor = ICON_GRAY_HEX_COLOR;
      locationIcon = <IconMapHighway16 />;
      timeText = highway.distance;
    } else if (railway) {
      locationText = railway.name;
      locationIconColor = ICON_GRAY_HEX_COLOR;
      locationIcon = <IconMapTrain16 />;
      timeText = railway.distance;
    }

    acc.push({
      title: newbuildingName,
      imageUrl,
      price,
      description,
      address,
      locationIcon,
      locationIconColor,
      locationText,
      timeIcon,
      timeText,
      url: offerUrl,
      product: analytics,
    });

    return acc;
  }, [] as INewbuildingSimilarOfferTransformed[]);
};
