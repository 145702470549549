import { ca } from '@cian/analytics';

import { TVideoType } from '../../types';

interface IParams {
  videoType: TVideoType;
  offerId: number;
  position: number;
}

export const trackVideoPlaying = ({ videoType, offerId, position }: IParams) => {
  ca('eventSite', {
    category: 'play_video',
    action: 'show',
    page: {
      extra: {
        video_source: videoType,
      },
    },
    products: [{ id: offerId, position, offerType: 'offer' }],
  });
};
