import { ca } from '@cian/analytics';

export const trackDeveloperCardLinkClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'click_sopr',
    label: 'to_card_builder',
    useLastProducts: true,
  });
};
