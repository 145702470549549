import { NB_FLAT_SALE_TRAP } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

export type TNewbuildingFlatSaleTrapExperimentGroup =
  /** Не показываем баннер **/
  | 'A'
  /** Показываем баннер с ссылкой на выдачу **/
  | 'B'
  /** Показываем баннер с кпн **/
  | 'C';

export function getNewbuildingFlatSaleTrapExperimentGroup(
  experiments: IAbUseExperiment[],
): TNewbuildingFlatSaleTrapExperimentGroup {
  const experiment = experiments.find(e => e.experimentName === NB_FLAT_SALE_TRAP);

  if (!experiment || !experiment.groupName) {
    return 'A';
  }

  return experiment.groupName as TNewbuildingFlatSaleTrapExperimentGroup;
}
