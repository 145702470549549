import { UIText2 } from '@cian/ui-kit';
import classNames from 'classnames';
import * as React from 'react';

import * as styles from './styles.css';

export const NewbuildingHousesFilter = ({ children, withLabel }: React.PropsWithChildren<{ withLabel?: boolean }>) => {
  return (
    <div className={classNames(withLabel ? styles['container-vertical'] : styles['container'])}>
      {withLabel && (
        <div className={classNames(styles['title'])}>
          <UIText2>Корпус</UIText2>
        </div>
      )}
      {children}
    </div>
  );
};
