import { sort } from 'ramda';

import { EMediaType, IVideoMedia, TMedia, TPhotoMedia } from '../../../../../shared/components/Carousel/types';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { IOfferPhoto } from '../../types/offer';

export const media: TComputedPropMapper<TMedia[]> = ({ offer }) => {
  const media: TMedia[] = [];

  if (offer.videos?.length) {
    const videos = offer.videos
      .map(({ url, previewUrl, id, type }) => {
        if (!previewUrl || type !== 'kinescope') {
          return null;
        }

        return {
          src: url,
          previewUrl,
          videoType: type,
          type: EMediaType.Video,
          id,
        };
      })
      .filter(Boolean) as IVideoMedia[];

    media.push(...videos);
  }

  if (offer.photos?.length) {
    const photos: TPhotoMedia[] = sort<IOfferPhoto>(
      (a, b) => Number(b.isDefault) - Number(a.isDefault),
      offer.photos || [],
    ).map(photo => ({
      type: photo.isLayout ? EMediaType.Layout : EMediaType.Photo,
      src: photo.thumbnail2Url,
    }));

    media.push(...photos);
  }

  return media;
};
