import { FOfferType } from '../../../packages/JsonQuery';
import { FDealType } from '../../utils/category';

export const getCategoryForPageExtra = (offerType: FOfferType, dealType: FDealType): string | null => {
  if (dealType === FDealType.Sale) {
    switch (offerType) {
      case FOfferType.Flat:
      case FOfferType.FlatOld:
      case FOfferType.FlatNew:
        return 'flatSale';
      case FOfferType.House:
        return 'houseSale';
      case FOfferType.HousePart:
        return 'houseShareSale';
      case FOfferType.Land:
        return 'landSale';
      case FOfferType.Townhouse:
        return 'townhouseSale';
      default:
        return null;
    }
  }

  if (dealType === FDealType.Rent) {
    switch (offerType) {
      case FOfferType.Flat:
        return 'flatRent';
      case FOfferType.Room:
        return 'roomRent';
      case FOfferType.Bed:
        return 'bedRent';
      default:
        return null;
    }
  }

  return null;
};
