import { ITypedReduxAction } from '../../types/redux';
import { INewbuildingLayoutWithOffers } from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';

export enum ENewbuildingLayoutOffersActionTypes {
  StartRequest = 'NewbuildingLayoutOffers/StartRequest',
  Error = 'NewbuildingLayoutOffers/Error',
  Finished = 'NewbuildingLayoutOffers/Finished',
  SetFavorite = 'NewbuildingLayoutOffers/SetFavorite',
}

export type TNewbuildingLayoutOffersFinishedPayload = INewbuildingLayoutWithOffers | null;

export type TFinishedNewbuildingLayoutOffers = ITypedReduxAction<
  ENewbuildingLayoutOffersActionTypes.Finished,
  TNewbuildingLayoutOffersFinishedPayload
>;
export type TStartRequestNewbuildingLayoutOffers = ITypedReduxAction<ENewbuildingLayoutOffersActionTypes.StartRequest>;

export type TErrorNewbuildingLayoutOffers = ITypedReduxAction<ENewbuildingLayoutOffersActionTypes.Error>;

export type TNewbuildingLayoutOffersSetFavoritePayload = {
  offerId: number;
  isFavorite: boolean;
};

export type TSetFavoriteNewbuildingLayoutOffers = ITypedReduxAction<
  ENewbuildingLayoutOffersActionTypes.SetFavorite,
  TNewbuildingLayoutOffersSetFavoritePayload
>;

export type TNewbuildingLayoutOffersFinishedActions =
  | TFinishedNewbuildingLayoutOffers
  | TStartRequestNewbuildingLayoutOffers
  | TErrorNewbuildingLayoutOffers
  | TSetFavoriteNewbuildingLayoutOffers;
