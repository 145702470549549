import { ArticleHeading4, ArticleParagraph2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './NewbuildingBlackFridayTopBanner.css';
import { NBSP } from '../../constants/symbols';

interface INewbuildingBlackFridayTopBannerProps {
  image: string;
}

export const NewbuildingBlackFridayTopBanner: React.FC<INewbuildingBlackFridayTopBannerProps> = ({ image }) => {
  return (
    <div className={styles['banner']}>
      <div className={styles['image']} style={{ backgroundImage: `url(${image})` }} />
      <div className={styles['text']}>
        <ArticleHeading4 color="text-primary-default">Скидки на новостройки только до 29 сентября</ArticleHeading4>
        <ArticleParagraph2 color="text-primary-default">
          Мы собрали выгодные предложения от застройщиков. Выберите квартиру и{NBSP}позвоните по{NBSP}номеру в{NBSP}
          объявлении
        </ArticleParagraph2>
      </div>
    </div>
  );
};
