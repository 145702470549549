import { ca } from '@cian/analytics';

import { IOffer } from '../../../../types/offer';

export function trackWriteClick(offer: IOffer, position: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Listing',
    action: 'send_message',
    label: offer.gaLabel,
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra: {
        /* istanbul ignore next */
        opponent_id: offer.user?.cianUserId,
        type: 'Open_listing_agent_block',
      },
    },
  });
}
