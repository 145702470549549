import { Chips } from '@cian/ui-kit';
import { TSelectType } from '@cian/ui-kit/chips/types/chips/selectType';
import * as React from 'react';

import { NonEmptyArray } from '../../../../JsonQuery';

type TOptionValue = string | number | boolean | null;

type TFilterType = Extract<TSelectType, 'multiple' | 'oneOf'>;

type TValue<T extends TFilterType> = T extends 'oneOf' ? TOptionValue : NonEmptyArray<TOptionValue> | null;

interface IOption {
  value: TOptionValue;
  label: string;
}

interface IFilterChipsProps<T extends TFilterType> {
  selectType: T;
  value: TValue<T>;
  options: IOption[];
  onChange(value: TValue<T>): void;
}

/**
 * Обертка над китовым Chips, для поддержки null и boolean в качестве значения
 *
 * @example // Пример использования
 * <FilterChips
 *   selectType="multiple"
 *   onChange={handleChange}
 *   options={filterOptions}
 *   value={selectedValues}
 * />
 */

export const FilterChips: React.FC<IFilterChipsProps<TFilterType>> = props => {
  const { selectType, onChange, options: optionsProp, value: valueProp } = props;
  const isNullValue = valueProp === null;

  const { options, optionsMap } = React.useMemo(() => {
    const options: IOption[] = [];
    const optionsMap: Record<string, TOptionValue | null> = {};

    optionsProp.forEach(item => {
      const value = String(item.value);

      options.push({
        ...item,
        value,
      });

      optionsMap[value] = item.value;
    });

    return {
      options,
      optionsMap,
    };
  }, [optionsProp]);

  // istanbul ignore next
  const value: (string | number | boolean | null)[] | undefined = React.useMemo(() => {
    if (selectType === 'oneOf') {
      return [String(valueProp)];
    } else {
      if (isNullValue) {
        return [String(null)];
      } else {
        return Array.isArray(valueProp) ? valueProp.map(String) : [String(valueProp)];
      }
    }
  }, [isNullValue, selectType, valueProp]);

  const handleOneOfChipClick = React.useCallback(
    (value: string) => onChange(optionsMap[value]),
    [onChange, optionsMap],
  );

  const handleMultipleChipClick = React.useCallback(
    (value: string) => {
      function getNextValue() {
        let newValue;
        // istanbul ignore else
        if (isNullValue) {
          newValue = [optionsMap[value]];
        } else {
          if ((valueProp as NonEmptyArray<TOptionValue>).includes(optionsMap[value])) {
            newValue = (valueProp as NonEmptyArray<TOptionValue>).filter(v => v !== optionsMap[value]);
          } else {
            newValue = [...(valueProp as NonEmptyArray<TOptionValue>), optionsMap[value]];
          }
        }

        // istanbul ignore next
        return newValue.indexOf(null) !== -1 ? null : (newValue as NonEmptyArray<TOptionValue>);
      }

      onChange(getNextValue());
    },
    [isNullValue, onChange, optionsMap, valueProp],
  );

  const handleClickChips = React.useCallback(
    (value: string) => {
      if (selectType === 'oneOf') {
        handleOneOfChipClick(value);
      } else {
        handleMultipleChipClick(value);
      }
    },
    [handleMultipleChipClick, handleOneOfChipClick, selectType],
  );

  return <Chips selectType={selectType} value={value} options={options} size="S" onChipClick={handleClickChips} />;
};
