import { Button, UIHeading1, UIHeading2 } from '@cian/ui-kit';
import cx from 'classnames';
import * as React from 'react';

import styles from './NewbuildingSpecialProject.css';
import { Marquee } from '../../../../../../../packages/Marquee';
import { INewbuildingSpecialProjectBannerContext } from '../../../../../../types/adfox';

interface INewbuildingSpecialProjectProps {
  context: INewbuildingSpecialProjectBannerContext;
}

export function NewbuildingSpecialProject({ context }: INewbuildingSpecialProjectProps) {
  const { bgImage, title, text, url, buttonText, substrateNotNeeded, substrateLogoNotNeeded, logo, label, disclaimer } =
    context;

  return (
    <div className={styles['wrapper']} data-testid="NewbuildingSpecialProject">
      <a
        href={url}
        target="_blank"
        rel="nofollow noopener noreferrer"
        className={cx(
          styles['container'],
          !Number(substrateNotNeeded) && styles['container--without-substrate'],
          !Number(substrateLogoNotNeeded) && styles['container--without-substratelogo'],
        )}
        style={{ backgroundImage: `url(${bgImage})` }}
      >
        <div className={styles['wrapper']}>
          <div className={styles['logo']}>
            <img className={styles['logo-image']} src={logo} alt={title} />
          </div>
          <div className={styles['content']}>
            <div className={styles['info']}>
              <div className={styles['label']}>{label}</div>
              <div className={styles['title']}>
                <UIHeading1 color="text-inverted-default">{title}</UIHeading1>
              </div>
              <div className={styles['text']}>
                <UIHeading2 color="text-inverted-default">{text}</UIHeading2>
              </div>
            </div>
            {buttonText && (
              <div className={styles['control']}>
                <Button type="button" theme="fill_white_primary">
                  {buttonText}
                </Button>
              </div>
            )}
          </div>
        </div>
        {disclaimer && (
          <div className={styles['note']}>
            <Marquee text={disclaimer} />
          </div>
        )}
      </a>
    </div>
  );
}
