import { useMemo } from 'react';

import { TPromoSearchTypes } from '../../../../../../../shared/types/promoSearchTypes';
import { JsonQuery, NonEmptyArray, nonEmptyArrayOr, TJsonQuery } from '../../../../../../JsonQuery';
import { getPromoSearchFilterValuesByType } from '../../../../utils/getPromoSearchFilterValuesByType';
import { SUBSIDIZED_MORTGAGE_OPTION_VALUE } from '../constants';

interface IParams {
  allPromoSearchTypeValues: NonEmptyArray<string> | null;
  promoSearchTypes: TPromoSearchTypes;
  jsonQuery: TJsonQuery;
}

export const useMortgagePromoSearchTypeValues = ({
  allPromoSearchTypeValues,
  promoSearchTypes,
  jsonQuery,
}: IParams): NonEmptyArray<string> | null => {
  const isSubsidisedMortgage = new JsonQuery(jsonQuery).getIsSubsidisedMortgage();

  return useMemo(() => {
    const result: string[] = [];

    if (isSubsidisedMortgage) {
      result.push(SUBSIDIZED_MORTGAGE_OPTION_VALUE);
    }

    if (allPromoSearchTypeValues && promoSearchTypes) {
      const availableFilterValues = getPromoSearchFilterValuesByType('mortgage', promoSearchTypes);
      const filteredValues = allPromoSearchTypeValues.filter(value => availableFilterValues.includes(value));

      result.push(...filteredValues);
    }

    return nonEmptyArrayOr(result);
  }, [allPromoSearchTypeValues, promoSearchTypes, isSubsidisedMortgage]);
};
