import { Image } from '@cian/ui-kit/image';
import React from 'react';

import styles from './Thumbnail.css';
import { TMedia } from '../../../../../shared/components/Carousel';

interface IThumbnailProps {
  media: TMedia | null;
}

export const Thumbnail: React.FC<React.PropsWithChildren<IThumbnailProps>> = ({ media, children }) => {
  return (
    <div className={styles['thumbnail']}>
      <Image src={media ? media.src : ''} objectFit="cover" />
      {children}
    </div>
  );
};
