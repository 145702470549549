import * as React from 'react';

import { isDailyRentOtaConversionExperimentEnabled } from '../../../../../../shared/utils/experiments/dailyrentOtaConversion';
import { getTermValue } from '../../../../../JsonQuery';
import { OnlineBooking } from '../../../components/advancedFilters/OnlineBooking';
import { useContext } from '../../../utils/useContext';

export const OnlineBookingContainer: React.FC = () => {
  const { jsonQuery, onChange, appContext } = useContext();
  const onlineBooking = getTermValue('online_booking')(jsonQuery);
  const isFilterEnabled = Boolean(appContext.config.get<boolean>('dailyrent.onlineBookingFilterDesktop.Enabled'));
  const isOtaConversionExperiment = isDailyRentOtaConversionExperimentEnabled(appContext);

  const handleChange = React.useCallback(
    (value: boolean | null) => {
      onChange({ action: 'setOnlineBooking', arguments: [value] });
    },
    [onChange],
  );

  if (!isFilterEnabled || isOtaConversionExperiment) {
    return null;
  }

  return <OnlineBooking onlineBooking={onlineBooking} onChange={handleChange} />;
};
