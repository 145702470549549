import { Header4, Paragraph2, TooltipDesktop } from '@cian/ui-kit';
import { IconProductTrust16 } from '@cian/ui-kit-design-tokens/icons';
import React from 'react';

import styles from './SolidJK.css';
import { ContentRow } from '../ContentRow';

interface ISolidJKProps {
  href?: string;
}

export function SolidJK({ href }: ISolidJKProps) {
  return (
    <TooltipDesktop
      theme="white"
      title={
        <div className={styles['cont']}>
          <ContentRow size={'M'}>
            <Header4>Проверено Циан</Header4>
          </ContentRow>
          <ContentRow size={'M'}>
            <Paragraph2>В ЖК есть корпуса, проверенные Циан.</Paragraph2>
          </ContentRow>
          {href && (
            <a href={`${href}#reliable`} target="_blank" rel="noreferrer">
              Подробнее
            </a>
          )}
        </div>
      }
      placement="bottom-start"
    >
      <div className={styles['icon']}>
        <IconProductTrust16 color="icon-main-default" />
      </div>
    </TooltipDesktop>
  );
}
