import { getDealType } from '../../../../selectors/getDealType';
import { EDealType } from '../../../../types/dealType';
import { IAppState } from '../../../state';

export function getSeoTabsLinksAvailable(state: IAppState): boolean {
  const { seoTabsLinks } = state;

  const dealType = getDealType(state);
  const isDailyRentQuery = dealType === EDealType.RentDaily;

  const isSeoTabsLinksPresent = Boolean(seoTabsLinks?.length);

  return isSeoTabsLinksPresent && isDailyRentQuery;
}
