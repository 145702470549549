import { Checkbox, Label } from '@cian/ui-kit';
import { IconProductHotLeads16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './BlackFriday.css';

interface IBlackFridayProps {
  text: string;
  value: boolean | null;
  label: string;
  onBlackFridayChange(value: boolean | null): void;
}

export const BlackFriday: React.FC<IBlackFridayProps> = ({ text, value, label, onBlackFridayChange }) => {
  return (
    <div className={styles['container']}>
      <Checkbox
        label={text}
        checked={!!value}
        onChange={event => onBlackFridayChange(event.target.checked ? true : null)}
      />
      <Label
        background="surface-main-default"
        color="accent-main-primary"
        icon={<IconProductHotLeads16 color="icon-main-default" />}
      >
        {label}
      </Label>
    </div>
  );
};
