import { Image, UIHeading4, UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { INewbuildingPromoBuilderOffer } from '../../types/newbuildingPromoBuilderOffers';
import { NewbuildingPromoUnderground } from '../NewbuildingPromoUnderground';

export interface INewbuildingCardProps extends INewbuildingPromoBuilderOffer {
  onNewbuilldingClick(): void;
  onOfferClick(): void;
}

export function NewbuildingPromoOfferCard({
  imageUrl,
  cardUrl,
  description,
  priceDisplay,
  newbuilding,
  underground,
  onNewbuilldingClick,
  onOfferClick,
}: INewbuildingCardProps) {
  return (
    <div className={styles['container']}>
      <a
        href={cardUrl}
        target="_blank"
        rel="noreferrer"
        data-testid="NewbuildingPromoOfferCardLink"
        className={styles['link']}
        onClick={onOfferClick}
      >
        <div className={styles['preview']}>
          <Image src={imageUrl} objectFit="contain" height={140} borderRadius={0} />
        </div>
        <div className={styles['content']}>
          <UIHeading4 as="h5" whiteSpace="nowrap">
            {priceDisplay}
          </UIHeading4>
          <div className={styles['price-group']}>
            {description && (
              <div className={styles['price']}>
                <UIText1>{description}</UIText1>
              </div>
            )}
          </div>
          {underground ? (
            <NewbuildingPromoUnderground
              lineColor={underground.lineColor}
              name={underground.name}
              transportType={underground.transportType}
              cityType={underground.cityType}
              timeDisplay={underground.timeDisplay}
            />
          ) : (
            newbuilding?.address && (
              <div data-testid="NewbuildingPromoOfferCardAddress" className={styles['address']}>
                <UIText2>{newbuilding.address}</UIText2>
              </div>
            )
          )}
        </div>
      </a>
      {newbuilding && (
        <a
          className={styles['link-newbuilding']}
          target="_blank"
          data-testid="NewbuildingPromoOfferCardJk"
          rel="noreferrer"
          href={newbuilding.url}
          onClick={onNewbuilldingClick}
        >
          <UIText2 color="text-main-default">ЖК {newbuilding.name}</UIText2>
        </a>
      )}
    </div>
  );
}
