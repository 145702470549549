import { Button, UIHeading5, InlineText3, Paragraph3, Tooltip } from '@cian/ui-kit';
import { LinkButton } from '@cian/ui-kit/button';
import React, { useState, useMemo, useCallback, MouseEvent, SyntheticEvent } from 'react';

import { useGetIsElectronicTrading } from '../../../../../../../shared/hooks/useGetIsElectronicTrading';
import { useApplicationContext } from '../../../../../../../shared/utils/applicationContext';
import { getDynamicCalltrackingPhone } from '../../../../api/dynamicCalltracking';
import { ContentRow } from '../../../../components/ContentRow';
import { useOfferContext } from '../../../../context';
import { IPhone } from '../../../../types/offer';

const EMPTY_PHONES = [{ countryCode: null, number: null }];

export function AgentPhoneButtonContainer() {
  const {
    offer,
    queryString,
    tracking,
    agentWorkTime,
    agentPhoneWarningMessage,
    isDealRentDisablePhonesExperimentActive,
    api: { openNotAvailablePopup },
    position,
  } = useOfferContext();
  const { logger, httpApi } = useApplicationContext();
  const isElectronicTrading = useGetIsElectronicTrading();

  const [opened, setOpened] = useState(false);
  // dynamic calltracking
  const [comagicLoaded, setComagicLoaded] = useState(false);
  const [comagicLoading, setComagicLoading] = useState(false);
  const [comagicPhone, setComagicPhone] = useState<IPhone | null>(null);

  const phones: IPhone[] = useMemo(() => {
    if (!comagicLoaded || !offer.phones) {
      return EMPTY_PHONES;
    }

    return comagicPhone ? [comagicPhone] : offer.phones;
  }, [comagicLoaded, comagicPhone, offer.phones]);

  const stopPropagation = (e: SyntheticEvent<HTMLElement>) => e.stopPropagation();

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLElement>) => {
      stopPropagation(e);
      tracking.trackPhoneOpened(true);
      const { newbuildingDynamicCalltracking, id } = offer;
      const siteBlockId = newbuildingDynamicCalltracking && newbuildingDynamicCalltracking.siteBlockId;
      setComagicLoading(true);
      if (siteBlockId) {
        try {
          const { phone } = await getDynamicCalltrackingPhone({
            httpApi,
            logger,
            blockId: siteBlockId,
            announcementId: id,
            placeType: 'offer',
            dynamicCalltrackingPlaceType: 'sidePanel',
            queryString,
          });

          if (phone) {
            setComagicPhone({
              countryCode: phone[0],
              number: phone.slice(1),
            });
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }

      if (agentWorkTime) {
        openNotAvailablePopup(offer, position);
      }

      setComagicLoading(false);
      setComagicLoaded(true);
      setOpened(true);
    },
    [offer, queryString, httpApi, logger, tracking, agentWorkTime, openNotAvailablePopup, position],
  );

  const trackSafeRentFillFormClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      stopPropagation(e);
      tracking.trackSafeRentFillFormClick(offer.id);
    },
    [tracking, offer],
  );

  const slicedPhone = useMemo(() => {
    return offer.phones && offer.phones[0] ? `${formatPhone(offer.phones[0]).slice(0, -2)}...` : '';
  }, [offer.phones]);

  if (offer.isCianPartner || isElectronicTrading) {
    return null;
  }

  if (isDealRentDisablePhonesExperimentActive) {
    return (
      <Tooltip placement="left" title="Назначить просмотр">
        <LinkButton
          href={`${offer.fullUrl}?opendealrentform=true`}
          fullWidth
          size="XS"
          onClick={trackSafeRentFillFormClick}
          theme="fill_primary"
        >
          Назначить просмотр
        </LinkButton>
      </Tooltip>
    );
  }

  if (!opened) {
    return (
      <Button
        data-mark="PhoneButton"
        fullWidth
        size="XS"
        onClick={handleClick}
        theme="fill_primary"
        loading={comagicLoading}
      >
        {slicedPhone}
      </Button>
    );
  }

  return (
    <>
      <ContentRow>
        {phones.map((phone, index) => (
          <ContentRow key={index}>
            <UIHeading5 data-mark="PhoneValue" onClick={stopPropagation}>
              {formatPhone(phone)}
            </UIHeading5>
            {index === phones.length - 1 && agentWorkTime && (
              <Paragraph3 color="gray60_100">{agentWorkTime}</Paragraph3>
            )}
          </ContentRow>
        ))}
      </ContentRow>
      {agentPhoneWarningMessage && (
        <ContentRow>
          <InlineText3 data-mark="new-agent-warning" color="gray60_100" whiteSpace="pre-line">
            {agentPhoneWarningMessage}
          </InlineText3>
        </ContentRow>
      )}
    </>
  );
}

export function formatPhone(phone: IPhone): string {
  if (!phone || !phone.number) {
    return '';
  }

  return (
    `${phone.countryCode && phone.countryCode.startsWith('+') ? '' : '+'}${phone.countryCode} ` +
    `${phone.number.substr(0, 3)} ` +
    `${phone.number.substr(3, 3)}-${phone.number.substr(6, 2)}-${phone.number.substr(8, 2)}`
  );
}
