import { ca } from '@cian/analytics';

export const trackNewbuildingLayoutOfferClick = () => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Layout',
    action: 'click_sopr',
    label: 'modal_open_card',
  });
};
