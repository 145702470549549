import { selectJsonQuery } from './results';
import { getIsSubsidisedMortgage } from '../../packages/JsonQuery/getIsSubsidisedMortgage';
import { IAppState } from '../common/state';

export const selectIsSubsidisedMortgageZeroResultsBannerAvailable = (state: IAppState) => {
  const jsonQuery = selectJsonQuery(state);

  const isSubsidisedMortgage = getIsSubsidisedMortgage(jsonQuery);
  const bannerInfo = state.config.get('subsidizedMortgage.desktopSerpZeroResultsBannerInfo');

  return Boolean(isSubsidisedMortgage && bannerInfo);
};
