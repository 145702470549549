import { ca } from '@cian/analytics';

import { getDeveloperContactsLabel } from '../../../serp/components/header/developers_contacts';

export function trackSendMessageClick(newbuildingId: number | undefined, pageExtraType: string) {
  ca('eventSite', {
    action: 'send_message',
    category: 'Listing',
    label: getDeveloperContactsLabel(null),
    name: 'oldevent',
    products: [{ id: newbuildingId, offerType: 'JK' }],
    page: {
      extra: {
        type: pageExtraType,
      },
    },
  });
}
