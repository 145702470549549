import { Video } from '@cian/slider';
import { clsx } from 'clsx';
import * as React from 'react';

import styles from './VideoSlide.css';
import { KinescopeVideoShort } from './components';
import { usePlayerStatus, useVideoShortParams } from './hooks';
import { EPlayerStatus } from './types';
import { IVideoMedia } from '../../types';

type IVideoSlideProps = Omit<IVideoMedia, 'type'> & {
  disableVideoPlay?: boolean;
};

export const VideoSlide: React.FC<IVideoSlideProps> = ({ id, src, previewUrl, videoType, disableVideoPlay }) => {
  const videoShortParams = useVideoShortParams();

  const { status, setVideoReady, setVideoPlaying, setVideoEnded, setPlayerInitError } = usePlayerStatus({
    videoType,
    disableVideoPlay,
  });

  return (
    <div className={styles['video-slide']}>
      <div className={clsx(styles['preview'], status === EPlayerStatus.VideoPlaying && styles['preview--blur'])}>
        <Video src={src} previewUrl={videoType === 'youtube' ? undefined : previewUrl} showPlayButton={false} />
      </div>

      {!!status && videoType === 'kinescope' && (
        <KinescopeVideoShort
          id={id}
          playVideo={status > EPlayerStatus.PlayerReady}
          onInitError={setPlayerInitError}
          onEnded={setVideoEnded}
          onReady={setVideoReady}
          onPlay={setVideoPlaying}
          params={videoShortParams}
        />
      )}
    </div>
  );
};
