/* eslint-disable react/no-danger */
import * as React from 'react';
import { renderToString } from 'react-dom/server';

interface IHtmlCommentProps {
  children: React.ReactElement | null;
  className?: string;
  dataName?: string;
}

export const NoIndex: React.FC<IHtmlCommentProps> = ({ children, className, dataName }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: children ? `<!--noindex-->${renderToString(children)}<!--/noindex-->` : '' }}
      className={className}
      data-name={dataName}
    />
  );
};
