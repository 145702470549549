import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { FavoritesControlContainer } from '../../../../containers/NewbuildingLayoutDetails/FavoritesControlContainer';
import { INewbuildingLayoutWithOffers } from '../../../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';
import { OffersRow } from '../OffersRow';

export const HEADER_ITEMS: string[] = ['Площадь', 'Срок сдачи', 'Этаж', 'Стоимость', ''];

interface IOffersTableProps {
  layout: INewbuildingLayoutWithOffers;
  onOfferLinkClick(): void;
  onOffersListingLinkClick(): void;
}

export const OffersTable = ({ layout, onOfferLinkClick, onOffersListingLinkClick }: IOffersTableProps) => {
  return (
    <div className={style['container']}>
      <div className={style['table-header']}>
        {HEADER_ITEMS.map((item, index) => (
          <div className={style['header-cell']} key={index}>
            <UIText2 color="current_color" textAlign={index === 3 ? 'right' : 'left'}>
              {item}
            </UIText2>
          </div>
        ))}
        <div className={style['divider']} />
      </div>
      <div className={style['table']}>
        {layout.offers.map(offer => (
          <OffersRow key={offer.offerId} offer={offer} onOfferLinkClick={onOfferLinkClick}>
            <FavoritesControlContainer offerId={offer.offerId} />
          </OffersRow>
        ))}
        <div className={style['offers-link-block']}>
          <a
            target="_blank"
            className={style['offers-link']}
            href={layout.offersLinkUrl}
            onClick={onOffersListingLinkClick}
            rel="noreferrer"
          >
            {layout.offersLinkText}
          </a>
        </div>
      </div>
    </div>
  );
};
