import { IOffer } from '../../offer';
import { INewbuilding } from '../../types/newbuildingHeader';
import { TThunkAction, TThunkDispatch } from '../../types/redux';
import { ITypedReduxAction } from '../../types/redux/actionType';
import { actionGenerator } from '../../utils/redux/actionGenerator';

export enum EChatActionTypes {
  Open = 'Chat/Open',
  OpenChatbot = 'Chat/OpenChatbot',
  OpenNewbuildingChat = 'Chat/OpenNewbuildingChat',
  Close = 'Chat/Close',
  OpenAfterAuth = 'Chat/OpenAfterAuth',
  OpenNewbuildingChatAfterAuth = 'Chat/OpenNewbuildingChatAfterAuth',
}

type TOpenChatPayload = {
  offer: IOffer;
  position: number;
};

type TOpenChatAfterAuthPayload = {
  offer: IOffer;
  position: number;
};

export type TOpenChat = ITypedReduxAction<EChatActionTypes.Open, TOpenChatPayload>;
export type TOpenChatAfterAuth = ITypedReduxAction<EChatActionTypes.OpenAfterAuth, TOpenChatAfterAuthPayload>;
export type TOpenChatbot = ITypedReduxAction<EChatActionTypes.OpenChatbot, void>;
export type TOpenNewbuildingChat = ITypedReduxAction<EChatActionTypes.OpenNewbuildingChat, INewbuilding>;
export type TOpenNewbuildingChatAfterAuth = ITypedReduxAction<
  EChatActionTypes.OpenNewbuildingChatAfterAuth,
  INewbuilding
>;
export type TCloseChat = ITypedReduxAction<EChatActionTypes.Close>;

/**
 * Action открытия чата по объявлению.
 * @param TOpenChatPayload
 */
export const openChat: (payload: TOpenChatPayload) => TOpenChat = actionGenerator<
  EChatActionTypes.Open,
  TOpenChatPayload
>(EChatActionTypes.Open);

/**
 * Action открытия чатбота
 * @param TOpenChatPayload
 */
export const openChatbot: () => TOpenChatbot = actionGenerator<EChatActionTypes.OpenChatbot, void>(
  EChatActionTypes.OpenChatbot,
);

/** Action открытия чата с застройщиком. */
export const openNewbuildingChat: (payload: INewbuilding) => TOpenNewbuildingChat = actionGenerator<
  EChatActionTypes.OpenNewbuildingChat,
  INewbuilding
>(EChatActionTypes.OpenNewbuildingChat);

/**
 * Callback авторизации при попытке открыть чаты
 * @param TOpenChatPayload
 */
export const onChatSuccessAuth = (payload: TOpenChatPayload) => (): TThunkAction => (dispatch: TThunkDispatch) =>
  dispatch(openChat(payload));

/**
 * Action открытия чата по объявлению после авторизации
 * @param TOpenChatAfterAuthPayload
 */
export const openChatAfterAuth: (payload: TOpenChatAfterAuthPayload) => TOpenChatAfterAuth = actionGenerator<
  EChatActionTypes.OpenAfterAuth,
  TOpenChatAfterAuthPayload
>(EChatActionTypes.OpenAfterAuth);

/** Action открытия чата с застройщиком после авторизации */
export const openNewbuildingChatAfterAuth: (payload: INewbuilding) => TOpenNewbuildingChatAfterAuth = actionGenerator<
  EChatActionTypes.OpenNewbuildingChatAfterAuth,
  INewbuilding
>(EChatActionTypes.OpenNewbuildingChatAfterAuth);

/**
 * Action закрытия чата
 */
export const closeChat: () => TCloseChat = actionGenerator<EChatActionTypes.Close>(EChatActionTypes.Close);

export type TChatActions =
  | TOpenChat
  | TOpenChatbot
  | TOpenNewbuildingChat
  | TCloseChat
  | TOpenChatAfterAuth
  | TOpenNewbuildingChatAfterAuth;
