export const NB_CHAT_COMMON_FILTER = 'newbuilding_chat_common_filter';
export const NB_CONSULTANT_TRIGGERING_MESSAGE = 'newbuilding-triggering-message';
export const RENTER_FORM_EXPERIMENT = 'renter_form_experiment';
export const AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT = 'aud_desktop_aud2776_cian_assistant_entry_point';
export const DAILY_RENT_OTA_CONVERSION = 'dailyrent_web_sut375_online_booking_cr';
export const NB_LAYOUT_LISTING = 'newbuilding_layout_listing_inside_jk_desktop';
export const NB_RENT_TRAP = 'newbuilding_rent_trap_desktop';
export const NB_SUBURBAN_TRAP = 'newbuilding_suburban_trap_desktop';
export const NB_FLAT_SALE_TRAP = 'newbuilding_flatsale_trap_desktop';
export const AUD_EMPTY_SEARCH_BANNER_EXPERIMENT = 'vx_web_aud_3494_informer_redesign';

export const EXPERIMENTS: string[] = [
  'newbuilding_consultant',
  NB_CHAT_COMMON_FILTER,
  NB_CONSULTANT_TRIGGERING_MESSAGE,
  RENTER_FORM_EXPERIMENT,
  AUD_DESKTOP_AUD2776_CIAN_ASSISTANT_ENTRY_POINT,
  DAILY_RENT_OTA_CONVERSION,
  NB_LAYOUT_LISTING,
  NB_RENT_TRAP,
  NB_SUBURBAN_TRAP,
  NB_FLAT_SALE_TRAP,
  AUD_EMPTY_SEARCH_BANNER_EXPERIMENT,
];
