import { Label, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { INewbuildingLayoutOffer } from '../../../../types/residentialComplexLayouts/layoutWithOffers/offer';

interface IOffersRowProps {
  offer: INewbuildingLayoutOffer;
  onOfferLinkClick(): void;
}

export const OffersRow = ({ offer, children, onOfferLinkClick }: React.PropsWithChildren<IOffersRowProps>) => {
  return (
    <div data-testid="NewbuildingLayoutsOffer" className={style['row']}>
      <a
        className={style['inner-row']}
        href={offer.singleOfferUrl}
        onClick={onOfferLinkClick}
        target="_blank"
        rel="noreferrer"
      >
        <div className={style['offer-title']}>
          <UIText1 color="primary_100">{offer.title}</UIText1>

          <div>
            {offer.hasDecoration && (
              <Label background="control-main-secondary-default" color="text-primary-default">
                С отделкой
              </Label>
            )}
          </div>
        </div>
        <div className={style['description-cell']}>
          <UIText1 color="current_color">{offer.buildingDeadlineShorten}</UIText1>
        </div>
        <div className={style['description-cell']}>
          <UIText1 color="current_color">{offer.floor}</UIText1>
        </div>
        <div className={style['description-cell']}>
          <UIText1 color="text-primary-default" textAlign="right" as="span">
            {offer.priceText}
          </UIText1>
        </div>
      </a>
      <div className={style['controls-cell']}>{children}</div>
    </div>
  );
};
