import * as R from 'ramda';
import { connect } from 'react-redux';

import { canShowTrap, getSuburbanCatalogsTypes, isBlackFridayBannerVisible } from './helpers';
import { getSeoTabsLinksAvailable } from './helpers/getSeoTabsLinksAvailable';
import { ISearchEngineResultsPageDispatchProps, ISearchEngineResultsPageProps, SearchEngineResultsPage } from './index';
import { setLoading } from '../../../filters/state/search';
import { selectOffersPerPage } from '../../../selectors/experiments/selectOffersPerPage';
import { residentialComplexLayoutsOnSelector } from '../../../selectors/newbuildingLayouts';
import { selectIsNewbuildingPromoBuilderOffersVisible } from '../../../selectors/newbuildingPromoBuilderOffers';
import { selectIsSpecialProjectAdfoxBannerVisible } from '../../../selectors/newbuildingSpecialProject';
import { selectIsProfessionalSearchPromoBannerVisible } from '../../../selectors/selectIsProfessionalSearchPromoBannerVisible';
import { selectIsSubsidisedMortgageZeroPlaceBannerVisible } from '../../../selectors/selectIsSubsidisedMortgageZeroPlaceBannerVisible';
import { EHideOfferState, setHideOfferState } from '../../../serp/state/hide_offer';
import { getTotalPagesCount } from '../../../utils/pagination';
import { Dispatch, IAppState } from '../../state';

/* istanbul ignore next */
export const mapStateToProps = (state: IAppState): ISearchEngineResultsPageProps => {
  const { config, results } = state;

  const { aggregatedOffers, offers, quickLinks, jsonQuery } = results;

  /**
   * Нужно для эксперимента с микроразметкой
   * ---------------------------------------
   */
  const currencyVariant = {
    1: 'USD',
    2: 'RUB',
    3: 'EUR',
  };

  const currency =
    jsonQuery.currency && jsonQuery.currency.value
      ? currencyVariant[jsonQuery.currency.value]
      : /**
         * По умолчанию RUB
         */
        currencyVariant[2];

  //Эксперимент баннер перед бесконечной выдачей CD-44294
  const page = (jsonQuery.page && jsonQuery.page.value) || 1;
  const perPage = selectOffersPerPage(state);
  const totalPages = getTotalPagesCount(perPage, aggregatedOffers);
  const showPreInfinteSaveSearchBanner =
    Boolean(state.results.offers.length) && (totalPages === 1 || page === totalPages);
  const isOfferInSavedSearch = R.pathOr(undefined, ['results', 'jsonQuery', 'saved_search_id'], state);

  const isSpecialProjectAdfoxBannerVisible = selectIsSpecialProjectAdfoxBannerVisible(state);
  const isNewbuildingPromoBuilderOffersVisible = selectIsNewbuildingPromoBuilderOffersVisible(state);
  const isResidentialComplexLayouts = residentialComplexLayoutsOnSelector(state);

  /* istanbul ignore next */
  return {
    currency,
    isPromotionMobileClosed: state.isPromotionMobileClosed,
    deviceType: state.deviceType,
    lkUrl: config.get<string>('hiddenObjects.lkUrl') || '',
    isPrintEnabled: state.print.enabled,
    isFetching: state.isFetching,
    aggregatedOffers,
    quickLinks,
    subdomain: state.currentSubdomain,

    regions: state.filters.regions,
    currentLocation: state.filters.currentLocation,
    jsonQuery,
    /* istanbul ignore next */
    tags: state.filters.backup ? state.filters.backup.tags : state.filters.tags,
    isSeoTabsLinksAvailable: getSeoTabsLinksAvailable(state),

    user: state.user,
    isDirectionsModalVisible: state.filters.directionsModal.isVisible,
    isDistrictModalVisible: state.isDistrictModalVisible,
    isLocationSwitcherVisible: state.filters.locationSwitcher.isLocationSwitcherOpened,
    isRegionalDistrictsModalVisible: state.filters.regionalDistrictsModal.isOpened,
    isMapModalVisible: state.isMapModalVisible,
    isStalkerBannerShown: state.isStalkerBannerShown,
    isMetroModalVisible: state.isMetroModalVisible,
    isOfficeCalculatorVisible: state.isOfficeCalculatorVisible,
    isSignInTriggerModalOpen: state.isSignInTriggerModalOpen,
    isTopPopupOpened: state.hideOffer.isTopPopupOpened,
    showTrap: canShowTrap(offers),
    hideOfferState: state.hideOffer.hideOfferState,
    hideOfferErrorMessage: state.hideOffer.hideOfferErrorMessage,

    httpApi: state.httpApi,
    queryString: state.results.queryString,

    context: {
      logger: state.logger,
    },
    showPreInfinteSaveSearchBanner,

    currentUrl: state.filters.jsonQueryUrl,
    isOfferInSavedSearch,
    config,
    comparisonNotification: state.comparisonNotification,
    suburbanCatalogsTypes: getSuburbanCatalogsTypes(state),
    isSpecialProjectAdfoxBannerVisible,
    isNewbuildingPromoBuilderOffersVisible,
    isProfessionalSearchPromoBannerVisible: selectIsProfessionalSearchPromoBannerVisible(state),
    isSubsidisedMortgageZeroPlaceBannerVisible: selectIsSubsidisedMortgageZeroPlaceBannerVisible(state),
    isBlackFridayBannerVisible: isBlackFridayBannerVisible(jsonQuery),
    isResidentialComplexLayouts,
  };
};

export const mapDispatchToProps = (dispatch: Dispatch): ISearchEngineResultsPageDispatchProps => ({
  setHideOfferState: (hideOfferState: EHideOfferState) => dispatch(setHideOfferState(hideOfferState)),
  setLoading: () => dispatch(setLoading()),
});

export const SearchEngineResultsPageContainer = connect<
  ISearchEngineResultsPageProps,
  ISearchEngineResultsPageDispatchProps
>(
  mapStateToProps,
  mapDispatchToProps,
)(SearchEngineResultsPage);
