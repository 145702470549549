import { useToggleState } from '@cian/react-utils';
import { CustomTooltip, UIText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './DoublesRankingPopup.css';

interface IDoublesRankingPopupProps {
  popupText: string;
}

export const DoublesRankingPopup: React.FunctionComponent<IDoublesRankingPopupProps> = ({ popupText }) => {
  const linkRef = React.useRef(null);
  const { state: open, toggle: toggleOpen } = useToggleState();

  return (
    <>
      <div className={styles['container']}>
        <UIText3 color="text-main-default" display="inline" ref={linkRef} onClick={toggleOpen}>
          Как стать первым среди дублей?
        </UIText3>
      </div>
      <CustomTooltip open={open} content={popupText} anchorRef={linkRef} onClose={toggleOpen} maxWidth={288} outside />
    </>
  );
};
