import { IAppState } from '../../common/state';

export const getUserPhone = ({ user }: IAppState): string => {
  if (!user.isAuthenticated) {
    return '';
  }

  const phone = user.phones && user.phones[0];
  if (!phone) {
    return '';
  }

  return phone.phone;
};
