import { RadioButtonGroup } from '@cian/ui-kit';
import * as React from 'react';

import { ISeoLinksTab } from '../../../types/seoTabsLinks';

interface ISeoTabsProps {
  tabs: ISeoLinksTab[];
  value: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, value: number) => void;
}

export const SeoTabs = ({ tabs, value, onChange }: ISeoTabsProps) => {
  const options = React.useMemo(
    () =>
      tabs.map((tab, index) => ({
        label: tab.title,
        value: index,
      })),
    [tabs],
  );

  return (
    <div data-testid="QuickLinksTabs">
      <RadioButtonGroup spliced options={options} value={value} onChange={onChange} />
    </div>
  );
};
