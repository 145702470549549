import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { LinkButton, UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SubsidisedMortgageZeroPlaceBanner.css';

interface ISubsidisedMortgageZeroPlaceBannerProps {
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  url: string;
  onClick: () => void;
  onShow: () => void;
}

export const SubsidisedMortgageZeroPlaceBanner: React.FC<ISubsidisedMortgageZeroPlaceBannerProps> = ({
  title,
  subtitle,
  image,
  buttonText,
  url,
  onClick,
  onShow,
}) => {
  return (
    <ActionAfterViewObserver callback={onShow} percentVisible={0.8} triggerOnce wrapper={false}>
      <div data-name="SubsidisedMortgageZeroPlaceBanner" className={styles['container']}>
        <img src={image} width="52px" height="52px" alt="Субсидированная ипотека" className={styles['image']} />
        <div className={styles['info']}>
          <UIHeading4>{title}</UIHeading4>
          <UIText2>{subtitle}</UIText2>
        </div>
        <LinkButton href={url} size="M" theme="fill_primary" onClick={onClick}>
          {buttonText}
        </LinkButton>
      </div>
    </ActionAfterViewObserver>
  );
};
