import * as React from 'react';

interface IStarIconProps {
  color?: string;
}

export const StarIcon = (props: IStarIconProps) => {
  const { color = '#fe8512' } = props;

  return (
    <svg
      data-testid="IconStar"
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      fill="none"
      viewBox="0 0 12 12"
    >
      <g clipPath="url(#clip0_1283_15863)">
        <path
          fill={color}
          d="M6.526.36a.564.564 0 00-1.052 0L4.243 3.49a.566.566 0 01-.478.356l-3.247.275a.569.569 0 00-.33.989l2.514 2.276a.57.57 0 01.173.548l-.761 3.37a.566.566 0 00.857.604l2.724-1.758a.563.563 0 01.61 0l2.724 1.758a.566.566 0 00.857-.604l-.76-3.37a.57.57 0 01.172-.548l2.514-2.276a.569.569 0 00-.33-.989l-3.247-.275a.565.565 0 01-.478-.357L6.526.36z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1283_15863">
          <path fill="var(--decorative-theme-white)" d="M0 0H12V12H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
