/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetSimilarNewobjectsModel,
  IGetSimilarNewobjectsRequest,
  IMappers,
  IGetSimilarNewobjectsResponse,
  IGetSimilarNewobjectsResponseError,
  TGetSimilarNewobjectsResponse,
} from './types';

const defaultConfig: TGetSimilarNewobjectsModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-similar-newobjects/',
  hostType: 'api',
} as TGetSimilarNewobjectsModel;

function createGetSimilarNewobjectsModel(parameters: IGetSimilarNewobjectsRequest): TGetSimilarNewobjectsModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetSimilarNewobjectsOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetSimilarNewobjectsRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetSimilarNewobjects<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetSimilarNewobjectsOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetSimilarNewobjectsResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetSimilarNewobjectsModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetSimilarNewobjectsResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetSimilarNewobjectsResponseError);
    }
  }

  return { response, statusCode } as TGetSimilarNewobjectsResponse;
}

export { defaultConfig, createGetSimilarNewobjectsModel, fetchGetSimilarNewobjects };
