import { NB_RENT_TRAP } from '../../constants/experiments';
import { IAbUseExperiment } from '../../types/abUseExperiments';

export type TNewbuildingRentTrapExperimentGroup =
  /** Не показываем баннер **/
  | 'A'
  /** Показываем баннер старого образца **/
  | 'B'
  /** Показываем новый баннер **/
  | 'C';

export function getNewbuildingRentTrapExperimentGroup(
  experiments: IAbUseExperiment[],
): TNewbuildingRentTrapExperimentGroup {
  const experiment = experiments.find(e => e.experimentName === NB_RENT_TRAP);

  if (!experiment || !experiment.groupName) {
    return 'A';
  }

  return experiment.groupName as TNewbuildingRentTrapExperimentGroup;
}
