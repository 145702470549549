import { IconActionClose12 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './TagNew.css';

interface ITagNew {
  onDeleteClick(): void;
}

export const TagNew: React.FC<React.PropsWithChildren<ITagNew>> = ({ children, onDeleteClick }) => {
  return (
    <div className={styles['container']}>
      {children}
      <button className={styles['button']} onClick={onDeleteClick}>
        <IconActionClose12 />
      </button>
    </div>
  );
};
