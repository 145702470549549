import React, { useMemo } from 'react';

import { OfferCardContainer } from './OfferCardContainer';
import { useApplicationContext } from '../../../../../shared/utils/applicationContext';
import { OfferContext } from '../../context';
import { AuctionContextProvider } from '../../context/AuctionContext';
import { CardInViewProvider } from '../../context/CardInViewContext';
import { IOfferCardFragmentProps } from '../../types/offer-card';
import { combineComputedProps } from '../../utils/computedProps/combineComputedProps';

export function OfferContainer(props: IOfferCardFragmentProps) {
  const ctx = useApplicationContext();

  const computedProps = useMemo(() => combineComputedProps(props, ctx), [props, ctx]);

  const context = useMemo(
    () => ({
      // пропсы переданные фрагменту
      ...props,
      // вычисляемые свойства на основе переданных пропсов
      ...computedProps,
    }),
    [props, computedProps],
  );

  return (
    <OfferContext.Provider value={context}>
      <AuctionContextProvider {...props}>
        <CardInViewProvider>
          <OfferCardContainer />
        </CardInViewProvider>
      </AuctionContextProvider>
    </OfferContext.Provider>
  );
}
