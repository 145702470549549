import { type IConfig } from '@cian/config/shared';

import { getRandomBoolean } from './getRandomBoolean';

const NEWBUILDING_BANNER_ROTATION_VALUE_CONFIG_KEY = 'newbuildingBannerRotationValue';

/**
 * Возвращает рандомный булеан. Можно переопределить в конфиге, чтобы всегда получать нужный результат.
 */
export function getNewbuildingBannerRandomBoolean(config: IConfig): boolean {
  const newbuildingBannerRotationValue = config.get<boolean>(NEWBUILDING_BANNER_ROTATION_VALUE_CONFIG_KEY);

  if (typeof newbuildingBannerRotationValue === 'boolean') {
    return newbuildingBannerRotationValue;
  }

  return getRandomBoolean();
}
