export enum EBannerType {
  DealSale,
  MortgageBroker,
  SubsidisedMortgage,
  CianDobro,
  RentKpnCallback,
  RentNBListing,
  SuburbanNBTrap,
  FlatSaleNBTrap,
}
