import { ca } from '@cian/analytics';

import { IOffer } from '../../../../fragments/OfferCardWidget/shared/types/offer';

export function trackModalOpen(offer: IOffer, position: number) {
  ca('eventSite', {
    name: 'oldevent',
    category: 'Message_popup',
    action: 'open',
    label: offer.gaLabel,
    products: [
      {
        id: offer.id,
        offerType: 'offer',
        position,
      },
    ],
    page: {
      pageType: 'Listing',
      siteType: 'desktop',
      extra: {
        /* istanbul ignore next */
        opponent_id: offer.user?.cianUserId,
      },
    },
  });
}
