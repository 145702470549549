import { Header5 } from '@cian/ui-kit/typography';
import * as React from 'react';

import * as styles from './SummaryHeader.css';

export interface IHeaderProps {
  header: string;
}

export const SummaryHeader: React.FC<IHeaderProps> = ({ header }) => {
  if (!header) {
    return null;
  }

  return (
    <div className={styles['header']} data-testid="SummaryHeader">
      <Header5 as="h5" fontWeight="bold" color="text-primary-default">
        {header}
      </Header5>
    </div>
  );
};

SummaryHeader.displayName = 'SummaryHeader';
