import { selectJsonQuery, selectOffersCount } from './results';
import { getIsSubsidisedMortgage } from '../../packages/JsonQuery/getIsSubsidisedMortgage';
import { IAppState } from '../common/state';

export const selectIsSubsidisedMortgageZeroPlaceBannerVisible = (state: IAppState) => {
  const jsonQuery = selectJsonQuery(state);
  const offersCount = selectOffersCount(state);

  const isSubsidisedMortgage = getIsSubsidisedMortgage(jsonQuery);
  const bannerInfo = state.config.get('subsidizedMortgage.desktopSerpZeroPlaceBannerInfo');

  return Boolean(offersCount && isSubsidisedMortgage && bannerInfo);
};
