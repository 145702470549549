import { IConfig } from '@cian/config/shared';

import { INewbuildingSchema } from '../../repositories/search-offers/entities/newbuilding/NewbuildingSchema';

// TODO: Удалить в CD-221978 после генерации.
/* istanbul ignore next */
export function mockBuilderChat(config: IConfig, newbuilding: INewbuildingSchema | null | undefined) {
  if (
    config.get<boolean>('builderChatEnabled') &&
    newbuilding &&
    newbuilding.builders &&
    newbuilding.builders[0] &&
    // @ts-expect-error Удалить в CD-221978
    !newbuilding.builders[0].chat
  ) {
    // @ts-expect-error Удалить в CD-221978
    newbuilding.builders[0].chat = {
      isEnabled: true,
      isOnline: true,
      defaultMessage:
        'Здравствуйте! Я пишу по ЖК Цветочки по адресу Профсоюзная 50 в Москве.\nhttps://zhk-akzent-spb-i.cian.ru/',
    };
  }
}
