import { Button, Image, LinkButton, UIHeading4, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import { Features } from './internal/Features';
import * as style from './style.css';
import { INewbuildingLayout } from '../../types/residentialComplexLayouts/layouts/newbuildingLayout';

export interface ICardProps {
  layout: INewbuildingLayout;
  onClick(key: string): void;
  onLinkClick(): void;
}

export const Card = ({ layout, onClick, onLinkClick }: ICardProps) => {
  const onCardClick = React.useCallback(() => {
    onClick(layout.layoutKey);
  }, [layout]);

  const isOfferLinkButton = React.useMemo(() => Boolean(layout.singleOfferId), [layout]);

  return (
    <div className={style['container']}>
      <div className={style['image']}>
        <Image src={layout.imageUrl} alt="Планировка квартиры" objectFit="contain" />
        {layout.hasTour && <Features />}
      </div>
      <div className={style['description']}>
        <UIHeading4 textAlign="center">
          {layout.roomType} {layout.areaRangeText}
        </UIHeading4>
        <UIText2 textAlign="center">{layout.priceRangeText}</UIText2>
      </div>
      {isOfferLinkButton ? (
        <LinkButton
          fullWidth
          theme="fill_secondary"
          href={`https://www.cian.ru/sale/flat/${layout.singleOfferId}/`}
          target="_blank"
          onClick={onLinkClick}
        >
          {layout.offersCountText}
        </LinkButton>
      ) : (
        <Button fullWidth theme="fill_secondary" onClick={onCardClick}>
          {layout.offersCountText}
        </Button>
      )}
    </div>
  );
};
