import { Header5 } from '@cian/ui-kit';
import { Button } from '@cian/ui-kit/button';
import { IconProductProSearch16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './Footer.css';

interface IFooterProps {
  professionalSearchUrl: string;
  isProfessionalSearchButtonVisible: boolean;
  searchButtonText: string;
  isCounterRefreshing: boolean;
  onProfessionalSearchClick(): void;
  onClearClick(): void;
  onShowClick(): void;
}

export const Footer: React.FC<IFooterProps> = ({
  onClearClick,
  onShowClick,
  professionalSearchUrl,
  isProfessionalSearchButtonVisible,
  isCounterRefreshing,
  searchButtonText,
  onProfessionalSearchClick,
}) => {
  const buttons = (
    <>
      <Button size="XS" theme="fill_white_primary" onClick={onClearClick}>
        Сбросить фильтры
      </Button>
      <Button size="XS" theme="fill_primary" onClick={onShowClick} loading={isCounterRefreshing}>
        {searchButtonText}
      </Button>
    </>
  );

  if (isProfessionalSearchButtonVisible) {
    return (
      <div className={styles['container--dual-side']}>
        <a className={styles['profsearch_link']} onClick={onProfessionalSearchClick} href={professionalSearchUrl}>
          <IconProductProSearch16 color="icon-main-default"></IconProductProSearch16>
          <Header5 color="text-main-default">Профпоиск для риелторов</Header5>
        </a>
        <div className={styles['primary_buttons']}>{buttons}</div>
      </div>
    );
  }

  return <div className={styles['container']}>{buttons}</div>;
};
