import { TPromoSearchTypes } from '../../../../shared/types/promoSearchTypes';

export function getPromoSearchFilterValuesByType(
  key: keyof NonNullable<TPromoSearchTypes>,
  promoSearchTypes: TPromoSearchTypes,
) {
  if (!promoSearchTypes) {
    return [];
  }

  return promoSearchTypes[key].map(item => item.value);
}
