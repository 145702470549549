import { Image } from '@cian/ui-kit';
import * as React from 'react';

import { trackGoToKPCardFromListingClick } from './tracking';
import * as styles from './village.css';
import { Carousel, EMediaType, TMedia } from '../../../../components/Carousel';
import { IJsonQuery } from '../../../../json_query';
import { IBreadcrumb } from '../../../../offer';
import { IVillageInfo } from '../../../../types/villageInfo';
import { Breadcrumbs } from '../../Breadcrumbs';

export interface IHeaderVillageProps {
  breadcrumbs: IBreadcrumb[];
  jsonQuery: IJsonQuery;
  villageInfo: IVillageInfo;
  h1: string;
}

export const DEFAULT_KP_PREFIX = 'коттеджном поселке';

export class HeaderVillage extends React.Component<IHeaderVillageProps> {
  public render() {
    const { breadcrumbs, villageInfo } = this.props;

    let media =
      villageInfo.gallery?.imageList?.map<TMedia>(i => ({ type: EMediaType.Photo, src: i.fullUrl || '' })) || null;
    if (media?.length === 0) {
      media = null;
    }

    return (
      <div className={styles['container']}>
        <div className={styles['wrapper']}>
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <h1 className={styles['title']}>{this.renderTitle()}</h1>
          <div className={styles['content']}>
            <div className={styles['content-main']}>
              <div className={styles['content-title']}>
                <a href={villageInfo.seo?.url || undefined} target="_blank" rel="noreferrer">
                  КП &laquo;{villageInfo.name}&raquo;
                </a>
              </div>
              <div className={styles['content-info']}>
                <span className={styles['content-address']}>{villageInfo.fullAddress}</span>
              </div>
              {villageInfo.description && (
                <div className={styles['content-description']}>{villageInfo.description}</div>
              )}
              <div className={styles['content-link']}>
                <a href={villageInfo.seo?.url || undefined} target="_blank" rel="noreferrer">
                  Подробнее о КП
                </a>
              </div>
            </div>
            <div className={styles['content-gallery']}>
              {!media && <Image src="" />}
              {media && <Carousel media={media} />}
            </div>
          </div>
        </div>
      </div>
    );
  }

  private renderTitle = () => {
    const {
      h1,
      villageInfo: { name, seo, id },
    } = this.props;

    // Удаляем название КП из h1
    const titleWithoutKpName = h1.replace(name, '');
    let title = h1;
    let linkPrefix = '';

    // Если выдача ЧПУ, то присваиваем обрезанный h1 и генерим текстовку в начале ссылки
    if (titleWithoutKpName !== h1) {
      linkPrefix = titleWithoutKpName.includes(`в ${DEFAULT_KP_PREFIX}`) ? DEFAULT_KP_PREFIX : '';
      title = titleWithoutKpName.replace(`в ${DEFAULT_KP_PREFIX}`, '');
    } else {
      // Если выдача не ЧПУ, то добавляем стандартный префикс к названию КП
      linkPrefix = DEFAULT_KP_PREFIX;
    }
    // Формируем текст ссылки
    const linkText = `${linkPrefix} «${name}»`;

    return (
      <span>
        {title} в&nbsp;
        <a
          href={seo?.url || undefined}
          target="_blank"
          onClick={() => trackGoToKPCardFromListingClick(id)}
          rel="noreferrer"
        >
          {linkText}
        </a>
      </span>
    );
  };
}

export const isVillageInfoVisible = (villageInfo: IVillageInfo, { kp_id, _type }: IJsonQuery) =>
  _type === 'suburbansale' && kp_id && kp_id.value === villageInfo.id;
