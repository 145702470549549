import { ca } from '@cian/analytics';

import { IJsonQuery } from '../../../repositories/common/json_query';

export const trackBannerShow = (sc: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BlackFriday', // Переиспользуем категорию ЧП.
    action: 'show_sopr',
    label: 'listing_banner',
    sc,
  });
};

export const trackBannerClick = (sc: IJsonQuery) => {
  ca('eventSite', {
    name: 'oldevent',
    category: 'BlackFriday', // Переиспользуем категорию ЧП.
    action: 'click_sopr',
    label: 'listing_banner',
    sc,
  });
};
