import { getGlobalEventsLogs } from '@cian/events-log';

import { subscribeOnAuthentification, TOPIC_USER } from './subscribeOnAuthentification';
import { IRequestAuthenticationParameters, IAuthenticateEvent } from './types';
import { TThunkAction } from '../../types/redux';

export function requestAuthentication(parameters?: IRequestAuthenticationParameters): TThunkAction {
  return dispatch => {
    /* istanbul ignore else */
    if (typeof window !== 'undefined') {
      const globalLog = getGlobalEventsLogs();

      dispatch(subscribeOnAuthentification(parameters));

      globalLog.produce<Required<IAuthenticateEvent>>(TOPIC_USER, {
        type: 'authenticate',
        value: {
          autoRefresh: false,
          viewData: parameters?.viewData || null,
          source: parameters?.source || null,
        },
      });
    }
  };
}
