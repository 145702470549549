/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetProjectsSuggestModel,
  IGetProjectsSuggestRequest,
  IMappers,
  IGetProjectsSuggestResponse,
  IGetProjectsSuggestResponseError,
  TGetProjectsSuggestResponse,
} from './types';

const defaultConfig: TGetProjectsSuggestModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'countryside-constructors',
  pathApi: '/v1/get-projects-suggest/',
  hostType: 'api',
} as TGetProjectsSuggestModel;

function createGetProjectsSuggestModel(parameters: IGetProjectsSuggestRequest): TGetProjectsSuggestModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetProjectsSuggestOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetProjectsSuggestRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetProjectsSuggest<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetProjectsSuggestOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetProjectsSuggestResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetProjectsSuggestModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetProjectsSuggestResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetProjectsSuggestResponseError);
    }
  }

  return { response, statusCode } as TGetProjectsSuggestResponse;
}

export { defaultConfig, createGetProjectsSuggestModel, fetchGetProjectsSuggest };
