import { useSelector } from 'react-redux';

import { IAppState } from 'shared/common/state';
import { IOffer } from 'shared/offer';
import { trackMLSuggestionsShown } from 'shared/serp/components/offers/tracking';

type TTrackingFunction = (
  suggestions: IOffer[] | null,
  pageNumber: number,
  allSuggestions?: IOffer[] | null,
  addedSuggestions?: IOffer[],
) => void;

export function useSuggestionsTracking(): TTrackingFunction {
  const jsonQuery = useSelector((state: IAppState) => state.results.jsonQuery);
  const breadcrumbs = useSelector((state: IAppState) => state.breadcrumbs);
  const queryString = useSelector((state: IAppState) => state.results.queryString);
  const offersQty = useSelector((state: IAppState) => state.results.totalOffers);
  const user = useSelector((state: IAppState) => state.user);
  const mlRankingGuid = useSelector((state: IAppState) => state.mlRankingGuid);
  const mlRankingModelVersion = useSelector((state: IAppState) => state.mlRankingModelVersion);

  return function handleTracking(suggestions, pageNumber, allSuggestions, addedSuggestions) {
    if (suggestions && suggestions.length > 0) {
      const offerWithModelVersion = (addedSuggestions || suggestions || []).find(
        offer => typeof offer.modelVersion !== 'object',
      );

      const modelVersion = offerWithModelVersion ? offerWithModelVersion.modelVersion : null;

      trackMLSuggestionsShown({
        jsonQuery,
        recommendations: addedSuggestions || suggestions || [],
        allRecommendations: allSuggestions || [],
        modelVersion,
        pageNumber,
        breadcrumbs,
        queryString,
        offersQty,
        user,
        mlRankingGuid,
        mlRankingModelVersion,
      });
    }
  };
}
