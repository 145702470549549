/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetNewobjectsBuilderModel,
  IGetNewobjectsBuilderRequest,
  IMappers,
  IGetNewobjectsBuilderResponse,
  IGetNewobjectsBuilderResponseError,
  TGetNewobjectsBuilderResponse,
} from './types';

const defaultConfig: TGetNewobjectsBuilderModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-newobjects-builder/',
  hostType: 'api',
} as TGetNewobjectsBuilderModel;

function createGetNewobjectsBuilderModel(parameters: IGetNewobjectsBuilderRequest): TGetNewobjectsBuilderModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewobjectsBuilderOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetNewobjectsBuilderRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetNewobjectsBuilder<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetNewobjectsBuilderOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetNewobjectsBuilderResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetNewobjectsBuilderModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewobjectsBuilderResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetNewobjectsBuilderResponseError);
    }
  }

  return { response, statusCode } as TGetNewobjectsBuilderResponse;
}

export { defaultConfig, createGetNewobjectsBuilderModel, fetchGetNewobjectsBuilder };
