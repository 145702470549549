import { LinkButton } from '@cian/ui-kit/button';
import { IconActionMap16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { selectIsSeoUrlOnMapExperimentEnabled } from '../../../../selectors/experiments/selectIsSeoUrlOnMapExperimentEnabled';
import { selectOffersCount } from '../../../../selectors/results';
import { selectMapUrl } from '../../../../selectors/selectMapUrl';
import { SummaryButtonWrapper } from '../../../components/Summary';
import { trackMapViewButtonClicked } from '../utils/tracking';

export function SummaryMapViewButtonContainer() {
  const offerCount = useSelector(selectOffersCount);
  const mapUrl = useSelector(selectMapUrl);
  const isSeoUrlOnMapExperimentEnabled = useSelector(selectIsSeoUrlOnMapExperimentEnabled);

  if (offerCount === 0) {
    return null;
  }

  return (
    <SummaryButtonWrapper>
      <LinkButton
        href={mapUrl}
        target={isSeoUrlOnMapExperimentEnabled ? '_self' : '_blank'}
        size="XS"
        theme="fill_secondary"
        beforeIcon={<IconActionMap16 color="icon-main-default" />}
        onClick={trackMapViewButtonClicked}
      >
        На карте
      </LinkButton>
    </SummaryButtonWrapper>
  );
}
