import { ActionAfterViewObserver } from '@cian/action-after-viewed-component';
import { LinkButton, UIHeading2, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './SubsidisedMortgageZeroResultsBanner.css';

interface ISubsidisedMortgageZeroResultsBannerProps {
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  url: string;
  onClick: () => void;
  onShow: () => void;
}

export const SubsidisedMortgageZeroResultsBanner: React.FC<ISubsidisedMortgageZeroResultsBannerProps> = ({
  title,
  subtitle,
  image,
  buttonText,
  url,
  onClick,
  onShow,
}) => {
  return (
    <ActionAfterViewObserver callback={onShow} percentVisible={0.8} triggerOnce wrapper={false}>
      <div data-name="SubsidisedMortgageZeroResultsBanner" className={styles['container']}>
        <img src={image} width="40px" height="40px" alt="Субсидированная ипотека" className={styles['image']} />
        <UIHeading2>{title}</UIHeading2>
        <UIText1>{subtitle}</UIText1>
        <LinkButton href={url} size="L" theme="fill_white_primary" onClick={onClick}>
          {buttonText}
        </LinkButton>
      </div>
    </ActionAfterViewObserver>
  );
};
