import { IAppState } from '../../common/state';
import {
  getNewbuildingSuburbanTrapExperimentGroup,
  TNewbuildingSuburbanTrapExperimentGroup,
} from '../../utils/experiments/getNewbuildingSuburbanTrapExperimentGroup';

export function selectNewbuildingSuburbanSaleTrap({
  abUseExperiments,
}: IAppState): TNewbuildingSuburbanTrapExperimentGroup {
  return getNewbuildingSuburbanTrapExperimentGroup(abUseExperiments);
}
