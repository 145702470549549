import { InformationTooltipDesktop } from '@cian/ui-kit';
import { IconFunctionalInformationCircle16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

export interface IAuctionApplyNewBetHintProps {
  /** Новая ставка (возвращается в случае понижения ставки) */
  nextBet: number;
  /** Дата применения новой ставки (возвращается в случае понижения ставки) */
  applyNewBetDate: string | null | undefined;
}

export function AuctionApplyNewBetHint({ nextBet, applyNewBetDate }: IAuctionApplyNewBetHintProps) {
  return (
    <InformationTooltipDesktop
      theme="white"
      placement="bottom"
      content={
        applyNewBetDate ? (
          <>
            Новая ставка {nextBet}&nbsp;₽ в сутки.
            <br />
            Применится в {applyNewBetDate}
          </>
        ) : (
          <>
            Обновите результаты поиска, чтобы увидеть
            <br />
            своё объявление на новой странице
          </>
        )
      }
    >
      <IconFunctionalInformationCircle16 />
    </InformationTooltipDesktop>
  );
}
