import { IAppState } from '../../common/state';
import { INewbuildingLayoutOffer } from '../../types/residentialComplexLayouts/layoutWithOffers/offer';

export const residentialComplexLayoutByOfferIdSelector =
  (state: IAppState) =>
  (offerId: number): INewbuildingLayoutOffer | null => {
    const offers = state.newbuildingLayoutOffers.data?.offers;
    if (!offers) {
      return null;
    }

    const offer = offers.find((offer: INewbuildingLayoutOffer) => {
      return offer.offerId === offerId;
    });

    return offer || null;
  };
