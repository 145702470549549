import { isDailyRent } from './category/isDailyRent';
import { getNewbuildingFeaturePromoLabels } from './labels/getNewbuildingFeaturePromoLabels';
import { getNewbuildingSnippetLabels } from './labels/getNewbuildingSnippetLabels';
import { getOnlineRentalAgreementLabel } from './labels/getOnlineRentalAgreementLabel';
import { getPledgeRealtyLabel } from './labels/getPledgeRealtyLabel';
import { getRosreestrCheckedLabel } from './labels/getRosreestrCheckedLabel';
import { getTradeRealtyLabel } from './labels/getTradeRealtyLabel';
import { EOfferFeatureLabelsV2Types } from '../../../../../shared/repositories/search-offers.legacy/v2/types';
import { getIsDailyrentOnlineBookingEnabled } from '../../../../../shared/utils/featureToggles/useIsDailyrentOnlineBookingEnabled';
import { TComputedPropMapper } from '../../types/computed-props-mapper';
import { ELabelColor, ILabel } from '../../types/label';
import { getIsSoldFurnishedLabelText } from '../getIsSoldFurnishedLabelText';
import { isNewbuildingBooked, isNewbuildingBookingAvailable } from '../newbuildingBooking';
import { prepareGoodPriceLabel } from '../prepareGoodPriceLabel';
import { prepareNotApprovedLayoutLabel } from '../prepareNotApprovedLayoutLabel';
import { getSubsidisedMortgageLabelText, isSubsidisedMortgageAvailable } from '../subsidisedMortgage';

const LABEL_TYPE_TO_COLOR: Record<EOfferFeatureLabelsV2Types, ELabelColor> = {
  [EOfferFeatureLabelsV2Types.DiscountMortgage]: ELabelColor.Purple,
};

export const labels: TComputedPropMapper<ILabel[]> = (
  { offer, isRosreestrCheckedLabelAvailable, isNewbuildingBookedFromDeveloper },
  { config },
) => {
  const isDailyRentOffer = isDailyRent(offer);
  /**
   * @todo Удалить фича-тогл
   * @description Данный функционал появился в задаче CD-187921, будет удалён в задаче CD-189395
   */
  const isElectronicTradingEnabled = config.getStrict<boolean>('Audience.ElectronicTrading.Enabled');

  const isDailyrentOnlineBookingEnabled = getIsDailyrentOnlineBookingEnabled(config);
  const isDailyrentOnlineBooking = Boolean(isDailyrentOnlineBookingEnabled && offer.dailyrent?.isOnlineBooking);
  const isCianPartner = isDailyrentOnlineBookingEnabled && offer.isCianPartner;
  const showInstantBookingLabel = isDailyRentOffer && (isDailyrentOnlineBooking || isCianPartner);

  const isSearchFlatShareEnabled = config.getStrict<boolean>('frontend_search_flat_share_enabled.Enabled');
  const offerLabels: string[] = offer.offerFeatureLabels || [];
  const offerLabelsV2 = offer.offerFeatureLabelsV2 || [];

  const newbuildingBooked = isNewbuildingBooked(offer, config);

  const newbuildingBookingAvailable = isNewbuildingBookingAvailable(offer, config);
  const subsidisedMortgageAvailable = isSubsidisedMortgageAvailable(offer, config);
  const goodPriceLabel = prepareGoodPriceLabel(offer);
  const layoutLabel = prepareNotApprovedLayoutLabel(offer);

  let labels: ILabel[] = [];

  const labelsV1: ILabel[] = offerLabels.map(text => ({ color: ELabelColor.DarkGray, text }));
  const labelsV2: ILabel[] = offerLabelsV2.map(({ text, type }) => ({ color: LABEL_TYPE_TO_COLOR[type], text }));

  labels.push(...labelsV1);

  labels.unshift(...labelsV2);

  const promoLabels = getNewbuildingFeaturePromoLabels(offer);

  if (offer.isOnlineRentalAgreementEnabled) {
    labels.unshift(getOnlineRentalAgreementLabel());
  }

  if (!isDailyRentOffer && offer.isUnique && !offer.promoInfo?.isExclusive) {
    labels.unshift({ color: ELabelColor.Blue, text: 'Только на Циан' });
  }

  if (showInstantBookingLabel) {
    labels.push({
      color: ELabelColor.BlueInstantBooking,
      text: 'Мгновенная бронь',
      tooltip: {
        description:
          'Это жильё можно забронировать без переговоров с владельцем и долгого ожидания подтверждения брони',
      },
    });
  }

  if (offer.isVerifiedByCian) {
    labels.unshift({
      color: ELabelColor.BlueVerifiedByCian,
      text: 'Проверено Циан',
      tooltip: {
        description:
          'Убедились, что информация в объявлении верна — оценили видео, фото объекта или дополнительно проанализировали объявление',
        link: 'https://support.cian.ru/ru/knowledge_base/article/7516/category/463',
        linkText: 'Подробнее',
      },
    });
  }

  if (newbuildingBooked || isNewbuildingBookedFromDeveloper) {
    labels.unshift({
      text: 'Забронирована',
      color: ELabelColor.DarkGray,
    });
  } else if (newbuildingBookingAvailable) {
    labels.unshift({
      text: 'Онлайн-бронирование',
      color: ELabelColor.DarkGray,
      tooltip: {
        title: 'Забронируйте квартиру за собой',
        description: 'Стоимость услуги 20 000 ₽ на 5 дней',
      },
    });
  }

  if (promoLabels) {
    labels.unshift(...promoLabels);
  }

  if (offer.isSoldFurnished) {
    labels.unshift({ color: ELabelColor.Orange, text: getIsSoldFurnishedLabelText(config) });
  }

  if (goodPriceLabel) {
    labels.unshift(goodPriceLabel);
  }

  const extensionLabels = offer.extensionLabels?.map(({ value }) => ({ text: value, color: ELabelColor.Yellow })) || [];
  labels = labels.concat(extensionLabels);

  if (isRosreestrCheckedLabelAvailable) {
    labels.push(getRosreestrCheckedLabel(offer.offerType));
  }

  if (isSearchFlatShareEnabled && layoutLabel) {
    labels.unshift(layoutLabel);
  }

  if (isElectronicTradingEnabled) {
    const isPledgeRealty = !!offer.user?.agentLists?.includes('pledgeRealty');
    const isTradeRealty = !!offer.user?.agentLists?.includes('tradeRealty');

    if (isPledgeRealty) {
      labels.push(getPledgeRealtyLabel());
    }

    if (isTradeRealty) {
      labels.push(getTradeRealtyLabel());
    }
  }

  if (subsidisedMortgageAvailable) {
    labels.unshift({ color: ELabelColor.OrangePercent, text: getSubsidisedMortgageLabelText(config) });
  }

  if (offer.newbuilding?.snippetType) {
    return getNewbuildingSnippetLabels({
      labelsV1,
      labelsV2,
      promoLabels,
      snippetType: offer.newbuilding?.snippetType,
      countPromos: offer.newbuilding?.countPromos,
      isBlackFriday: !!offer.promoInfo?.isBlackFriday,
      isExclusive: !!offer.promoInfo?.isExclusive,
      isUnique: offer.isUnique,
    });
  }

  return labels;
};
