import { TooltipDesktop, UIText1, UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { ILabelTooltip } from '../../types/label';

interface ILabelTooltipProps {
  children: React.ReactElement;
  tooltip?: ILabelTooltip;
}

export function LabelTooltip({ tooltip, children }: ILabelTooltipProps) {
  if (!tooltip) {
    return children;
  }

  return (
    <TooltipDesktop
      title={
        <div className={styles['content']}>
          {tooltip.title && <UIText1 fontWeight="bold">{tooltip.title}</UIText1>}
          <div className={styles['text']}>
            <UIText2>{tooltip.description}</UIText2>
          </div>
          {tooltip.link && (
            <UIText2>
              <a className={styles['link']} href={tooltip.link} rel="noreferrer noopener" target="_blank">
                {tooltip.linkText ?? 'Как это работает'}
              </a>
            </UIText2>
          )}
        </div>
      }
      theme={'white'}
      placement={'bottom-start'}
      onOpen={tooltip.onOpen}
    >
      {children}
    </TooltipDesktop>
  );
}
