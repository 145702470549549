import { type IHttpApi } from '@cian/http-api/shared';

import { assertUnknownError } from './assert';
import { mapAddOfferToComparisonResponse, mapAddOfferToComparisonResponseError } from './mappers';
import {
  fetchAddOfferToComparison,
  IAddOfferToComparisonRequest,
} from '../../../repositories/offers-comparison/v2/add-offer-to-comparison';
import { IAddOfferToComparison } from '../../../types/offersComparison';

export async function addOfferToComparisonRequest({
  httpApi,
  parameters,
}: {
  httpApi: IHttpApi;
  parameters: IAddOfferToComparisonRequest;
}): Promise<IAddOfferToComparison> {
  const response = await fetchAddOfferToComparison({
    httpApi,
    parameters,
    config: {},
    mappers: {
      200: mapAddOfferToComparisonResponse,
      400: mapAddOfferToComparisonResponseError,
    },
  });

  assertUnknownError(response);

  return response.response as IAddOfferToComparison;
}
