import * as React from 'react';

import { SeoFullListHidden } from './SeoFullListHidden';
import { SeoLinksList } from './SeoLinksList';
import { SeoTabs } from './SeoTabs';
import * as style from './style.css';
import { getDefaultTab } from './utils/getDefaultTab';
import { ISeoLinksTab } from '../../../types/seoTabsLinks';

interface ITabsLinksProps {
  tabs: ISeoLinksTab[];
}

export const SeoTabsLinks = ({ tabs }: ITabsLinksProps) => {
  const [selectedTab, setSelectedTab] = React.useState(getDefaultTab(tabs));
  const [isExpanded, setIsExpanded] = React.useState(false);

  const onTabChange = React.useCallback((_: React.ChangeEvent<HTMLInputElement>, value: number) => {
    setSelectedTab(value);
  }, []);
  const onMoreButtonClick = React.useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const links = React.useMemo(() => tabs[selectedTab].links, [tabs, selectedTab]);

  return (
    <div className={style['container']}>
      {isExpanded && <SeoTabs tabs={tabs} value={selectedTab} onChange={onTabChange} />}
      <SeoLinksList links={links} isExpanded={isExpanded} onMoreButtonClick={onMoreButtonClick} />
      <SeoFullListHidden tabs={tabs} />
    </div>
  );
};
