import { UIText2 } from '@cian/ui-kit';
import { IconActionChevronDownSmall16, IconActionChevronUpSmall16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as style from './style.css';

interface IMoreButtonProps {
  onClick(): void;
  isExpanded: boolean;
}

export const SeoLinksMoreButton = ({ onClick, isExpanded }: IMoreButtonProps) => {
  return (
    <span className={style['link']} onClick={onClick}>
      <UIText2 color="text-main-default" as="span" display="inline-block">
        {isExpanded ? 'Свернуть' : 'Ещё'}
      </UIText2>
      {isExpanded ? (
        <IconActionChevronUpSmall16 color="icon-main-default" />
      ) : (
        <IconActionChevronDownSmall16 color="icon-main-default" />
      )}
    </span>
  );
};
