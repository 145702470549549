import { IOffer } from '../../../../offer';

export const getAgentName = (offer: IOffer): string | undefined => {
  const { user, isByHomeowner, newbuilding } = offer;
  const { masterAgent, cianUserId, agencyName } = user || {};

  if (isByHomeowner) {
    return cianUserId ? `ID ${cianUserId}` : undefined;
  }

  if (masterAgent && masterAgent.name) {
    return masterAgent.name;
  }

  if (newbuilding?.isFromBuilder && agencyName) {
    return `Застройщик ${agencyName}`;
  }

  if (agencyName) {
    return agencyName;
  }

  if (cianUserId) {
    return `ID ${cianUserId}`;
  }

  return undefined;
};
