import { useMemo } from 'react';

import { TPromoSearchTypes } from '../../../../../../../shared/types/promoSearchTypes';
import { getAllowedPromoSearchTypes } from '../../../../../../JsonQuery';
import { getPromoSearchFilterValuesByType } from '../../../../utils/getPromoSearchFilterValuesByType';

export const useDiscountPromoSearchTypeOptions = (promoSearchTypes: TPromoSearchTypes, apartments: boolean | null) => {
  return useMemo(() => {
    if (!promoSearchTypes) {
      return [];
    }

    const promoSearchTypeValues = getPromoSearchFilterValuesByType('discount', promoSearchTypes);
    const allowedPromoSearchTypes = getAllowedPromoSearchTypes(apartments, promoSearchTypeValues);

    return [
      { label: 'Неважно', value: null },
      ...allowedPromoSearchTypes.map(value => {
        const label = promoSearchTypes.discount.find(type => type.value === value)?.name ?? '';

        return {
          value,
          label,
        };
      }),
    ];
  }, [apartments, promoSearchTypes]);
};
