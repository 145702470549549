/* eslint-disable max-lines */
import {
  GeoSuggest as StructuredGeoSuggest,
  IMultiSuggestResult,
  TSuggestType,
  ESuggestType,
  IMessageInfo,
  EInputType,
  IItemClickParams,
  ETruncation,
} from '@cian/geosuggest-widget';
import { type IHttpApi } from '@cian/http-api/shared';
import { type ILogger } from '@cian/logger';
import { Spinner } from '@cian/ui-kit/loader';
import { IconActionSearch16 } from '@cian/ui-kit-design-tokens/icons';
import { isEmpty } from 'ramda';
import * as React from 'react';
import { debounce } from 'throttle-debounce';

import { getMultiSuggest, getSuggestOfferType, geoTypeMapper } from './api';
import { isNewbuildingsAvailable, removeCountryName } from './helpers';
import styles from './styles.css';
import {
  trackGeoSuggestClick,
  trackGeoSuggestNotFound,
  trackNewbuildingSuggestLinkClick,
  trackSelectGeo,
} from './tracking';
import {
  IGeocodeCachedResult,
  IGeocodeForSearchResponse,
  IGeoDetail,
  geocodeForSearch,
  geocode,
  geocodeCached,
} from '../../../api/geo';
import { EDealType, ESource } from '../../../repositories/geo-suggest/v2/suggest';
import { ILocation, TLocation } from '../../../types/location';
import { FDealType, FOfferType, isSuburban } from '../../../utils/category';
import { getRegionId } from '../../../utils/geo';
import { getGeoSuggestLocation } from '../../../utils/getGeoSuggestLocation';
import { IMakeRequest } from '../../../utils/request';
import { IDirection } from '../../api/directions';
import { IGeoObject, IPolygonObject, TGeoType } from '../../state/geo';
import { ISelectKPParams } from '../../state/kp_id';

export type IGeocodeResult = IGeocodeCachedResult;

const DEAL_TYPE_SUGGEST_MAP: { [key: number]: EDealType } = {
  [FDealType.Sale]: EDealType.Sale,
  [FDealType.RentLongterm]: EDealType.Rent,
  [FDealType.RentDaily]: EDealType.Rent,
  [FDealType.Rent]: EDealType.Rent,
};

export interface IGeoSuggestProps {
  makeRequest: IMakeRequest;
  logger: ILogger;

  highwaysData: IDirection[];
  dealType: FDealType;
  offerType: FOfferType;
  currentLocation: TLocation;
  boundedBy: [YMaps.TCoord, YMaps.TCoord] | undefined;

  httpApi: IHttpApi;

  rightAddon?: React.ReactNode;
  redesign?: boolean;

  onGeoSelected(value: IGeoObject | IPolygonObject, userInput: string): void;
  onHighwaySelected(highwaysIds: number[], regionId?: number): void;
  directionsDataReceived(directionsData: IDirection[], regionId: number): void;
  onKPSelected(params: ISelectKPParams): void;
  onBuilderSelected({ builderId, name }: { builderId: number; name: string }): void;
}

export interface IGeoSuggestState {
  value: string;
  selectedValue: string;
  loading: boolean;
  disabled: boolean;
  error: IMessageInfo;
  suggestData?: IMultiSuggestResult;
}

type TGeoModel = Partial<IGeoObject | IPolygonObject> & { isParsed?: boolean };

const ERROR = { title: 'Не удалось получить возможные варианты', text: 'Попробуйте еще раз' };
const NOT_FOUND = { title: 'Ничего не найдено', text: 'Укажите другой адрес' };
const YANDEX_RESULTS_MAX = 10;

export class GeoSuggest extends React.Component<IGeoSuggestProps, IGeoSuggestState> {
  public state: IGeoSuggestState = {
    value: '',
    selectedValue: '',
    loading: false,
    disabled: false,
    error: {},
    suggestData: undefined,
  };

  public renderSuggest() {
    const { disabled, loading, value, selectedValue, suggestData, error } = this.state;
    const { redesign } = this.props;

    return (
      <StructuredGeoSuggest
        suggestData={suggestData}
        value={value}
        selectedValue={selectedValue}
        placeholder={this.getPlaceholder()}
        isLoading={!redesign && loading}
        disabled={disabled}
        error={error}
        inputStyle={redesign ? styles['input'] : undefined}
        inputType={EInputType.search}
        onItemClick={this.onItemClick}
        onValueChange={this.onValueChange}
        onFocus={this.onFocus}
        onLinkClick={this.onLinkClick}
        onBlur={this.onBlur}
        itemsTruncation={ETruncation.Two}
      />
    );
  }

  public render() {
    const { loading } = this.state;
    if (this.props.redesign) {
      return (
        <div className={styles['container']}>
          <div className={styles['addon-left']}>
            <IconActionSearch16 color="icon-main-default" />
          </div>
          {this.renderSuggest()}
          <div className={styles['addon-right']}>
            {loading && (
              <div className={styles['spinner']}>
                <Spinner size={16} color="current_color" />
              </div>
            )}
            {this.props.rightAddon}
          </div>
        </div>
      );
    }

    return this.renderSuggest();
  }

  private getPlaceholder = () => {
    const { dealType, offerType, currentLocation, redesign } = this.props;

    const isUrban = isNewbuildingsAvailable(dealType, offerType, currentLocation);

    if (isSuburban(offerType)) {
      return redesign ? 'Адрес' : 'Город, адрес, метро, район, ж/д, шоссе';
    }

    if (isUrban) {
      return redesign ? 'Адрес, ЖК или Застройщик' : 'Город, адрес, метро, район, ж/д, шоссе или ЖК';
    }

    return redesign ? 'Адрес' : 'Город, адрес, метро, район или шоссе';
  };

  private suggest = debounce(300, async (valueRaw: string) => {
    const { currentLocation, offerType, httpApi, logger, boundedBy, dealType } = this.props;

    const value = valueRaw.trim();

    if (value) {
      this.setState({ loading: true });
      try {
        const result = await getMultiSuggest(
          httpApi,
          {
            structured: {
              offerType: getSuggestOfferType(offerType),
              query: value,
              regionId: getGeoSuggestLocation(currentLocation),
              dealType: DEAL_TYPE_SUGGEST_MAP[dealType],
              source: ESource.Serp,
            },
            yandex: {
              options: { boundedBy, results: YANDEX_RESULTS_MAX },
              value: 'Россия, ' + value,
            },
          },
          logger,
        );

        let error: IMessageInfo = {};
        if (this.state.value) {
          if (isEmpty(result.suggestions)) {
            trackGeoSuggestNotFound(value);
            error = NOT_FOUND;
          }
          this.setState({ suggestData: result as IMultiSuggestResult, loading: false, error });
        } else {
          this.setState({ suggestData: undefined, loading: false });
        }
      } catch (e) {
        this.setState({ loading: false, error: ERROR });
      }
    }
  });

  private onFocus = () => {
    const { value } = this.state;

    this.setState({ error: {} });
    trackGeoSuggestClick();
    if (value.length > 2) {
      this.suggest(value);
    }
  };

  private onBlur = () => {
    this.resetSuggest();
  };

  private onValueChange = (value: string) => {
    this.setState({ value });
    if (value.length > 2) {
      this.suggest(value);
    } else {
      this.setState({ loading: false, error: {}, suggestData: undefined });
    }
  };

  private resetSuggest() {
    this.setState({ value: '', selectedValue: '', error: {}, loading: false, suggestData: undefined });
  }

  private onItemClick = (params: IItemClickParams) => {
    const { id, title, group, regionId } = params;
    const { currentLocation } = this.props;
    //структурированный саджест
    if (id) {
      switch (group) {
        case ESuggestType.road:
          this.props.onHighwaySelected([id], regionId);
          break;
        case ESuggestType.village:
          this.props.onKPSelected({ id, name: title, regionId });
          break;
        case ESuggestType.builder:
          this.props.onBuilderSelected({ builderId: id, name: title });
          break;
        default: {
          const geo: IGeoObject = {
            id,
            text: title,
            type: geoTypeMapper(group),
            regionId: regionId || getRegionId(currentLocation),
          };
          this.props.onGeoSelected(geo, title);
        }
      }

      trackSelectGeo({ geoType: group, isYandexGeo: false });

      this.resetSuggest();
      //яндекс саджест
    } else {
      this.setState({
        loading: true,
        disabled: true,
      });

      this.geocode(title)
        .then(results => this.handleGeocodeResults(results, title, title))
        .catch(() => {
          this.setState({
            error: ERROR,
            loading: false,
            disabled: false,
          });
        });
    }
  };

  private async handleGeocodeResults(results: IGeocodeResult[] | void, value: string, userInput: string) {
    if (!results || results.length === 0) {
      this.props.logger.warning(`Empty geocode results for: ${value}`);

      this.setState({
        error: ERROR,
        loading: false,
        disabled: false,
      });

      return;
    }

    const geocodeResult = this.filterResults(results, value);
    const geocodeForSearchRequest = {
      text: geocodeResult.text,
      kind: geocodeResult.kind,
      coordinates: geocodeResult.coordinates,
    };

    try {
      const result = await geocodeForSearch(this.props.makeRequest, geocodeForSearchRequest);
      const objectDetails = result.details[result.details.length - 1];
      const regionInfo: IGeoDetail | undefined = getRegion(result);
      const district = getDistrict(result);

      const locationInfo: ILocation | undefined = regionInfo ? createLocationModel(regionInfo) : undefined;
      let geoModel: TGeoModel = {
        text: removeCountryName(result.details.map(item => item.name).join(', ')).trim(),
        coordinates: [result.geo.lng, result.geo.lat] as [number, number],
        locationId: regionInfo && regionInfo.id,
      };

      if (result.geoLocationCatalogLink) {
        geoModel = {
          ...geoModel,
          type: result.geoLocationCatalogLink.objectType.toLowerCase() as TGeoType,
          id: result.geoLocationCatalogLink.id,
        };
      } else {
        geoModel = {
          ...geoModel,
          // TODO: FUCK IT
          type: objectDetails.geoType.toLowerCase().replace('road', 'highway') as TGeoType,
          id: objectDetails.id,
          regionId: result.regionId,
          locationInfo,
          district,
          isParsed: result.isParsed,
        };
      }

      let type = geoModel.type as string;
      // TODO research it. Wait for CD-11447
      if (geoModel.type === 'location') {
        if (result.details.length > 1) {
          type = 'city';
        } else {
          type = 'region';
        }
      }

      trackSelectGeo({ geoType: type, isYandexGeo: true });

      if (!result.isParsed && !result.geoLocationCatalogLink) {
        const bounds = geocodeResult.boundedBy;
        (geoModel as IPolygonObject).polygon = [
          [bounds[0][0], bounds[0][1]],
          [bounds[0][0], bounds[1][1]],
          [bounds[1][0], bounds[1][1]],
          [bounds[1][0], bounds[0][1]],
          [bounds[0][0], bounds[0][1]],
        ];
      }

      this.props.onGeoSelected(geoModel as IGeoObject, userInput);
      this.resetSuggest();
      this.setState({
        loading: false,
        disabled: false,
      });
    } catch (error) {
      this.props.logger.warning(
        `Failed to geocode using 'geocodeForSearch' for: ${JSON.stringify(geocodeForSearchRequest)}`,
      );

      this.setState({
        error: ERROR,
        loading: false,
        disabled: false,
      });
    }
  }

  private onLinkClick = (title: string, group: TSuggestType) => {
    if (group === ESuggestType.newbuilding) {
      trackNewbuildingSuggestLinkClick();
    }
  };

  private geocode(value: string): Promise<IGeocodeResult[] | void> {
    return this.geocodeCached(value).catch(() => {
      return this.geocodeDirect(value);
    });
  }

  private geocodeCached(value: string): Promise<IGeocodeResult[] | void> {
    return geocodeCached(this.props.makeRequest, value).catch(() => {
      this.props.logger.warning(`Failed to geocode using 'geocodeCached' for: ${value}`);
    });
  }

  private geocodeDirect(value: string): Promise<IGeocodeResult[] | void> {
    return geocode(value)
      .then(result => {
        return result.geoObjects.toArray().map(geoObject => {
          return {
            text: geoObject.properties.get('text') as string,
            name: geoObject.properties.get('name') as string,
            kind: geoObject.properties.get('metaDataProperty.GeocoderMetaData.kind') as YMaps.TGeoKind,
            coordinates: (geoObject.geometry && geoObject.geometry.getCoordinates()) || [0, 0],
            boundedBy: geoObject.properties.get('boundedBy') as [YMaps.TCoord, YMaps.TCoord],
          };
        });
      })
      .catch(() => {
        this.props.logger.warning(`Failed to geocode using 'geocode' for: ${value}`);
      });
  }

  private filterResults(results: IGeocodeResult[], value: string) {
    if (results.length === 1) {
      return results[0];
    }

    const filteredResults = results.filter(result => {
      return result.text === value;
    });

    if (filteredResults.length !== 1) {
      this.props.logger.warning(`Failed to filter geocode results for: ${value}`);

      return results[0];
    }

    return filteredResults[0];
  }
}

function createLocationModel(detail: IGeoDetail) {
  return {
    ...detail.locationInfo,
    displayName: detail.fullName,
    name: detail.name,
    id: detail.id,
    fullName: detail.fullName,
  };
}

export function getDistrict(result: IGeocodeForSearchResponse) {
  if (result.isParsed) {
    if (result.microDistricts.length > 0) {
      return {
        name: result.microDistricts[0].fullName,
        id: result.microDistricts[0].id,
      };
    }

    const detail = result.details[result.details.length - 1];
    if (detail.geoType === 'District') {
      return {
        name: detail.fullName,
        id: detail.id,
      };
    }
  }

  return undefined;
}

export function getRegion(result: IGeocodeForSearchResponse) {
  return result.details.reduce((acc: IGeoDetail | undefined, value) => {
    return value.locationInfo ? value : acc;
  }, undefined);
}
