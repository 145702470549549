import { useMemo } from 'react';

import { TPromoSearchTypes } from '../../../../../../../shared/types/promoSearchTypes';
import { NonEmptyArray } from '../../../../../../JsonQuery';
import { nonEmptyArrayOr } from '../../../../../../JsonQuery/utils/nonEmptyArrayOr';
import { getPromoSearchFilterValuesByType } from '../../../../utils/getPromoSearchFilterValuesByType';

export const useDiscountPromoSearchTypeValues = (
  allValues: NonEmptyArray<string> | null,
  promoSearchTypes: TPromoSearchTypes,
) => {
  return useMemo(() => {
    if (!allValues || !promoSearchTypes) {
      return null;
    }

    const availableFilterValues = getPromoSearchFilterValuesByType('discount', promoSearchTypes);
    const filteredValues = allValues.filter(value => availableFilterValues.includes(value));

    return nonEmptyArrayOr(filteredValues);
  }, [allValues, promoSearchTypes]);
};
