import { Scroller, Slider, Image, Overlay, Buttons, PaginationFraction } from '@cian/slider';
import * as React from 'react';

import { Booked } from './components/Booked';
import { CianLayout } from './components/CianLayout';
import { VideoSlide } from './components/VideoSlide';
import { EMediaType, TMedia } from './types';

interface ICarouselProps {
  media: TMedia[];
  deoptimised?: boolean;
  bookedFromCian?: boolean;
  bookedFromDeveloper?: boolean;
  onSlideChange?(): void;
}

export const Carousel: React.FC<ICarouselProps> = ({
  media,
  deoptimised,
  bookedFromCian,
  bookedFromDeveloper,
  onSlideChange,
}) => {
  return (
    <Slider loop onSlideChange={onSlideChange}>
      <Scroller>
        {media.map((m, index) => {
          let slide: React.ReactNode = null;

          switch (m.type) {
            case EMediaType.Layout:
            case EMediaType.Photo:
              slide = (
                <Image
                  key={`slide_${index}`}
                  src={m.src}
                  priority={deoptimised && index === 0}
                  objectFit={m.type === EMediaType.Layout ? 'contain' : undefined}
                />
              );
              break;
            case EMediaType.Video:
              slide = <VideoSlide key={`slide_${index}`} {...m} />;
              break;
            case EMediaType.CianLayout:
              slide = <CianLayout key={`slide_${index}`} src={m.src} deoptimised={deoptimised && index === 0} />;
              break;
          }

          if ((bookedFromCian || bookedFromDeveloper) && index === 0) {
            return (
              <Booked key={`slide_${index}`} fromCian={bookedFromCian}>
                {slide}
              </Booked>
            );
          }

          return slide;
        })}
      </Scroller>
      <Overlay>
        <Buttons />
        <PaginationFraction />
      </Overlay>
    </Slider>
  );
};
