import { updateLayoutMeta } from '../../filters/state/meta';
import { makeSearch } from '../../filters/state/search';
import { prepareJsonQuery } from '../../services/fetchOffers';
import { fetchResidentialComplexLayoutsCountSafe } from '../../services/fetchResidentialComplexLayoutsCount';
import { TThunkAction } from '../../types/redux';
import { newbuildingLayoutsOff } from '../newbuildingLayouts';

export function getNewbuildingHousesOffers(): TThunkAction<Promise<void>> {
  return async (dispatch, getState, context) => {
    const { logger, config, httpApi } = context;
    const state = getState();
    const jsonQuery = state.filters.jsonQuery;
    const { currentLocation } = state.filters;

    try {
      const layoutsCountMeta = await fetchResidentialComplexLayoutsCountSafe(
        { httpApi, logger, config },
        { jsonQuery: prepareJsonQuery({ jsonQuery, location: currentLocation }) },
      );

      dispatch(updateLayoutMeta({ layoutsCountMeta: layoutsCountMeta.offersCount }));

      if (layoutsCountMeta.offersCount === 0) {
        dispatch(newbuildingLayoutsOff());
      }

      dispatch(makeSearch());
    } catch (error) {
      logger.error(error);
    }
  };
}
