import { Button, UIHeading1, UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';

interface IErrorScreenProps {
  onRetry(): void;
}

export const ErrorScreen = ({ onRetry }: IErrorScreenProps) => {
  return (
    <div className={style['container']}>
      <div className={style['container-inner']}>
        <div className={style['image']} />
        <div className={style['controls']}>
          <UIHeading1 textAlign="center">Что-то&nbsp;пошло не&nbsp;так</UIHeading1>
          <UIText1 textAlign="center">
            Проверьте интернет <br />
            или&nbsp;попробуйте обновить страницу
          </UIText1>
          <Button size="L" onClick={onRetry}>
            Обновить
          </Button>
        </div>
      </div>
    </div>
  );
};
