import React from 'react';

import styles from './ContactActionsLayout.css';

interface IContactActionsLayoutProps {
  phoneButton: React.ReactNode;
  electronicTradingButton: React.ReactNode;
  chatButton: React.ReactNode;
  showMoreOffersLink: React.ReactNode;
}

export function ContactActionsLayout({
  phoneButton,
  electronicTradingButton,
  chatButton,
  showMoreOffersLink,
}: IContactActionsLayoutProps) {
  return (
    <div className={styles['wrapper']}>
      {showMoreOffersLink}
      {phoneButton && <div className={styles['button']}>{phoneButton}</div>}
      {electronicTradingButton && <div className={styles['button']}>{electronicTradingButton}</div>}
      {chatButton && <div className={styles['button']}>{chatButton}</div>}
    </div>
  );
}
