import { addSavedOffer } from '@cian/favorites-assign-widget';

import {
  ENewbuildingLayoutOffersActionTypes,
  TNewbuildingLayoutOffersSetFavoritePayload,
  TSetFavoriteNewbuildingLayoutOffers,
} from '../../actions/newbuildingLayoutOffers/types';
import { IAppState, Dispatch } from '../../common/state';
import { showMotivationPopup } from '../../serp/state/login_motivation_popup';
import { actionGenerator } from '../../utils/redux';
import { IFavoriteChangeResponse } from '../api';

export const newbuildingLayoutsFavorite: (
  payload: TNewbuildingLayoutOffersSetFavoritePayload,
) => TSetFavoriteNewbuildingLayoutOffers = actionGenerator<
  ENewbuildingLayoutOffersActionTypes.SetFavorite,
  TNewbuildingLayoutOffersSetFavoritePayload
>(ENewbuildingLayoutOffersActionTypes.SetFavorite);

const FAVORITES_LIMIT_FOR_UNAUTH_USER = 5;

export function changeLayoutFavorite(offerId: number, isFavorite: boolean) {
  return (dispatch: Dispatch, getState: () => IAppState) => {
    const { user, loginMotivationPopupOnFavorite, makeRequest } = getState();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const currentCount = (window as any).__header_favorites_count__ || 0;

    if (isFavorite && !user.isAuthenticated && currentCount >= FAVORITES_LIMIT_FOR_UNAUTH_USER) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((window as any).__headerLoginPopupShowMotivation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__headerLoginPopupShowMotivation();
      }
    } else {
      dispatch(newbuildingLayoutsFavorite({ offerId, isFavorite }));

      if (isFavorite) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__header_favorites_count__ = currentCount + 1;

        if (!user.isAuthenticated && loginMotivationPopupOnFavorite.isActive) {
          dispatch(showMotivationPopup(offerId));
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).__header_favorites_count__ = currentCount - 1;
      }

      makeRequest({
        apiType: 'legacy',
        microserviceName: 'monolith-python',
        pathApi: '/ajax/sale/flat/favorites',
        parameters: {
          action: isFavorite ? 'assign' : 'remove',
          oid: offerId,
          addToFolder: isFavorite ? true : undefined,
        },
        headers: [['Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8']],
        bodyAsEncodeString: true,
        method: 'POST',
      }).then(
        (data: IFavoriteChangeResponse) => {
          if (isFavorite) {
            const userHasFolders = Boolean(data.folderInfo && data.folderInfo.hasFolders);
            const folder = data.folderInfo?.folder;

            let savedInFolders = null;
            if (folder?.name && folder?.id) {
              savedInFolders = [
                {
                  id: folder.id,
                  name: folder.name,
                },
              ];
            }

            dispatch(addSavedOffer({ offerIds: [offerId], savedInFolders, userHasFolders }));
          }

          if (data.newbuilding_id !== undefined && window.__header_updateFavorites__) {
            window.__header_updateFavorites__(true);
          }

          return window.__header_updateFavorites__ && window.__header_updateFavorites__(isFavorite);
        },
        () => {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (window as any).__header_favorites_count__ = currentCount - 1;
        },
      );
    }
  };
}
