import * as React from 'react';

import { NBSP } from '../../../../../../shared/constants/symbols';
import { FOfferType, getTermValue } from '../../../../../JsonQuery';
import { BlackFriday } from '../../../components/advancedFilters/BlackFriday';
import { Filter } from '../../../components/advancedFilters/common/Filter';
import { InlineFilter } from '../../../components/advancedFilters/common/InlineFilter';
import { InlineFilterGroup } from '../../../components/advancedFilters/common/InlineFilterGroup';
import { useContext } from '../../../utils/useContext';
import { useOfferType } from '../../../utils/useOfferType';

export const SpecialOffersContainer: React.FC = () => {
  const { jsonQuery, isNewbuildingBlackFridayActive, onChange } = useContext();
  const offerType = useOfferType();
  const isNewbuildingListing = offerType === FOfferType.FlatNew;

  const filterText = isNewbuildingListing ? 'Спецпредложения' : `Спецпредложения на${NBSP}новостройки`;
  const blackFridayText = isNewbuildingListing ? 'Только до 29 сентября' : 'Скидки и акции';
  const blackFridayLabel = isNewbuildingListing ? 'New' : 'до 29 сентября';

  const blackFridayValue = getTermValue('is_black_friday')(jsonQuery);

  const handleBlackFridayChange = React.useCallback(
    (isBlackFriday: boolean | null) => {
      onChange({ action: 'setBlackFriday', arguments: [isBlackFriday] });
    },
    [onChange],
  );

  if (!isNewbuildingBlackFridayActive) {
    return null;
  }

  return (
    <Filter id="advancedFilter_specialOffers" label={filterText}>
      <InlineFilterGroup>
        <InlineFilter>
          <BlackFriday
            text={blackFridayText}
            value={blackFridayValue}
            label={blackFridayLabel}
            onBlackFridayChange={handleBlackFridayChange}
          />
        </InlineFilter>
      </InlineFilterGroup>
    </Filter>
  );
};
