import * as React from 'react';

import { useDiscountPromoSearchTypeOptions, useDiscountPromoSearchTypeValues } from './hooks';
import { JsonQuery, NonEmptyArray, getTermsValue } from '../../../../../JsonQuery';
import { DiscountPromoSearchType } from '../../../components/advancedFilters/DiscountPromoSearchType';
import { getPromoSearchFilterValuesByType } from '../../../utils/getPromoSearchFilterValuesByType';
import { useContext } from '../../../utils/useContext';

export const DiscountPromoSearchTypeContainer = () => {
  const { jsonQuery, promoSearchTypes, onChange } = useContext();

  const allValues = getTermsValue('promo_search_types')(jsonQuery);
  const apartments = new JsonQuery(jsonQuery).getApartments();
  const discountValues = useDiscountPromoSearchTypeValues(allValues, promoSearchTypes);
  const discountOptions = useDiscountPromoSearchTypeOptions(promoSearchTypes, apartments);

  const handleChange = React.useCallback(
    (newValues: NonEmptyArray<string> | null) => {
      onChange({
        action: 'setPromoSearchTypes',
        arguments: [
          {
            allValues,
            newValues,
            availableFilterValues: getPromoSearchFilterValuesByType('discount', promoSearchTypes),
          },
        ],
      });
    },
    [onChange, allValues, promoSearchTypes],
  );

  if (!promoSearchTypes) {
    return null;
  }

  return <DiscountPromoSearchType options={discountOptions} values={discountValues} onChange={handleChange} />;
};
