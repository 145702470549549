import React, { CSSProperties } from 'react';

import styles from './styles.css';

interface IColoredIconWrapperProps {
  color?: string | null;
}

export const ColoredIconWrapper: React.FC<React.PropsWithChildren<IColoredIconWrapperProps>> = ({
  color,
  children,
}) => {
  const style: CSSProperties = {};

  if (color) {
    style.color = color;
  }

  return (
    <div className={styles['container']} style={style}>
      {children}
    </div>
  );
};
