import { type IHttpApi } from '@cian/http-api/shared';

import { assertUnknownError } from './assert';
import { mapDeleteOfferFromComparisonResponse, mapDeleteOfferFromComparisonResponseError } from './mappers';
import {
  fetchDeleteOfferFromComparison,
  IDeleteOfferFromComparisonRequest,
} from '../../../repositories/offers-comparison/v1/delete-offer-from-comparison';
import { IDeleteFromComparison } from '../../../types/offersComparison';

export async function deleteOfferFromComparisonRequest({
  httpApi,
  parameters,
}: {
  httpApi: IHttpApi;
  parameters: IDeleteOfferFromComparisonRequest;
}): Promise<IDeleteFromComparison> {
  const response = await fetchDeleteOfferFromComparison({
    httpApi,
    parameters,
    config: {},
    mappers: {
      200: mapDeleteOfferFromComparisonResponse,
      400: mapDeleteOfferFromComparisonResponseError,
    },
  });

  assertUnknownError(response);

  return response.response as IDeleteFromComparison;
}
