import { ca } from '@cian/analytics';

import { getDeveloperContactsLabel } from '../../serp/components/header/developers_contacts';

export function trackPhoneShow({ newbuildingId, action }: { newbuildingId: number; action: string }) {
  ca('eventEbc', {
    name: 'oldevent',
    category: 'Phones',
    action,
    label: getDeveloperContactsLabel(null),
    products: [{ id: newbuildingId, offerType: 'JK' }],
  });
}
