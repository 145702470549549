import { useEventCallback } from '@cian/react-utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCanPlayVideo } from './useCanPlayVideo';
import { useOfferContext } from '../../../../../../fragments/OfferCardWidget/shared/context';
import { selectJsonQuery } from '../../../../../selectors/results';
import { useApplicationContext } from '../../../../../utils/applicationContext';
import { TVideoType } from '../../../types';
import { trackVideoPlaying } from '../tracking';
import { EPlayerStatus } from '../types';

interface IParams {
  disableVideoPlay?: boolean;
  videoType: TVideoType;
}

export const usePlayerStatus = ({ videoType, disableVideoPlay }: IParams) => {
  const { logger } = useApplicationContext();

  const [status, setStatus] = useState<EPlayerStatus>(EPlayerStatus.PlayerNotMounted);

  const { canPlayVideo, resetCanPlayVideo } = useCanPlayVideo({ videoType, disableVideoPlay });

  const {
    position,
    offer: { id },
  } = useOfferContext();

  const jsonQuery = useSelector(selectJsonQuery);

  const setPlayerInitError = useCallback(
    (error: Error) => {
      setStatus(EPlayerStatus.PlayerInitError);
      resetCanPlayVideo();

      logger.error('Ошибка инициализации плеера для шортса', {
        name: error.name,
        message: error.message,
        searchParams: JSON.stringify(jsonQuery),
        offerId: id,
      });
    },
    [id, jsonQuery, logger, resetCanPlayVideo],
  );

  const setVideoReady = useEventCallback(() => {
    setStatus(EPlayerStatus.VideoReady);
  });

  const setVideoPlaying = useEventCallback(() => {
    setStatus(EPlayerStatus.VideoPlaying);
    trackVideoPlaying({ videoType, offerId: id, position });
  });

  useEffect(() => {
    if (!status && canPlayVideo) {
      // Этап инициализации плеера
      setStatus(EPlayerStatus.PlayerInitStart);
    } else if (status === EPlayerStatus.VideoPlaying && !canPlayVideo) {
      // Видео проигрывается, но карточка ушла из вьюпорта или видео закончилось
      setStatus(EPlayerStatus.PlayerReady);
    } else if (status === EPlayerStatus.PlayerReady && canPlayVideo) {
      // Видео попало во вьюпорт, но плеер уже проинициализирован
      setVideoReady();
    }
  }, [canPlayVideo, id, position, setVideoReady, status, videoType]);

  return useMemo(
    () => ({ status, setPlayerInitError, setVideoReady, setVideoPlaying, setVideoEnded: resetCanPlayVideo }),
    [resetCanPlayVideo, setPlayerInitError, setVideoPlaying, setVideoReady, status],
  );
};
