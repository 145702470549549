import { UIText1 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';
import { NBSP } from '../../../../../constants/symbols';

export interface IRealtorsFooterProps {
  link: string;
  onLinkClick(): void;
}

export const RealtorsFooter: React.VFC<IRealtorsFooterProps> = ({ link, onLinkClick }) => {
  return (
    <div className={styles['footer']} data-name="download_excel_realtors_footer">
      <UIText1>Если нужно больше возможностей, воспользуйтесь сервисом Аналитика для{NBSP}бизнеса</UIText1>
      <a href={link} className={styles['link']} target="_blank" rel="noreferrer" onClick={onLinkClick}>
        <UIText1 color="text-main-default" fontWeight="bold">
          Подробнее
        </UIText1>
      </a>
    </div>
  );
};
