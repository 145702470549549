import { IconMapCar16 } from '@cian/ui-kit-design-tokens/icons';
import React, { useMemo, useCallback, MouseEvent } from 'react';

import { SpecialGeo } from '../../../components/SpecialGeo';
import { useOfferContext } from '../../../context';

export function HighwayContainer() {
  const { highway, isMoscow, tracking, qsToUris, isSpb } = useOfferContext();

  const url = useMemo(() => {
    if (highway) {
      return (qsToUris && qsToUris[highway.qs]) || `/cat.php?${highway.qs}`;
    }

    return '';
  }, [qsToUris, highway]);

  const remoteness = useMemo(() => {
    if (highway && highway.distance) {
      const distance = Math.round(Number(highway.distance));

      if (isMoscow) {
        return `${distance} км от МКАД`;
      }
      if (isSpb) {
        return `${distance} км от КАД`;
      }
    }

    return undefined;
  }, [highway, isMoscow, isSpb]);

  const handleUndergroundClick = useCallback(
    (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      tracking.trackAddressClick('highway');
    },
    [tracking],
  );

  if (!highway) {
    return null;
  }

  return (
    <SpecialGeo
      url={url}
      label={`${highway.name} шоссе`}
      icon={<IconMapCar16 color="icon-secondary-default" />}
      remoteness={remoteness}
      onClick={handleUndergroundClick}
    />
  );
}
