import { IBuilder } from './builder';
import { INewbuildingLayoutOffer } from './offer';

export interface INewbuildingLayoutWithOffers {
  /** Диапазон площадей **/
  areaRangeText: string;
  /** Застройщик **/
  builder?: IBuilder | null;
  /** Параметры для аналитики **/
  gaLabel: string;
  /** Полная ссылка на планировку **/
  imageUrl?: string | null;
  /** Объект параметров запроса в строковом представлении **/
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jsonQuery: any;
  /** Список офферов **/
  offers: INewbuildingLayoutOffer[];
  /** Количество офферов **/
  offersCount: number;
  /** Количество офферов в текстовом представлении **/
  offersCountText: string;
  /** Диапазон цен за м² **/
  pricePerMeterRangeText: string;
  /** Диапазон цен **/
  priceRangeText: string;
  /** Комнатность **/
  roomType: string;
  /** Описание **/
  title: string;
  /** Описание предложений в формате: 2-комн. кв., от 42.7 м² **/
  tourAreaText: string;
  /** Цена в формате: от 16 432 800 ₽ **/
  tourPriceText: string;
  /** Ссылка на 3Д тур **/
  tourUrl?: string | null;
  /** Текст ссылки на все предложения **/
  offersLinkText: string;
  /** Ссылка на все предложения **/
  offersLinkUrl: string;
}

export enum ENewbuildingLayoutFetchStatus {
  Initial = 'initial',
  Error = 'error',
  Loading = 'loading',
  Done = 'done',
}

export interface INewbuildingLayoutWithOffersState {
  fetchStatus: ENewbuildingLayoutFetchStatus;
  data: INewbuildingLayoutWithOffers | null;
}
