import * as React from 'react';
import { useSelector } from 'react-redux';

import { PaginationButtonsContainer } from './PaginationButtonsContainer';
import { PaginationContainer } from './PaginationContainer';
import { PaginationSection } from '../../components/Pagination';
import { getOffers } from '../../selectors/offers';

export function PaginationSectionContainer() {
  const offers = useSelector(getOffers);

  if (!offers.length) {
    return null;
  }

  return <PaginationSection pagination={<PaginationContainer />} buttons={<PaginationButtonsContainer />} />;
}
