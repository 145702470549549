import { useEventCallback } from '@cian/react-utils';
import * as React from 'react';

import { useDeferredSlideStatus } from './useDeferredSlideStatus';
import { useCardInViewContext } from '../../../../../../fragments/OfferCardWidget/shared/context/CardInViewContext';
import { TVideoType } from '../../../types';
import { PLAYABLE_VIDEO_TYPES } from '../constants';

interface IParams {
  videoType: TVideoType;
  disableVideoPlay?: boolean;
}

/**
 * Хук управляет возможностью воспроизведения видео
 */
export const useCanPlayVideo = ({ videoType, disableVideoPlay }: IParams) => {
  const cardInView = useCardInViewContext();

  const isSlideActive = useDeferredSlideStatus();

  const [canPlayVideo, setCanPlayVideo] = React.useState(false);

  React.useEffect(() => {
    if (!disableVideoPlay) {
      setCanPlayVideo(cardInView && isSlideActive && PLAYABLE_VIDEO_TYPES.includes(videoType));
    }
  }, [cardInView, isSlideActive, videoType, disableVideoPlay]);

  const resetCanPlayVideo = useEventCallback(() => {
    setCanPlayVideo(false);
  });

  return React.useMemo(() => ({ canPlayVideo, resetCanPlayVideo }), [canPlayVideo, resetCanPlayVideo]);
};
