import {
  IconProductHighDemand16,
  IconProductCianLogo12,
  IconProductTrust16,
  IconFinancePercent12,
  IconProductHotLeads16,
  IconComfortArmchair16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import boltIcon from './assets/bolt.svg';
import { ELabelColor, TLabelColor } from '../../types/label';

export const labelIconMap: Record<ELabelColor, React.ReactElement | undefined> = {
  [ELabelColor.Red]: undefined,
  [ELabelColor.Green]: undefined,
  [ELabelColor.DarkGreen]: undefined,
  [ELabelColor.Blue]: <IconProductCianLogo12 color="icon-main-default" />,
  [ELabelColor.Gray]: undefined,
  [ELabelColor.DarkGray]: undefined,
  [ELabelColor.Yellow]: undefined,
  [ELabelColor.OrangePercent]: <IconFinancePercent12 />,
  [ELabelColor.SimpleBlue]: undefined,
  [ELabelColor.SurfaceBlueIconed]: <IconProductHotLeads16 color="icon-main-default" />,
  [ELabelColor.SurfaceBlue]: undefined,
  [ELabelColor.BlackBlueGradient]: undefined,
  [ELabelColor.BlueVerifiedByCian]: <IconProductTrust16 color="icon-main-default" />,
  [ELabelColor.BlueInstantBooking]: <img src={boltIcon} alt="" />,
  [ELabelColor.Purple]: <IconProductHighDemand16 />,
  [ELabelColor.Orange]: <IconComfortArmchair16 color="icon-warning-default" />,
};

export function mapLabelIcon(color: TLabelColor): React.ReactElement | undefined {
  return labelIconMap[color];
}
