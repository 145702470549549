/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetDeveloperInfoModel,
  IGetDeveloperInfoRequest,
  IMappers,
  IGetDeveloperInfoResponse,
  IGetDeveloperInfoResponseNotFoundError,
  IGetDeveloperInfoResponseError,
  TGetDeveloperInfoResponse,
} from './types';

const defaultConfig: TGetDeveloperInfoModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400, 404],
  method: 'GET',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/get-developer-info/',
  hostType: 'api',
} as TGetDeveloperInfoModel;

function createGetDeveloperInfoModel(parameters: IGetDeveloperInfoRequest): TGetDeveloperInfoModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetDeveloperInfoOptions<TResponse200, TResponse400, TResponse404> {
  httpApi: IHttpApi;
  parameters: IGetDeveloperInfoRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400, TResponse404>;
}

async function fetchGetDeveloperInfo<TResponse200, TResponse400, TResponse404>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetDeveloperInfoOptions<TResponse200, TResponse400, TResponse404>): Promise<
  TResponse200 | TResponse400 | TResponse404 | TGetDeveloperInfoResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetDeveloperInfoModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetDeveloperInfoResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetDeveloperInfoResponseError);
    }

    if (statusCode === 404) {
      return mappers[404](response as IGetDeveloperInfoResponseNotFoundError);
    }
  }

  return { response, statusCode } as TGetDeveloperInfoResponse;
}

export { defaultConfig, createGetDeveloperInfoModel, fetchGetDeveloperInfo };
