export enum ELabelColor {
  Gray = 'gray',
  DarkGray = 'dark-gray',
  Green = 'green',
  DarkGreen = 'darkGreen',
  Red = 'red',
  Yellow = 'yellow',
  /** Оранжевый с иконкой процента */
  OrangePercent = 'orangePercent',
  /** Синий с иконкой Циан */
  Blue = 'blue',
  /** Синий без иконки Циан */
  SimpleBlue = 'simpleBlue',
  SurfaceBlueIconed = 'surfaceBlueIconed',
  SurfaceBlue = 'surfaceBlue',
  BlackBlueGradient = 'blackBlueGradient',
  BlueVerifiedByCian = 'blueVerifiedByCian',
  BlueInstantBooking = 'blueInstantBooking',
  /** Фиолетовый с иконкой */
  Purple = 'purple',
  Orange = 'orange',
}

export type TLabelColor = `${ELabelColor}`;

export interface ILabelTooltip {
  description: React.ReactNode;
  title?: string;
  link?: string;
  linkText?: string;
  onOpen?: () => void;
}

export interface ILabel {
  color: ELabelColor;
  text: string;
  tooltip?: ILabelTooltip;
}
