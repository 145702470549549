import { TAdFoxBannerTemplate } from '@cian/adfox-component';
import { TTgbNativedescGeneralContext } from '@cian/adfox-templates';
import { Base64 } from 'js-base64';
import * as React from 'react';

import { IMortgageBannerContext, INewbuildingSpecialProjectBannerContext } from '../../../../../types/adfox';
import { useApplicationContext } from '../../../../../utils/applicationContext';
import { AdFoxBannerTrackerContainer } from '../AdFoxBannerTracker';
import { TAdFoxBannerTrackerProps } from '../AdFoxBannerTracker/AdFoxBannerTracker';
import { NewbuildingSpecialProjectContainer } from '../templates/NewbuildingSpecialProject';
import { TgbNativedescGeneralContainer } from '../templates/TgbNativedescGeneral';
import { MortgageSimpleBannerContainer } from '../templates/mortgage';

const OWN_BUNDLE_NAMES = ['banner.transfer'];
const OWN_TYPES: Array<BannerContext['type']> = [
  'mortgage_banner_new',
  'special_project_banner',
  'tgb_nativedesc_general',
];

export interface IAdFoxTemplateParams {
  type: string;
}

type BannerContext = IMortgageBannerContext | INewbuildingSpecialProjectBannerContext | TTgbNativedescGeneralContext;

export type TAdFoxTemplatesParams = TAdFoxBannerTrackerProps & IAdFoxTemplateParams & BannerContext;

export const OwnTemplates: React.FC<TAdFoxBannerTemplate> = props => {
  const ctx = useApplicationContext();
  const { bundleName, bundleParams } = props;

  if (bundleName && OWN_BUNDLE_NAMES.includes(bundleName) && bundleParams) {
    if (bundleName === 'banner.transfer' && bundleParams.htmlBase64) {
      try {
        const decodedParams = Base64.decode(bundleParams.htmlBase64);
        const parsedDecodedParams = JSON.parse(decodedParams);

        if (isParsedParamsValid(parsedDecodedParams)) {
          if (OWN_TYPES.includes(parsedDecodedParams.type)) {
            switch (parsedDecodedParams.type) {
              case 'special_project_banner':
                return (
                  <NewbuildingSpecialProjectContainer
                    context={{
                      ...parsedDecodedParams,
                      campaignId: bundleParams.campaignId,
                      bannerId: bundleParams.bannerId,
                    }}
                  />
                );
              case 'mortgage_banner_new': {
                const context = {
                  text: parsedDecodedParams.text,
                  reference: parsedDecodedParams.url,
                  label: parsedDecodedParams.label,
                  annotation: parsedDecodedParams.annotation,
                  withReference: parsedDecodedParams.withReference,
                };

                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                return <MortgageSimpleBannerContainer context={context as any} />;
              }

              case 'tgb_nativedesc_general':
                return <TgbNativedescGeneralContainer context={parsedDecodedParams} />;
            }
          }
        }
      } catch (e) {
        ctx.logger.error(e, {
          message: '[AdFox] Failed to decode/parse bundleParams',
          bannerId: props.bundleParams?.bannerId,
        });
      }
    }
  }

  return <AdFoxBannerTrackerContainer {...props} />;
};

function isParsedParamsValid(params: object): params is TAdFoxTemplatesParams {
  return 'type' in params;
}
