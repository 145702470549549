import { UIText2 } from '@cian/ui-kit';
import * as React from 'react';

import * as style from './style.css';
import { HEADER_ITEMS } from '../OffersTable';

export const SkeletonTableHead = () => {
  return (
    <div className={style['table-header']}>
      {HEADER_ITEMS.map((item, index) => (
        <div className={style['header-cell']} key={index}>
          <UIText2 color="current_color" textAlign={index === 3 ? 'right' : 'left'}>
            {item}
          </UIText2>
        </div>
      ))}
    </div>
  );
};
