import { ca } from '@cian/analytics';

export const trackApplyDatesModal = () => {
  ca('eventSite', {
    name: 'oldevent',
    action: 'click',
    category: 'DailyrentCalendar',
    label: 'ConfirmDates',
  });
};
