import * as React from 'react';

import * as style from './style.css';

export const SkeletonHeader = () => {
  return (
    <div className={style['header']}>
      <div className={style['image']} />
      <div className={style['info-block']}>
        <div className={style['layout']}>
          <div className={style['layout-params']}>
            <div className={style['layout-title']} />
            <div className={style['layout-square']} />
          </div>
          <div className={style['layout-price']}>
            <div className={style['price']} />
            <div className={style['price-per-meter']} />
          </div>
        </div>
        <div className={style['contacts']}>
          <div className={style['contacts-contact']}>
            <div className={style['contact-image']} />
            <div className={style['contacts-contact-title']}>
              <div className={style['contact-title-type']} />
              <div className={style['contact-title-name']} />
            </div>
          </div>
          <div className={style['contacts-control']} />
        </div>
      </div>
    </div>
  );
};
