import { getGeoValueByType, getTermValue } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';
import { EBuildingStatus } from '../../repositories/common/json_query';

export function getIsHousesFilterEnabled({ filters }: IAppState): boolean {
  const isFromDeveloper = getTermValue('from_developer')(filters.jsonQuery);
  const isNewobject = getGeoValueByType('newobject')(filters.jsonQuery);
  const buildingStatus = getTermValue('building_status')(filters.jsonQuery);

  return Boolean(buildingStatus === EBuildingStatus.New || (isFromDeveloper && isNewobject));
}
