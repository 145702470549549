import { FDealType, FOfferType, isAvailable, isAvailableStrict } from '../../../../JsonQuery';
import { SubsidisedMortgageContainer } from '../../containers/advancedFilters/SubsidisedMortgageContainer';
import { IFilter } from '../../types/advancedFilters';
import { SUBSIDIZED_MORTGAGE_ENABLED_KEY } from '../config';

export const subsidisedMortgage: IFilter = {
  component: SubsidisedMortgageContainer,
  availability: ({ config, jsonQuery }) => {
    // TODO: Удалить развилку в CD-217337.
    const isMixedListingEnabled = config.get('newbuildingPromoSearchTypes.mixedListingEnabled') ?? false;
    const availableFunction = isMixedListingEnabled ? isAvailableStrict : isAvailable;

    return (
      Boolean(config.get<boolean>(SUBSIDIZED_MORTGAGE_ENABLED_KEY)) &&
      availableFunction(FDealType.Sale, FOfferType.FlatOld)(jsonQuery)
    );
  },
};
