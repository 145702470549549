import { FDealType, FOfferType, isAvailable, isAvailableStrict } from '../../../../JsonQuery';
import { MortgagePromoSearchTypeContainer } from '../../containers/advancedFilters/MortgagePromoSearchTypeContainer';
import { IFilter } from '../../types/advancedFilters';

export const mortgagePromoSearchType: IFilter = {
  component: MortgagePromoSearchTypeContainer,
  availability: ({ config, jsonQuery }) => {
    // TODO: Удалить развилку в CD-217337.
    const isMixedListingEnabled = config.get('newbuildingPromoSearchTypes.mixedListingEnabled') ?? false;
    const availableFunction = isMixedListingEnabled ? isAvailable : isAvailableStrict;

    return availableFunction(FDealType.Sale, FOfferType.FlatNew)(jsonQuery);
  },
};
