import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackSubsidisedMortgageZeroPlaceBannerClick, trackSubsidisedMortgageZeroPlaceBannerShow } from './tracking';
import { getRangeValue } from '../../../../packages/JsonQuery';
import { selectRegionId } from '../../../selectors/filters/selectRegionId';
import { selectJsonQuery } from '../../../selectors/results';
import { useApplicationContext } from '../../../utils/applicationContext';
import { SubsidisedMortgageZeroPlaceBanner } from '../../components/SubsidisedMortgageZeroPlaceBanner';

type TZeroPlaceBannerInfo = {
  title: string;
  subtitle: string;
  image: string;
  buttonText: string;
  promoUrl: string;
};

export const SubsidisedMortgageZeroPlaceBannerContainer: React.FC = () => {
  const { config } = useApplicationContext();

  const regionId = useSelector(selectRegionId);
  const jsonQuery = useSelector(selectJsonQuery);

  const bannerInfo = config.get<TZeroPlaceBannerInfo>('subsidizedMortgage.desktopSerpZeroPlaceBannerInfo');

  const handleShow = React.useCallback(() => {
    trackSubsidisedMortgageZeroPlaceBannerShow();
  }, []);

  const handleClick = React.useCallback(() => {
    trackSubsidisedMortgageZeroPlaceBannerClick();
  }, []);

  const url = React.useMemo(() => {
    /* istanbul ignore next */
    if (!bannerInfo) {
      return '';
    }

    const url = new URL(bannerInfo.promoUrl);
    const price = getRangeValue('price')(jsonQuery);

    /* istanbul ignore next */
    if (price?.lte) {
      url.searchParams.set('price', String(price.lte));
    }

    url.searchParams.set('region-id', String(regionId));

    return url.toString();
  }, [bannerInfo, regionId, jsonQuery]);

  /* istanbul ignore next */
  if (!bannerInfo) {
    return null;
  }

  const { title, subtitle, image, buttonText } = bannerInfo;

  return (
    <SubsidisedMortgageZeroPlaceBanner
      buttonText={buttonText}
      image={image}
      onClick={handleClick}
      onShow={handleShow}
      subtitle={subtitle}
      title={title}
      url={url}
    />
  );
};
