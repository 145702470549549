import { Button, FormFieldInput, ModalWindow } from '@cian/ui-kit';
import { IconActionPlus16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import * as React from 'react';

import * as styles from './AuctionReduceBetModal.css';
import { trackAuctionReduceBetPopupShow } from '../../context/AuctionContext/utils/tracking';
import { IBetFormValues } from '../../types/auction';

interface IAuctionReduceBetModalProps {
  currentBet: number;
  open: boolean;
  formik: FormikProps<IBetFormValues>;
  incrementBet(): void;
  onClose(): void;
}

export const AuctionReduceBetModal: React.FC<IAuctionReduceBetModalProps> = ({
  open,
  formik,
  currentBet,
  incrementBet,
  onClose,
}) => {
  const handleSubmit = async () => {
    if (!formik.isSubmitting) {
      await formik.submitForm();
      onClose();
    }
  };

  React.useEffect(() => {
    if (open) {
      trackAuctionReduceBetPopupShow();
    }
  }, [open]);

  return (
    <ModalWindow
      size="M"
      fixed={false}
      title="Вы понижаете ставку!"
      maxWidth={430}
      open={open}
      onClose={onClose}
      content={
        <>
          <div className={styles['input-row']}>
            <div className={styles['input-label']}>Действующая ставка</div>
            <div className={styles['input-max_bet']}>{currentBet}&nbsp;₽ в сутки</div>
          </div>

          <div className={styles['input-row']}>
            <div className={styles['input-label']}>Новая ставка</div>

            <FormFieldInput
              id="bet"
              name="bet"
              type="number"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={`${formik.values.bet}`}
              leftAdornment="₽"
              rightAdornment={<IconActionPlus16 cursor="pointer" color="icon-main-default" onClick={incrementBet} />}
              errorMessage={formik.errors.bet}
              invalid={!!formik.errors.bet}
              disabled={formik.isSubmitting}
            />
          </div>

          <div className={classNames(styles['buttons'], styles['buttons-right'])}>
            <Button type="button" size="XS" theme="fill_white_primary" onClick={onClose}>
              Отменить
            </Button>
            <Button
              theme="fill_primary"
              size="XS"
              disabled={!!formik.errors.bet || formik.isSubmitting}
              onClick={handleSubmit}
              loading={formik.isSubmitting}
            >
              Применить
            </Button>
          </div>
        </>
      }
    />
  );
};
