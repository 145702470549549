import { type IConfig } from '@cian/config/shared';

import { dealTypeFromJsonQuery, FDealType } from './category';
import { tempNormalizeLocation } from './geo';
import { MOSCOW_AND_REGION } from '../../packages/Filters/shared/constants/regions';
import { TJsonQuery } from '../../packages/JsonQuery';
import { IAbUseExperiment } from '../types/abUseExperiments';
import { TLocation } from '../types/location';

type Params = {
  jsonQuery: TJsonQuery;
  config: IConfig;
  experiments: IAbUseExperiment[];
  location: TLocation;
};

export function isHybridSearchRedirectNeeded({ jsonQuery, config, experiments, location }: Params): boolean {
  const excludedRegions = config.get<number[]>('BX.HybridSearch.ExcludedRegions') ?? MOSCOW_AND_REGION;
  const expName = config.get<string>('BX.HybridSearch.ExperimentName');
  const isFeatureEnabled = config.get<boolean>('BX.HybridSearch.Enabled') ?? false;

  const dealType = dealTypeFromJsonQuery(jsonQuery);
  const isValidDealType = dealType === FDealType.RentLongterm;

  const regionIds = tempNormalizeLocation(location).map(location => location.id);
  const isValidRegion = !regionIds.some(regionId => excludedRegions.includes(regionId));

  const isExperimentEnabled: boolean =
    !expName || experiments.some(experiment => experiment.experimentName === expName && experiment.groupName === 'B');

  return isFeatureEnabled && isValidDealType && isValidRegion && isExperimentEnabled;
}
