import {
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroMsk16,
  IconMapMetroNN16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroSpb16,
} from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './styles.css';
import { ColoredIconWrapper } from '../../../../common/components/ColoredIconWrapper';
import { ECityType } from '../../../../types/newbuildingPromoBuilderOffers';

interface IUndergroundIconProps {
  lineColor: string;
  undergroundCityType: ECityType;
}

const componentMap = {
  [ECityType.Msk]: IconMapMetroMsk16,
  [ECityType.Spb]: IconMapMetroSpb16,
  [ECityType.Samara]: IconMapMetroSam16,
  [ECityType.Ekb]: IconMapMetroEkb16,
  [ECityType.Kazan]: IconMapMetroKzn16,
  [ECityType.Nizhniy]: IconMapMetroNN16,
  [ECityType.Novosib]: IconMapMetroNovosib16,
};

export const UndergroundIcon = (props: IUndergroundIconProps) => {
  const { undergroundCityType, lineColor } = props;

  const Icon = componentMap[undergroundCityType];

  if (Icon) {
    return (
      <div className={styles['underground-icon']} data-testid="underground_icon" style={{ color: `#${lineColor}` }}>
        <ColoredIconWrapper color={lineColor}>
          <Icon data-testid={`underground_icon_${undergroundCityType}`} />
        </ColoredIconWrapper>
      </div>
    );
  }

  return (
    <div
      className={styles['underground-icon--regions']}
      data-testid="underground_with_custom_icon"
      style={{ color: `#${lineColor}` }}
    />
  );
};
