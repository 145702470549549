export enum ENewbuildingCallPlaceType {
  NewbuildingHeaderInSerp = 'NewbuildingHeaderInSerp',
  NewbuildingHeaderInSerpFullscreenGallery = 'NewbuildingHeaderInSerpFullscreenGallery',
  NewbuildingOpenLayoutModalBlock = 'OpenLayoutModalBlock',
}

export type TNewbuildingCallPlaceType =
  | ENewbuildingCallPlaceType.NewbuildingHeaderInSerp
  | ENewbuildingCallPlaceType.NewbuildingHeaderInSerpFullscreenGallery
  | ENewbuildingCallPlaceType.NewbuildingOpenLayoutModalBlock;
