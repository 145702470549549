/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TGetNewbuildingLayoutsCountModel,
  IGetNewbuildingLayoutsCountRequest,
  IMappers,
  IGetNewbuildingLayoutsCountResponse,
  IGetNewbuildingLayoutsCountResponseError,
  TGetNewbuildingLayoutsCountResponse,
} from './types';

const defaultConfig: TGetNewbuildingLayoutsCountModel = {
  apiType: 'public',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'search-offers-index',
  pathApi: '/v1/get-newbuilding-layouts-count/',
  hostType: 'api',
} as TGetNewbuildingLayoutsCountModel;

function createGetNewbuildingLayoutsCountModel(
  parameters: IGetNewbuildingLayoutsCountRequest,
): TGetNewbuildingLayoutsCountModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IGetNewbuildingLayoutsCountOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IGetNewbuildingLayoutsCountRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchGetNewbuildingLayoutsCount<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IGetNewbuildingLayoutsCountOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TGetNewbuildingLayoutsCountResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetNewbuildingLayoutsCountModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IGetNewbuildingLayoutsCountResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IGetNewbuildingLayoutsCountResponseError);
    }
  }

  return { response, statusCode } as TGetNewbuildingLayoutsCountResponse;
}

export { defaultConfig, createGetNewbuildingLayoutsCountModel, fetchGetNewbuildingLayoutsCount };
