import * as React from 'react';
import { useSelector } from 'react-redux';

import { NewbuildingChatPopupContainer } from './NewbuildingChatPopupContainer';
import { OfferChatPopupContainer } from './OfferChatPopupContainer';
import { selectChat } from '../../selectors/selectChat';

export const ChatPopupContainer: React.FC = () => {
  const { newbuilding } = useSelector(selectChat);

  return newbuilding ? <NewbuildingChatPopupContainer /> : <OfferChatPopupContainer />;
};
