import * as React from 'react';

import styles from './style.css';
import { ISeoLinksTab } from '../../../types/seoTabsLinks';

interface ISeoFullListHiddenProps {
  tabs: ISeoLinksTab[] | null;
}

/* Компонент необходим для правильной индексации ссылок поисковым ботом */
export function SeoFullListHidden({ tabs }: ISeoFullListHiddenProps) {
  return (
    <div className={styles['full-list-hidden']}>
      {tabs?.map(tab => (
        <ul key={tab.title}>
          {tab.links.map(link => (
            <li key={link.title}>
              <a href={link.url} target="_blank" rel="noreferrer">
                {link.title}
              </a>
            </li>
          ))}
        </ul>
      )) || null}
    </div>
  );
}
