import * as React from 'react';
import { useSelector } from 'react-redux';

import { getSeoTabsLinks } from '../../../selectors/getSeoTabsLinks/getSeoTabsLinks';
import { SeoTabsLinks } from '../../components/SeoTabsLinks';

export const SeoTabsLinksContainer = () => {
  const tabs = useSelector(getSeoTabsLinks);

  if (tabs.length === 0) {
    return null;
  }

  return <SeoTabsLinks tabs={tabs} />;
};
