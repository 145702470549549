/* eslint-disable */
//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  IGetModerationComplainMicrofrontendRequest,
  IMappers,
  TGetModerationComplainMicrofrontendModel,
  TGetModerationComplainMicrofrontendResponse,
} from './types';
import { IMicrofrontendManifest } from '@cian/mf-registry/shared';

const defaultConfig = {
  apiType: 'public',
  assertStatusCodes: [200],
  method: 'GET',
  microserviceName: 'moderation-complain-frontend',
  pathApi: '/v1/get-moderation-complain-microfrontend/',
  hostType: 'api',
  requestType: 'json',
  responseType: 'json',
} as TGetModerationComplainMicrofrontendModel;

function createGetModerationComplainMicrofrontendModel(): TGetModerationComplainMicrofrontendModel {
  return {
    ...defaultConfig,
  };
}

export interface IGetModerationComplainMicrofrontendOptions<TResponse200> {
  httpApi: IHttpApi;
  parameters?: IGetModerationComplainMicrofrontendRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200>;
}

async function fetchGetModerationComplainMicrofrontend<TResponse200>({
  httpApi,
  config,
  mappers,
}: IGetModerationComplainMicrofrontendOptions<TResponse200>): Promise<
  TResponse200 | TGetModerationComplainMicrofrontendResponse
> {
  const { response, statusCode } = await httpApi.fetch(createGetModerationComplainMicrofrontendModel(), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response.manifest as IMicrofrontendManifest);
    }
  }

  return { response, statusCode } as TGetModerationComplainMicrofrontendResponse;
}

export { defaultConfig, createGetModerationComplainMicrofrontendModel, fetchGetModerationComplainMicrofrontend };
