import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getDefaultMessage, getFirstBuilder, getNewbuildingChatSrc } from './utils';
import { closeChat } from '../../../actions/chat';
import { ChatPopup } from '../../../components/ChatPopup';
import { selectNewbuildingLayoutDetailsModalOpen } from '../../../selectors/newbuildingLayouts';
import { selectChat } from '../../../selectors/selectChat';
import { getUser } from '../../../selectors/user';

export const NewbuildingChatPopupContainer: React.FC = () => {
  const dispatch = useDispatch();

  const { isOpened, newbuilding } = useSelector(selectChat);
  const user = useSelector(getUser);
  const builder = getFirstBuilder(newbuilding);
  const isLayoutDetailsModalOpen = useSelector(selectNewbuildingLayoutDetailsModalOpen);

  const isOwnNewbuilding = user.isAuthenticated && builder?.realtyUserId === user.userId;

  const onClose = React.useCallback(() => dispatch(closeChat()), [dispatch]);

  if (!isOpened || isOwnNewbuilding) {
    return null;
  }

  const defaultMessage = getDefaultMessage(newbuilding);
  const src = getNewbuildingChatSrc(builder?.realtyUserId as number, defaultMessage);

  return <ChatPopup src={src} onClose={onClose} aboveModal={isLayoutDetailsModalOpen} />;
};
