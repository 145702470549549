import { InlineText2 } from '@cian/ui-kit/typography';
import React from 'react';

import { Thumbnail } from './Thumbnail';
import styles from './Thumbnails.css';
import { TMedia } from '../../../../../shared/components/Carousel';

interface IThumbnailsProps {
  media: Array<TMedia | null>;
  hasMore?: boolean;
}

export function Thumbnails({ media, hasMore }: IThumbnailsProps) {
  return (
    <div className={styles['wrapper']}>
      {media.map((thumbnail, i) => (
        <Thumbnail key={`thumbnail-${i}`} media={thumbnail}>
          {hasMore && i === media.length - 1 && (
            <div className={styles['overlay']}>
              <InlineText2 color="text-inverted-default">Ещё фото</InlineText2>
            </div>
          )}
        </Thumbnail>
      ))}
    </div>
  );
}
