import { APARTMENTS_VALUE, DISCOUNTS_BY_PROPERTY_VALUE } from '../constants/promoSearchTypes';

export function getAllowedPromoSearchTypes<T extends string[] | null>(apartments: boolean | null, promoSearchTypes: T) {
  if (!promoSearchTypes || apartments === null) {
    return promoSearchTypes;
  }

  if (apartments) {
    return promoSearchTypes.filter(searchType => searchType !== DISCOUNTS_BY_PROPERTY_VALUE);
  }

  return promoSearchTypes.filter(searchType => searchType !== APARTMENTS_VALUE);
}
