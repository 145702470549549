import { ENewbuildingLayoutDetailsModalActionTypes } from '../../actions/newbuildingLayoutDetails';
import { IAppState, TActions } from '../../common/state';

export function newbuildingLayoutDetailsModalReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ENewbuildingLayoutDetailsModalActionTypes.Open: {
      return {
        ...state,
        newbuildingLayoutDetailsModal: {
          open: true,
        },
      };
    }
    case ENewbuildingLayoutDetailsModalActionTypes.Close: {
      return {
        ...state,
        newbuildingLayoutDetailsModal: {
          open: false,
        },
      };
    }
    default:
      return state;
  }
}
