import * as React from 'react';
import { useSelector } from 'react-redux';

import { trackSubsidisedMortgageBannerShow, trackSubsidisedMortgageBannerClick } from './tracking';
import { getRangeValue } from '../../../../packages/JsonQuery';
import { selectRegionId } from '../../../selectors/filters/selectRegionId';
import { getJsonQuery } from '../../../selectors/getJsonQuery';
import { useApplicationContext } from '../../../utils/applicationContext';
import { SubsidisedMortgageBanner } from '../../components/SubsidisedMortgageBanner';

type THeaderBannerInfo = {
  title: string;
  subtitle: string;
  backgroundColor: string;
  backgroundImage: string;
  textColor: string;
  buttonTheme: string;
  buttonText: string;
  defaultUrl: string;
  focusTitle: string;
  focusSubtitle: string;
  focusButtonText: string;
  focusUrl: string;
  focusBackgroundImage: string;
  focusRegionIds: number[];
};

export const SubsidisedMortgageBannerContainer: React.FC = () => {
  const { config } = useApplicationContext();
  const regionId = useSelector(selectRegionId);
  const jsonQuery = useSelector(getJsonQuery);
  const bannerInfo = config.get<THeaderBannerInfo>('subsidizedMortgage.desktopSerpBannerInfo');

  const handleShow = React.useCallback(() => {
    trackSubsidisedMortgageBannerShow();
  }, []);

  const handleClick = React.useCallback(() => {
    trackSubsidisedMortgageBannerClick();
  }, []);

  const url = React.useMemo(() => {
    /* istanbul ignore next */
    if (!bannerInfo) {
      return '';
    }

    const url = new URL(bannerInfo.defaultUrl);
    const price = getRangeValue('price')(jsonQuery);

    /* istanbul ignore next */
    if (price?.lte) {
      url.searchParams.set('price', String(price.lte));
    }

    url.searchParams.set('region-id', String(regionId));

    return url.toString();
  }, [bannerInfo, regionId, jsonQuery]);

  /* istanbul ignore next */
  if (!bannerInfo) {
    return null;
  }

  const {
    title,
    subtitle,
    backgroundColor,
    backgroundImage,
    textColor,
    buttonTheme,
    buttonText,
    focusTitle,
    focusSubtitle,
    focusButtonText,
    focusUrl,
    focusBackgroundImage,
    focusRegionIds,
  } = bannerInfo;

  const props = focusRegionIds.includes(regionId)
    ? /* istanbul ignore next */ {
        title: focusTitle,
        subtitle: focusSubtitle,
        url: focusUrl,
        backgroundImage: focusBackgroundImage,
        buttonText: focusButtonText,
      }
    : {
        title,
        subtitle,
        url,
        backgroundImage,
        buttonText,
      };

  return (
    <SubsidisedMortgageBanner
      {...props}
      backgroundColor={backgroundColor}
      textColor={textColor}
      buttonTheme={buttonTheme}
      onClick={handleClick}
      onShow={handleShow}
    />
  );
};
