import { getGeoValueByType, getTermValue } from '../../../packages/JsonQuery';
import { IAppState } from '../../common/state';
import { EBuildingStatus, IJsonQueryNewbuilding } from '../../repositories/common/json_query';
import { selectJsonQuery } from '../results';

export const isListingForResidentialComplexSelector = (state: IAppState) => {
  const jsonQuery = selectJsonQuery(state);

  const newobject = getGeoValueByType<IJsonQueryNewbuilding>('newobject')(jsonQuery);
  const buildingStatus = getTermValue('building_status')(jsonQuery);
  const isFromDeveloper = Boolean(getTermValue('from_developer')(jsonQuery));

  return Boolean((buildingStatus === EBuildingStatus.New || isFromDeveloper) && newobject && newobject[0].id);
};
