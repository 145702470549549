import { InlineText3 } from '@cian/ui-kit';
import * as React from 'react';

import * as styles from './styles.css';

interface IInputErrorProps {
  error: string;
}

export function InputError({ error }: IInputErrorProps) {
  if (!error) {
    return null;
  }

  return (
    <div className={styles['error']}>
      <InlineText3 color="text-negative-default">{error}</InlineText3>
    </div>
  );
}
