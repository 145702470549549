import { Button } from '@cian/ui-kit/button';
import { IconToggleHeartOff16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';
import { useSelector } from 'react-redux';

import { TJsonQuery } from '../../../../packages/JsonQuery';
import { TOnSubmitSaveSearchParams, useSaveSearchContext } from '../../../../packages/SaveSearchModal';
import { IAppState } from '../../../common/state';
import { IJsonQuery } from '../../../json_query';
import { getTrackingData } from '../../../selectors/getTrackingData';
import { ITrackingPageData } from '../../../serp/state/tracking';
import { isSuburban, offerTypeFromJsonQuery } from '../../../utils/category';
import { SaveSearchModalContainer } from '../SaveSearchModalContainer/';
import {
  trackShowSaveSearchClick,
  trackSuburbanCloseSaveSearchClick,
  trackSuburbanShowSaveSearchClick,
  trackSaveSearchSubscribed,
  trackSuburbanSaveSearchSubscribed,
} from '../tracking';

const subscribeHandler = (
  isSuburbanSearch: boolean,
  jsonQuery: TJsonQuery,
  page: ITrackingPageData,
  params: TOnSubmitSaveSearchParams,
) => {
  const { defaultEmail, defaultTitle, subscribeToPushAvailable, ...commonParameters } = params;

  if (isSuburbanSearch) {
    trackSuburbanSaveSearchSubscribed({
      ...commonParameters,
      defaultTitle,
      defaultEmail,
      jsonQuery,
      page,
    });
  }

  trackSaveSearchSubscribed({
    ...commonParameters,
    jsonQuery,
    subscribeToPushAvailable,
  });
};

export const SaveSearchButtonContainer = () => {
  const { open, onOpen, onClose, subscribeToPush } = useSaveSearchContext();

  const jsonQuery = useSelector<IAppState, IJsonQuery>(state => state.results.jsonQuery);
  const isSuburbanSearch = isSuburban(offerTypeFromJsonQuery(jsonQuery));
  const { page } = useSelector(getTrackingData);

  const onSubscribed = React.useCallback(
    (params: TOnSubmitSaveSearchParams) => {
      subscribeHandler(isSuburbanSearch, jsonQuery, page, params);
    },
    [isSuburbanSearch, jsonQuery, page],
  );

  const onModalOpen = React.useCallback(() => {
    trackShowSaveSearchClick();

    if (isSuburbanSearch) {
      trackSuburbanShowSaveSearchClick(jsonQuery);
    }
  }, [isSuburbanSearch, jsonQuery]);

  const onModalClose = React.useCallback(() => {
    onClose();

    if (isSuburbanSearch) {
      trackSuburbanCloseSaveSearchClick(jsonQuery);
    }
  }, [isSuburbanSearch, jsonQuery]);

  return (
    <>
      <Button
        theme="fill_white_primary"
        size="XS"
        beforeIcon={<IconToggleHeartOff16 color="icon-main-default" />}
        onClick={onOpen}
      >
        Сохранить поиск
      </Button>
      <SaveSearchModalContainer
        isOpen={open}
        onSubscribed={onSubscribed}
        onPushSubscribed={subscribeToPush}
        onOpen={onModalOpen}
        onClose={onModalClose}
      />
    </>
  );
};
