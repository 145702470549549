import * as React from 'react';
import { useSelector } from 'react-redux';

import { PreInfiniteBanner } from '../../common/components/PreInfiniteBanner';
import { EPreInfiniteBannerTypes } from '../../common/components/PreInfiniteBanner/PreInfiniteBanner';
import { EmptyListingBanner } from '../../components/EmptyListingBanner';
import { selectIsEmptySearchBannerExperimentEnabled } from '../../selectors/experiments/selectIsEmptySearchBannerExperimentEnabled';

export const EmptyListingBannerContainer: React.FC = () => {
  const isRedesignBannerExperimentEnabled = useSelector(selectIsEmptySearchBannerExperimentEnabled);

  if (!isRedesignBannerExperimentEnabled) {
    return <PreInfiniteBanner type={EPreInfiniteBannerTypes.empty} />;
  }

  return <EmptyListingBanner />;
};
