import * as React from 'react';

import { NBSP } from '../../../../../../shared/constants/symbols';
import { NonEmptyArray } from '../../../../../JsonQuery';
import { FilterChips } from '../../FilterChips';
import { Filter } from '../common/Filter';

interface IDiscountPromoSearchType {
  options: {
    value: string | null;
    label: string;
  }[];
  values: NonEmptyArray<string> | null;
  onChange(newValues: NonEmptyArray<string> | null): void;
}

export const DiscountPromoSearchType: React.FC<IDiscountPromoSearchType> = ({ options, values, onChange }) => {
  return (
    <Filter id="advancedFilter_DiscountPromoSearchType" label={`Скидки и акции от${NBSP}застройщиков`}>
      <div data-name="DiscountPromoSearchTypeChips">
        <FilterChips options={options} selectType="multiple" value={values} onChange={onChange} />
      </div>
    </Filter>
  );
};
