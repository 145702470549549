import { ArticleParagraph2 } from '@cian/ui-kit';
import { TColors } from '@cian/ui-kit-design-tokens/colors';
import React, { useMemo, VFC } from 'react';

import styles from './Description.css';

interface IDescriptionProps {
  color?: TColors;
  text: string;
  lines?: number;
}

export const CSS_LINE_HEIGHT = 20;

export const Description: VFC<IDescriptionProps> = ({ text, lines, color }) => {
  const inlineStyles = useMemo(
    () =>
      lines
        ? {
            maxHeight: lines * CSS_LINE_HEIGHT,
            WebkitLineClamp: lines,
          }
        : {},
    [lines],
  );

  return (
    <div className={styles['description']} style={inlineStyles}>
      <ArticleParagraph2 color={color}>{text}</ArticleParagraph2>
    </div>
  );
};
