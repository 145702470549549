import { type IConfig } from '@cian/config/shared';

import { IOffer } from '../types/offer';

export function isNewbuildingBookingEnabled(config: IConfig) {
  return Boolean(config.get<boolean>('newbuilding-booking.Enabled'));
}
export function isNewbuildingBooked({ profitbaseBooking }: IOffer, config: IConfig) {
  const newbuildingBookingEnabled = isNewbuildingBookingEnabled(config);

  return newbuildingBookingEnabled && Boolean(profitbaseBooking?.isBooked);
}
export function isNewbuildingBookingAvailable({ profitbaseBooking }: IOffer, config: IConfig) {
  const newbuildingBookingEnabled = isNewbuildingBookingEnabled(config);

  return Boolean(newbuildingBookingEnabled && profitbaseBooking && !profitbaseBooking.isBooked);
}
export function isNewbuildingBookedFromDeveloper({ isBookedFromDeveloper }: IOffer): boolean {
  return Boolean(isBookedFromDeveloper);
}
