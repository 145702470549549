import { ENewbuildingLayoutOffersActionTypes } from '../../actions/newbuildingLayoutOffers/types';
import { IAppState, TActions } from '../../common/state';
import {
  ENewbuildingLayoutFetchStatus,
  INewbuildingLayoutWithOffers,
} from '../../types/residentialComplexLayouts/layoutWithOffers/layoutWithOffers';
import { INewbuildingLayoutOffer } from '../../types/residentialComplexLayouts/layoutWithOffers/offer';

export function newbuildingLayoutOffersReducer(state: IAppState, action: TActions): IAppState {
  switch (action.type) {
    case ENewbuildingLayoutOffersActionTypes.StartRequest: {
      return {
        ...state,
        newbuildingLayoutOffers: {
          fetchStatus: ENewbuildingLayoutFetchStatus.Loading,
          data: null,
        },
      };
    }
    case ENewbuildingLayoutOffersActionTypes.Error: {
      return {
        ...state,
        newbuildingLayoutOffers: {
          fetchStatus: ENewbuildingLayoutFetchStatus.Error,
          data: null,
        },
      };
    }
    case ENewbuildingLayoutOffersActionTypes.Finished: {
      return {
        ...state,
        newbuildingLayoutOffers: {
          fetchStatus: ENewbuildingLayoutFetchStatus.Done,
          data: action.payload,
        },
      };
    }

    case ENewbuildingLayoutOffersActionTypes.SetFavorite: {
      const offers: INewbuildingLayoutOffer[] = state.newbuildingLayoutOffers.data?.offers || [];
      const updatedOffers = offers.map((offer: INewbuildingLayoutOffer) => {
        if (offer.offerId === action.payload.offerId) {
          return {
            ...offer,
            isFavorite: action.payload.isFavorite,
          };
        }

        return offer;
      });

      return {
        ...state,
        newbuildingLayoutOffers: {
          ...state.newbuildingLayoutOffers,
          data: {
            ...(state.newbuildingLayoutOffers.data as INewbuildingLayoutWithOffers),
            offers: updatedOffers,
          },
        },
      };
    }
  }

  return state;
}
