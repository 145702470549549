import { IconActionSearch16 } from '@cian/ui-kit-design-tokens/icons';
import * as React from 'react';

import * as styles from './SearchIcon.css';

export const SearchIcon = () => {
  return (
    <div className={styles['search-icon']}>
      <IconActionSearch16 color="icon-main-default" />
    </div>
  );
};
