import {
  IconMapMetroSpb16,
  IconMapMetroEkb16,
  IconMapMetroKzn16,
  IconMapMetroNovosib16,
  IconMapMetroSam16,
  IconMapMetroNN16,
  IconMapMetroMsk16,
  IconMapMCD116,
  IconMapMCD216,
  IconMapMCD316,
  IconMapMCD416,
  IconMapMCD516,
  ISVGIconProps,
} from '@cian/ui-kit-design-tokens/icons';
import React, { ComponentType } from 'react';

import { IUnderground } from '../../../../types/offer';

const METRO_ICONS_BY_REGION: Partial<Record<number, ComponentType<ISVGIconProps>>> = {
  2: IconMapMetroSpb16,
  4743: IconMapMetroEkb16,
  4777: IconMapMetroKzn16,
  4897: IconMapMetroNovosib16,
  4966: IconMapMetroSam16,
  4885: IconMapMetroNN16,
};

const MCD_ICONS_BY_LINE_TYPE: Partial<Record<string, ComponentType<ISVGIconProps>>> = {
  mcd1: IconMapMCD116,
  mcd2: IconMapMCD216,
  mcd3: IconMapMCD316,
  mcd4: IconMapMCD416,
  mcd5: IconMapMCD516,
};

interface IParams {
  underground?: IUnderground;
  regionId: number;
}

export const getUndergroundIcon = ({ underground, regionId }: IParams) => {
  if (!underground) {
    return null;
  }

  const { lineType } = underground;

  const mcdIcon = lineType && MCD_ICONS_BY_LINE_TYPE[lineType];

  const IconComponent = mcdIcon || METRO_ICONS_BY_REGION[regionId] || IconMapMetroMsk16;

  return <IconComponent color="current_color" />;
};
