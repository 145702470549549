import { ITypedReduxAction } from '../../types/redux';
import { actionGenerator } from '../../utils/redux';

export enum ENewbuildingLayoutDetailsModalActionTypes {
  Open = 'NewbuildingLayoutDetailsModal/Open',
  Close = 'NewbuildingLayoutDetailsModal/Close',
}

export type TNewbuildingLayoutDetailsModalOpen = ITypedReduxAction<ENewbuildingLayoutDetailsModalActionTypes.Open>;
export type TNewbuildingLayoutDetailsModalClose = ITypedReduxAction<ENewbuildingLayoutDetailsModalActionTypes.Close>;

export const openNewbuildingLayoutDetails: () => TNewbuildingLayoutDetailsModalOpen =
  actionGenerator<ENewbuildingLayoutDetailsModalActionTypes.Open>(ENewbuildingLayoutDetailsModalActionTypes.Open);

export const closeNewbuildingLayoutDetails: () => TNewbuildingLayoutDetailsModalClose =
  actionGenerator<ENewbuildingLayoutDetailsModalActionTypes.Close>(ENewbuildingLayoutDetailsModalActionTypes.Close);

export type TNewbuildingLayoutDetailsModalActions =
  | TNewbuildingLayoutDetailsModalOpen
  | TNewbuildingLayoutDetailsModalClose;
