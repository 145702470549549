import { IntersectionOptions } from 'react-intersection-observer';

import { useApplicationContext } from '../../../../../../shared/utils/applicationContext';

export const DEFAULT_INTERSECTION_OPTIONS: IntersectionOptions = {
  threshold: 1,
};

export const useIntersectionOptions = () => {
  const { config } = useApplicationContext();

  return config.get<IntersectionOptions>('audience.videoShorts.intersectionOptions') || DEFAULT_INTERSECTION_OPTIONS;
};
