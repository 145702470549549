import { ca } from '@cian/analytics';

export function trackSubsidisedMortgageZeroPlaceBannerClick() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountSecondary',
    action: 'Click',
    label: 'DetailsBanner',
  });
}

export function trackSubsidisedMortgageZeroPlaceBannerShow() {
  ca('eventSite', {
    name: 'oldevent',
    category: 'MortgageDiscountSecondary',
    action: 'Show',
    label: 'DetailsBanner',
  });
}
