import { ELabelColor, ILabel } from '../../../types/label';
import { TOfferType } from '../../../types/offer';

export function getRosreestrCheckedLabel(offerType: TOfferType): ILabel {
  switch (offerType) {
    case 'flat':
      return {
        tooltip: {
          title: 'Продавец указал кадастровый номер',
          description: 'В объявлении есть информация из Росреестра о собственности, обременениях и площади квартиры',
        },
        text: 'Проверено в Росреестре',
        color: ELabelColor.Gray,
      };
    case 'suburban':
      return {
        tooltip: {
          title: 'Продавец указал кадастровый номер',
          description:
            'В объявлении есть информация из Росреестра о собственности, статусе земли, площади дома и участка',
        },
        text: 'Проверено в Росреестре',
        color: ELabelColor.Gray,
      };
    default:
      return {
        text: 'Проверено в Росреестре',
        color: ELabelColor.Gray,
      };
  }
}
