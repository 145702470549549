import * as React from 'react';
import { useInView } from 'react-intersection-observer';

import { CardInViewContext } from './context';
import { useIntersectionOptions } from './hooks';

export const CardInViewProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const intersectionOptions = useIntersectionOptions();

  const { ref, inView } = useInView(intersectionOptions);

  return (
    <CardInViewContext.Provider value={inView}>
      <div ref={ref}>{children}</div>
    </CardInViewContext.Provider>
  );
};
