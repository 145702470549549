import { ModalWindow } from '@cian/ui-kit';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { trackNewbuildingLayoutOfferClick } from './tracking';
import { closeNewbuildingLayoutDetails, openNewbuildingLayoutDetails } from '../../actions/newbuildingLayoutDetails';
import { getNewbuildingLayoutOffers } from '../../actions/newbuildingLayoutOffers';
import { Card } from '../../components/NewbuildingLayoutsCard';
import { Cards } from '../../components/NewbuildingLayoutsCards';
import {
  residentialComplexLayoutsSelector,
  selectNewbuildingLayoutDetailsModalOpen,
} from '../../selectors/newbuildingLayouts';
import { TThunkDispatch } from '../../types/redux';
import { INewbuildingLayout } from '../../types/residentialComplexLayouts/layouts/newbuildingLayout';
import { NewbuildingLayoutDetails } from '../NewbuildingLayoutDetails';

export const NewbuildingLayoutsContainer = () => {
  const dispatch = useDispatch<TThunkDispatch>();
  const [layoutKey, setLayoutKey] = React.useState<string>('');
  const layouts = useSelector(residentialComplexLayoutsSelector);
  const isDetailsModalOpen = useSelector(selectNewbuildingLayoutDetailsModalOpen);

  const onClick = React.useCallback(
    (key: string) => {
      dispatch(getNewbuildingLayoutOffers({ layoutKey: key }));
      dispatch(openNewbuildingLayoutDetails());
      setLayoutKey(key);
    },
    [dispatch],
  );

  const onClose = React.useCallback(() => {
    dispatch(closeNewbuildingLayoutDetails());
  }, [dispatch]);

  if (!layouts) {
    return null;
  }

  return (
    <div data-testid="NewbuildingLayoutsContainer">
      <Cards>
        {layouts.map((layout: INewbuildingLayout) => {
          return (
            <Card
              key={layout.layoutKey}
              layout={layout}
              onClick={onClick}
              onLinkClick={trackNewbuildingLayoutOfferClick}
            />
          );
        })}
      </Cards>
      <ModalWindow open={isDetailsModalOpen} onClose={onClose} size="M" fixed={false}>
        <NewbuildingLayoutDetails layoutKey={layoutKey} />
      </ModalWindow>
    </div>
  );
};
