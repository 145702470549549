import * as React from 'react';

import * as style from './style.css';

export const SkeletonRow = () => {
  return (
    <div className={style['row']}>
      <div className={style['offer-title']}>
        <div className={style['offer-title-link']} />
        <div className={style['labels']} />
      </div>
      <div className={style['description-cell']} />
      <div className={style['description-cell']} />
      <div className={style['description-cell']} />
      <div className={style['description-controls']}>
        <div className={style['favorite']} />
      </div>
    </div>
  );
};
