import { FDealType, FOfferType, isAvailable, isAvailableStrict } from '../../../../JsonQuery';
import { DiscountPromoSearchTypeContainer } from '../../containers/advancedFilters/DiscountPromoSearchTypeContainer';
import { IFilter } from '../../types/advancedFilters';

export const discountPromoSearchType: IFilter = {
  component: DiscountPromoSearchTypeContainer,
  availability: ({ config, jsonQuery }) => {
    // TODO: Удалить развилку в CD-217337.
    const isMixedListingEnabled = config.get('newbuildingPromoSearchTypes.mixedListingEnabled') ?? false;
    const availableFunction = isMixedListingEnabled ? isAvailable : isAvailableStrict;

    return availableFunction(FDealType.Sale, FOfferType.FlatNew)(jsonQuery);
  },
};
