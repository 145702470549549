import * as React from 'react';

import * as styles from './NewbuildingFilters.css';

interface ISummaryNewbuildingColumnProps {
  layoutsToggle: React.ReactElement;
  housesFilter: React.ReactElement;
}

export const NewbuildingFilters = (props: ISummaryNewbuildingColumnProps) => {
  return (
    <div className={styles['section']}>
      <div className={styles['container']}>
        <div>{props.layoutsToggle}</div>
        <div>{props.housesFilter}</div>
      </div>
    </div>
  );
};
