import { type IConfig } from '@cian/config/shared';
import React from 'react';

import { EFeature } from './features';
import { IJsonQuery } from '../types/jsonQuery';
import { TCurrentLocation } from '../types/location';

interface IAvailabilityFunctionParameters {
  config: IConfig;
  currentLocation: TCurrentLocation;
  features: EFeature[];
  jsonQuery: IJsonQuery;
  isVillageMortgageFilterEnabled?: boolean;
}

export type TAvailabilityFunction = (parameters: IAvailabilityFunctionParameters) => boolean;

export interface IFilterComponentBaseProps {
  filterKey: TFilter;
}

export interface IFilterComponentProps extends IFilterComponentBaseProps {
  open?: boolean;
  onOpen?(): void;
  onClose?(): void;
}

export enum EFilterType {
  Default = 'default',
  Openable = 'openable',
}

export interface IBaseFilter {
  availability?: TAvailabilityFunction;
  component: React.ComponentType<IFilterComponentBaseProps>;
  type: EFilterType;
}

export interface IDefaultFilter extends IBaseFilter {
  type: EFilterType.Default;
}

export interface IOpenableFilter extends IBaseFilter {
  component: React.ComponentType<IFilterComponentProps>;
  type: EFilterType.Openable;
}

export type TOpenedFiltersState = { [key in TFilter]?: boolean };

export type IFilter = IDefaultFilter | IOpenableFilter;

export type TFilter =
  | 'advancedFilters'
  | 'bedrooms'
  | 'beds'
  | 'cottageVillage'
  | 'dates'
  | 'dealType'
  | 'geo'
  | 'offerType'
  | 'price'
  | 'roomType'
  | 'villageMortgageAllowed'
  | 'starRating'
  | 'suburbanArea';
