import { IconActionChevronDownSmall16 } from '@cian/ui-kit-design-tokens/icons';
import classNames from 'classnames';
import * as React from 'react';

import { getInlineStyles } from './helpers';
import * as styles from './selectButton.css';
import { EVisualSize, ISelectStyles, TSelectButtonAppearance } from '../types';

const APPEARANCE_TO_CLASS_MAP: { [key in TSelectButtonAppearance]: string } = {
  default: styles['button'],
  underlined: styles['button-underlined'],
};

interface ISelectButtonProps extends Omit<React.ComponentPropsWithoutRef<'button'>, 'type'> {
  active: boolean;
  invalid?: boolean;
  size?: EVisualSize;
  style?: ISelectStyles;
  appearance?: TSelectButtonAppearance;
  withoutArrow?: boolean;
}

export const SelectButton = React.forwardRef(function SelectButton(
  props: ISelectButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const { active, children, invalid, size, style, appearance = 'default', withoutArrow, ...htmlButtonProps } = props;

  return (
    <button
      {...htmlButtonProps}
      type="button"
      className={classNames(
        APPEARANCE_TO_CLASS_MAP[appearance],
        active && styles['active'],
        invalid && styles['invalid'],
        size === EVisualSize.DESKTOP && styles['desktop'],
        size === EVisualSize.MOBILE && styles['mobile'],
      )}
      style={getInlineStyles(style)}
      ref={ref}
    >
      <span className={styles['content']}>{children}</span>

      {!withoutArrow && (
        <div className={styles['icon']}>
          <IconActionChevronDownSmall16
            color={htmlButtonProps.disabled ? 'icon-primary-default' : 'icon-main-default'}
          />
        </div>
      )}
    </button>
  );
});
