import { getAgentName } from './getAgentName';
import { IOffer } from '../../../../offer';

export function getOfferChatSrc(offer: IOffer | null, dates: { checkin: string; checkout: string } | null) {
  if (offer) {
    const { user, cianId, dealType, offerType, gaLabel } = offer;

    const companionName = getAgentName(offer);

    /* istanbul ignore next */
    return (
      `/dialogs/?hostType=frame&offerId=${cianId}` +
      `&dealType=${dealType}&offerType=${offerType}` +
      `${companionName ? '&companionName=' + encodeURIComponent(companionName) : ''}` +
      `${user?.cianUserId ? '&companionId=' + encodeURIComponent(user?.cianUserId) : ''}` +
      `${gaLabel ? '&gaLabel=' + encodeURIComponent(gaLabel) : ''}` +
      '&pageType=Listing' +
      `${dates ? '&checkin=' + encodeURIComponent(dates.checkin) : ''}` +
      `${dates ? '&checkout=' + encodeURIComponent(dates.checkout) : ''}`
    );
  }

  return '/dialogs/?hostType=frame&chatbot=1&source=Listing&pageType=Listing';
}
