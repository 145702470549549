/* eslint-disable */

//tslint:disable

import { type IHttpApi, type IHttpApiFetchConfig } from '@cian/http-api/shared';
import {
  TConvertJsonQueryToSeoUrlModel,
  IConvertJsonQueryToSeoUrlRequest,
  IMappers,
  IConvertJsonQueryToSeoUrlResponse,
  IConvertJsonQueryToSeoUrlResponseError,
  TConvertJsonQueryToSeoUrlResponse,
} from './types';

const defaultConfig: TConvertJsonQueryToSeoUrlModel = {
  apiType: 'legacy',
  assertStatusCodes: [200, 400],
  method: 'POST',
  microserviceName: 'monolith-python',
  pathApi: '/cian-api/site/v1/convert-json-query-to-seo-url',
  hostType: 'api',
} as TConvertJsonQueryToSeoUrlModel;

function createConvertJsonQueryToSeoUrlModel(
  parameters: IConvertJsonQueryToSeoUrlRequest,
): TConvertJsonQueryToSeoUrlModel {
  return {
    ...defaultConfig,
    parameters,
  };
}

export interface IConvertJsonQueryToSeoUrlOptions<TResponse200, TResponse400> {
  httpApi: IHttpApi;
  parameters: IConvertJsonQueryToSeoUrlRequest;
  config?: IHttpApiFetchConfig;
  mappers?: IMappers<TResponse200, TResponse400>;
}

async function fetchConvertJsonQueryToSeoUrl<TResponse200, TResponse400>({
  httpApi,
  parameters,
  config,
  mappers,
}: IConvertJsonQueryToSeoUrlOptions<TResponse200, TResponse400>): Promise<
  TResponse200 | TResponse400 | TConvertJsonQueryToSeoUrlResponse
> {
  const { response, statusCode } = await httpApi.fetch(createConvertJsonQueryToSeoUrlModel(parameters), config);

  if (mappers && statusCode in mappers) {
    if (statusCode === 200) {
      return mappers[200](response as IConvertJsonQueryToSeoUrlResponse);
    }

    if (statusCode === 400) {
      return mappers[400](response as IConvertJsonQueryToSeoUrlResponseError);
    }
  }

  return { response, statusCode } as TConvertJsonQueryToSeoUrlResponse;
}

export { defaultConfig, createConvertJsonQueryToSeoUrlModel, fetchConvertJsonQueryToSeoUrl };
