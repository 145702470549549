import { useSelector } from 'react-redux';

import { getBuilder, getBuilderChatEnabled } from '../../../selectors/newbuildingHeader';
import { getUser } from '../../../selectors/user';

export function useBuilderChatEnabled() {
  const user = useSelector(getUser);
  const builder = useSelector(getBuilder);
  const isChatEnabled = useSelector(getBuilderChatEnabled);

  const isOwnNewbuilding = user.isAuthenticated && builder?.realtyUserId === user.userId;

  return isChatEnabled && !isOwnNewbuilding;
}
